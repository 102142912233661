import { config } from '@abyss/ui/tools/config';

import appConfig from './app.json';
import localConfig from './env/local.json';
import devConfig from './env/dev.json';
import testConfig from './env/test.json';
import stageConfig from './env/stage.json';
import prodConfig from './env/prod.json';

const envs = {
  local: localConfig,
  dev: devConfig,
  test: testConfig,
  stage: stageConfig,
  prod: prodConfig,
};

config({ ...appConfig, ...envs[config('APP_ENV')] });

export const convertPython = ({ url, method, headers, body }) => {
  const codeArr = [];
  codeArr.push('import requests');

  codeArr.push(`\nurl = "${url}"`);

  if (body) {
    codeArr.push(`\npayload = ${JSON.stringify(JSON.stringify(body))}`);
  } else {
    codeArr.push('\npayload = {}');
  }

  codeArr.push('headers = {');
  const headerArr = headers.map(header => {
    return `  '${header.key}': '${header.value}',`;
  });
  codeArr.push(`${headerArr.join('\n')}`);
  codeArr.push('}');

  codeArr.push(`\nresponse = requests.request("${method}", url, `);
  codeArr.push(`  headers=headers, data=payload)`);

  codeArr.push('\nprint(response.text)');

  return codeArr.join('\n');
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { apiItems } from './__mock_data__/all-api';

export const AppContext = React.createContext({});

export function AppProvider(props) {
  const { children } = props;
  const [previousPath, setPreviousPath] = useState('');
  const [apiList] = React.useState(apiItems);
  const sortOptions = [
    { label: 'API Name (A-Z)', value: -1 },
    { label: 'API Name (Z-A)', value: 1 },
  ];
  const [selectedOption, setSelectedOption] = React.useState(sortOptions[0]);
  const filterList = [
    { title: 'Claims', checked: false, id: 1 },
    { title: 'Eligibility', checked: false, id: 2 },
    { title: 'Provider Demographic', checked: false, id: 5 },
    { title: 'Referrals', checked: false, id: 3 },
    { title: 'Extended X12', checked: false, id: 4 },
    { title: 'Prior Authorizations', checked: false, id: 7 },
  ];
  const sortedFilters = [...filterList].sort((a, b) =>
    a.title > b.title ? 1 : -1
  );
  const [filters, updateFilters] = React.useState(sortedFilters);
  let sortedApiList = [...apiList];
  if (selectedOption !== null) {
    if (selectedOption.value === -1) {
      sortedApiList = [...apiList].sort((a, b) =>
        a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
      );
    } else {
      sortedApiList = [...apiList].sort((a, b) =>
        a.label.toLowerCase() < b.label.toLowerCase() ? 1 : -1
      );
    }
  }

  const selectedCategories = filters
    .filter(filter => filter.checked)
    .map(filter => filter.title.toLowerCase());

  if (selectedCategories.length > 0) {
    sortedApiList = [...sortedApiList].filter(api =>
      selectedCategories.some(element => api.apiCategory.includes(element))
    );
  }

  return (
    <AppContext.Provider
      value={{
        apiList: sortedApiList,
        filters,
        updateFilters,
        sortOptions,
        selectedOption,
        setSelectedOption,
        previousPath,
        setPreviousPath,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.element,
};

AppProvider.defaultProps = {
  children: null,
};

import React, { useEffect, useState } from 'react';
import { PageLayout } from 'src/common/PageLayout';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Button } from '@abyss/web/ui/Button/Button';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { config } from '@abyss/ui/tools/config';
import { StarRating } from 'src/common/StarRating';
import {
  useFeedbackSubmission,
  resetFeedback,
} from 'src/hooks/useFeedbackSubmission';
import { useParams } from '@abyss/ui/router/hooks/useParams';
import { Alert } from '@abyss/web/ui/Alert';
import { event } from '@abyss/web/tools/event';
import { useSelector } from 'react-redux';
import { useDSUserInfo } from 'src/hooks/useDSUserInfo';
import { styled } from '@abyss/web/tools/styled';
import apiData from '../OrganizationListReviewUrl.json';
import { constants } from '../../../../../../../common/Utils/Constants';
import { useUserInfo } from '../../../../../../../hooks/useUserInfo';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },
  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const PageTitle = styled('h1', {
  color: constants.FONT_COLOR_NAVY_BLUE,
  fontSize: '28px !important',
});

const PageText = styled('h4', {
  color: constants.FONT_COLOR_NAVY_BLUE,
  fontWeight: 'bold',
  fontSize: '15px !important',
  lineHeight: '24px !important',
});

const ButtonStyles = styled('div', {
  display: 'flex',
  gap: '10px',
  marginTop: '20px',
});

const RatingStyles = styled('div', {
  paddingBottom: '32px',
});

const TextInputAreaStyle = styled('div', {
  width: '100%',
  '@screen >= $sm': {
    width: '50%',
  },
});

const RatingRequiredError = styled('p', {
  marginBottom: '0px',
  color: 'red',
  fontSize: '12px',
  fontWeight: 'bold',
});

const PageDesc = styled('h4', {
  color: constants.FONT_COLOR_NAVY_BLUE,
  fontSize: '22px !important',
  fontWeight: '700',
  lineHeight: '32px !important',
  letterSpacing: '0px',
  textAlign: 'left',
});

const ButtonMargin = styled('div', {
  marginTop: '16px',
});

export const ApiFeedback = () => {
  const { service } = useParams();
  const [value, setValue] = useState('');
  const [rating, setRating] = useState(0);
  const [displayRatingError, setDisplayRatingError] = useState(false);
  const history = useRouter();
  const [submitFeedbackApi, postSubmitFeedbackApi] = useFeedbackSubmission();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);

  const userDSInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const [dsUserInfo, getDSUserInfo] = useDSUserInfo();
  const [, resetFeedbackResponse] = resetFeedback();
  const [, getUserInfo] = useUserInfo();

  const goBack = () => {
    history.push('/welcome');
  };

  const capitalizedServiceName = apiData[service]?.serviceName;

  const resetFeedbackState = () => {
    resetFeedbackResponse();
  };

  const getIsProdFlag = () => {
    if (userDSInfo?.linkedApis?.prod?.includes(capitalizedServiceName)) {
      return true;
    }
    return false;
  };

  const submitFeedback = () => {
    if (rating < 1) {
      setDisplayRatingError(true);
    } else {
      setDisplayRatingError(false);
      const requestBody = {
        apiName: service,
        rating,
        comments: value,
        isProd: getIsProdFlag(),
        orgId: userDSInfo?.orgId,
      };

      postSubmitFeedbackApi({
        variables: { values: requestBody },
        userInfo,
      });
    }
  };

  useEffect(() => {
    resetFeedbackState();
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }

    if (userInfo.uuid === '') {
      getUserInfo();
    }

    if (userDSInfo?.uuid === '') {
      getDSUserInfo({ userInfo });
    }
  }, []);

  useEffect(() => {
    if (
      userDSInfo?.uuid !== '' &&
      !userDSInfo?.linkedApis?.stage?.includes(capitalizedServiceName)
    ) {
      goBack();
    }

    if (!dsUserInfo.loading && dsUserInfo.data.uuid !== '') {
      event('NEW_PAGE_LOAD', {
        pageName: `apimarketplace:welcome:${service}-feedback`,
        siteSectionL1: 'welcome',
        siteSectionL2: `${service}-feedback`,
        dsUserInfo: dsUserInfo.data,
        userInfo,
      });
    }
  }, [service, dsUserInfo]);

  useEffect(() => {
    setDisplayRatingError(false);
  }, [rating]);

  const form = useForm();
  return (
    <PageLayout>
      <Wrapper>
        <Breadcrumbs />
        <PageTitle>{apiData[service]?.name}</PageTitle>
        <If
          condition={
            !submitFeedbackApi.loading &&
            submitFeedbackApi?.data?.status &&
            submitFeedbackApi?.data?.status !== 200
          }
        >
          <Alert title="Your submission has failed. Please try again." />
        </If>
        <Choose>
          <When
            condition={
              !submitFeedbackApi.loading &&
              submitFeedbackApi?.data?.status === 200
            }
          >
            <PageDesc>Thank you for your feedback. </PageDesc>
            <ButtonMargin>
              <Button fontSize="15px" onClick={goBack}>
                Back to Account Dashboard
              </Button>
            </ButtonMargin>
          </When>
          <Otherwise>
            <PageText>How likely are you to refer this API to a peer?</PageText>
            <FormProvider state={form} onSubmit={submitFeedback}>
              <RatingStyles>
                <StarRating value={setRating} />
                <If condition={displayRatingError}>
                  <RatingRequiredError>
                    Please provide your API rating.
                  </RatingRequiredError>
                </If>
              </RatingStyles>
              <TextInputAreaStyle>
                <TextInputArea
                  model="feedback"
                  rows={6}
                  label="Do you have any feedback for how we can improve this API?"
                  value={value}
                  placeholder="Please provide your feedback for this API."
                  maxLength={config('FEEDBACK_CHARACTER_LIMIT')}
                  onChange={e => setValue(e.target.value)}
                  css={{
                    'abyss-text-input-area-label': {
                      color: '$primary1',
                      fontSize: '14px',
                    },
                  }}
                />
              </TextInputAreaStyle>
              <ButtonStyles>
                <Button size="$md" variant="outline" onClick={goBack}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isLoading={submitFeedbackApi.loading}
                  ariaLoadingLabel="Submitting Feedback"
                >
                  Submit
                </Button>
              </ButtonStyles>
            </FormProvider>
          </Otherwise>
        </Choose>
      </Wrapper>
    </PageLayout>
  );
};

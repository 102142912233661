import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './Testimonials.css';
import { TestimonialsMobileView } from './TestimonialsMobileView/TestimonialsMobileView';
import { TestimonialsDesktopView } from './TestimonialsDesktopView/TestimonialsDesktopView';

export const Testimonials = ({ initialData }) => {
  const [deviceView, setDeviceView] = useState('');

  const widthHandler = () => {
    if (window.outerWidth > 576) {
      setDeviceView('desktop');
    } else {
      setDeviceView('mobile');
    }
  };

  useEffect(() => {
    widthHandler();
    window.addEventListener('resize', widthHandler);
    return () => {
      window.removeEventListener('resize', widthHandler);
    };
  }, []);

  return (
    <div className={style.Wrapper}>
      {deviceView === 'desktop' ? (
        <TestimonialsDesktopView initialData={initialData} />
      ) : null}
      {deviceView === 'mobile' ? (
        <TestimonialsMobileView initialData={initialData} />
      ) : null}
    </div>
  );
};

Testimonials.propTypes = {
  initialData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkLabel: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

Testimonials.defaultProps = {
  initialData: [],
};

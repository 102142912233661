import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import PuzzleImage from 'src/assets/puzzle-image.svg';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { StyledLink } from 'src/common/Link';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

const Wrapper = styled('div', {
  minHeight: '400px',
  background: 'rgb(255, 255, 255)',
  borderRadius: '4px',
  width: '100%',
  display: 'flex',
  margin: '64px 0 0px 0',

  '@screen >= $xs': {
    flexDirection: 'column-reverse',
  },

  '@screen >= $lg': {
    flexDirection: 'row-reverse',
  },

  '@screen >= $xl': {
    flexDirection: 'row',
  },
});

const LeftCon = styled('div', {
  flexBasis: '50%',
  padding: '32px',
  margin: 'auto',
});

const RightCon = styled('div', {
  flexBasis: '50%',
});

const StyledImg = styled('img', {
  width: '100%',
  height: '100%',
});

const Title = styled('h3', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px ! important',
  fontWeight: '600',
  lineHeight: '32px',
  marginBottom: '16px',
});

const Description = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  overflow: 'hidden',
  maxHeight: '237px',
});

const LinkBox = styled('div', {
  letterSpacing: '0px',
  display: 'flex',
  alignItems: 'center',
  height: '20px',
  color: 'rgb(25, 110, 207)',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '20px',
  marginTop: '40px',

  a: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export const GettingStarted = () => {
  const router = useRouter();
  const onGettingStartedClick = () => {
    const interactionName = 'start up with apis';
    const interactionValue = 'get started';
    const interactionContext = 'page cta link clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    router.push('/getting-started');
  };
  return (
    <Wrapper>
      <LeftCon>
        <Title>Start up with APIs</Title>
        <Description>
          Learn how you can get started working with APIs, from downloading our
          self-paced user guide and understanding the steps, plus the range of
          benefits that API integration can bring to your practice.
        </Description>
        <LinkBox>
          <StyledLink
            onClick={onGettingStartedClick}
            fontWeight="$bold"
            after={<IconMaterial icon="arrow_forward" size="20px" />}
            id="getStarted"
          >
            Get Started
          </StyledLink>
        </LinkBox>
      </LeftCon>
      <RightCon>
        <StyledLink variant="custom" onClick={onGettingStartedClick}>
          <StyledImg src={PuzzleImage} />
        </StyledLink>
      </RightCon>
    </Wrapper>
  );
};

export const convertJavascript = ({ url, method, headers, body }) => {
  const codeArr = [];

  codeArr.push(`var myHeaders = new Headers();`);
  const headerArr = headers.map(header => {
    return `myHeaders.append('${header.key}', '${header.value}');`;
  });
  codeArr.push(`${headerArr.join('\n')}`);

  if (body) {
    codeArr.push(
      `\nvar raw = JSON.stringify(${JSON.stringify(body, null, 2)});`
    );
  }

  codeArr.push(`\nvar requestOptions = {`);
  const optionsArr = [];
  optionsArr.push(`  method: '${method}'`);
  if (headers.length) {
    optionsArr.push(`  headers: myHeaders`);
  }
  if (body) {
    optionsArr.push(`  body: raw`);
  }
  codeArr.push(`${optionsArr.join(',\n')}`);
  codeArr.push(`};`);
  codeArr.push(`\nfetch('${url}', requestOptions)`);
  codeArr.push(`  .then(response => response.text())`);
  codeArr.push(`  .then(result => console.log(result))`);
  codeArr.push(`  .catch(error => console.log('error', error));`);

  return codeArr.join('\n');
};

import { config } from '@abyss/ui/tools/config';

const thunk = ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    return action(dispatch, getState);
  }

  return next(action);
};

export const getDefaultMiddleware = () => {
  let middlewareArray = [thunk];

  if (config('APP_ENV') !== 'prod') {
    middlewareArray = [
      // eslint-disable-next-line global-require
      require('redux-immutable-state-invariant').default(),
      thunk,
      // eslint-disable-next-line global-require
      require('redux-logger').createLogger({ collapsed: true }),
    ];
  }

  return middlewareArray;
};

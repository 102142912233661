import { ACTION_TYPE } from './useUserInfo';

const initialState = {
  data: {
    uuid: '',
    username: '',
    firstName: '',
    lastName: '',
  },
  loading: false,
  error: false,
};

export const reducerUserInfo = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_CALLED`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${ACTION_TYPE}_COMPLETED`:
        if (action.payload.error) {
          return {
            ...state,
            data: action.payload,
            loading: false,
            error: true,
          };
        }
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };

      case `${ACTION_TYPE}_ERROR`:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case `${ACTION_TYPE}_RESET`:
        return {
          ...initialState,
        };
      default:
        return state;
    }
  },
};

import React, { Fragment, useEffect } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { styled } from '@abyss/web/tools/styled';
import { useSelector } from 'react-redux';
import { useDeleteFile } from 'src/hooks/useDeleteFile';
import { useDeleteCommunicationAPI } from '../../../../../../hooks/useDeleteCommunicationAPI';
import { useGetCommunicationAPI } from '../../../../../../hooks/useGetCommunicationAPI';

const ButtonConatiner = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '15px',
});

export const DeleteSelectedDraftsModal = ({
  showModal,
  modalOnClose,
  ids = [],
}) => {
  const [
    deleteCommunication,
    deleteCommunicationAPI,
  ] = useDeleteCommunicationAPI();
  const [communicationAPI] = useGetCommunicationAPI();
  const [deleteFiles, deleteUploadedFiles] = useDeleteFile();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);

  useEffect(() => {
    if (
      !deleteFiles.loading &&
      deleteFiles.data.message &&
      !deleteFiles.error
    ) {
      modalOnClose(false);
    }
  }, [deleteFiles]);

  useEffect(() => {
    if (
      !deleteCommunication.loading &&
      deleteCommunication.data.message &&
      !deleteCommunication.error
    ) {
      const { draftMessages = [] } = communicationAPI.data || {};
      const deleteFileIds = draftMessages.reduce((accumulator, draft) => {
        if (ids?.includes(draft.id) && draft?.fileIds?.length) {
          return [...accumulator, ...draft?.fileIds];
        }
        return accumulator;
      }, []);

      if (deleteFileIds?.length) {
        const fileKeyList = deleteFileIds?.map(fileId => ({
          Key: `apimarketplace/${fileId}`,
        }));
        deleteUploadedFiles({ userInfo, fileKeyList });
      } else {
        modalOnClose(false);
      }
    } else {
      // TODO: handle errror scenario
    }
  }, [deleteCommunication]);

  const onClick = () => {
    deleteCommunicationAPI({ userInfo, ids });
  };

  return (
    <Fragment>
      <Modal
        title="Selected draft communications will be permanently deleted."
        isOpen={showModal}
        onClose={modalOnClose}
        css={{
          'abyss-modal-header-container': {
            color: '#002677',
            fontSize: '28.83px',
          },
          'abyss-modal-content-title': { 'font-size': '26px !important' },
        }}
      >
        <Modal.Section>
          <ButtonConatiner>
            <Button
              id="cancelButton"
              size="$md"
              variant="outline"
              fontSize="15px"
              onClick={() => modalOnClose(false)}
              style={{ marginRight: '10px' }}
            >
              Cancel
            </Button>
            <Button
              size="$md"
              fontSize="15px"
              onClick={onClick}
              id="continueButton"
              isLoading={deleteCommunication.loading}
              ariaLoadingLabel="Delete Draft"
            >
              Continue
            </Button>
          </ButtonConatiner>
        </Modal.Section>
      </Modal>
    </Fragment>
  );
};
DeleteSelectedDraftsModal.propTypes = {
  modalOnClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

import { createTheme } from '@abyss/web/tools/theme';

export const theme = createTheme('uhc', {
  colors: {
    primary: '#196ECF',
    // neutral
    black: '#000000',
    white: '#FFFFFF',

    // UHC Palette
    UHC_BLUE: '#002677',
  },
});

import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { useSelector } from 'react-redux';

const ModalText = styled('p', {
  fontSize: '15px',
});

const Note = styled('p', {
  fontWeight: 'bold',
  marginTop: '15px',
});

const ButtonStyle = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '45px',
});

export const SecretExpireModal = ({ showModal, modalOnClose }) => {
  const userStatus = useSelector(state => state.GET_CREDENTIALS.data);
  const stageAlert = userStatus?.credentials?.stage?.showCredentialsExpiryAlert;
  const prodAlert = userStatus?.credentials?.prod?.showCredentialsExpiryAlert;
  const credentialsAPIEnvironment = () => {
    let env = '';
    if (prodAlert && stageAlert) {
      env = 'prod and stage';
    } else if (prodAlert) {
      env = 'prod';
    } else if (stageAlert) {
      env = 'stage';
    }
    return env;
  };

  const noOfDays = () => {
    let days = '';
    const stageExpiryNoOfDays =
      userStatus?.credentials?.stage?.credentialsExpiryNoOfDays;
    const prodExpiryNoOfDays =
      userStatus?.credentials?.prod?.credentialsExpiryNoOfDays;
    const stageEndDateTime = userStatus?.credentials?.stage?.endDateTime;
    const prodEndDateTime = userStatus?.credentials?.prod?.endDateTime;
    if (prodAlert && stageAlert) {
      if (stageEndDateTime === prodEndDateTime) {
        days = stageExpiryNoOfDays;
      } else if (stageExpiryNoOfDays < prodExpiryNoOfDays) {
        days = stageExpiryNoOfDays;
      } else {
        days = prodExpiryNoOfDays;
      }
    } else if (prodAlert) {
      days = prodExpiryNoOfDays;
    } else if (stageAlert) {
      days = stageExpiryNoOfDays;
    }
    return days;
  };

  const title = `Your organization's credentials will expire in ${noOfDays()} days`;

  return (
    <Modal
      size="lg"
      title={title}
      isOpen={showModal}
      onClose={() => modalOnClose(false)}
      className="abyss-modal-content-title"
      css={{
        'abyss-modal-content-title': {
          color: 'rgb(0, 38, 119)',
        },
      }}
    >
      <Modal.Section>
        <ModalText>
          To keep your API credentials active in {credentialsAPIEnvironment()},
          you must regenerate your client secret (password) using the following
          steps: Dashboard {'>'} My Organization {'>'} Credentials.
        </ModalText>
        <Note>Note: Only the secret keeper is able to update the secret.</Note>
        <ButtonStyle>
          <Button onClick={() => modalOnClose(false)}>Acknowledged</Button>
        </ButtonStyle>
      </Modal.Section>
    </Modal>
  );
};

SecretExpireModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  modalOnClose: PropTypes.func.isRequired,
};

export const convertNode = ({ url, method, headers, body }) => {
  const codeArr = [];
  codeArr.push(`var axios = require('axios');`);

  if (body) {
    codeArr.push(`var data = '${JSON.stringify(body)}';`);
  }

  codeArr.push('\nvar config = {');
  codeArr.push(`  method: '${method}',`);
  codeArr.push(`  url: '${url}',`);

  codeArr.push('  headers: {');
  const headerArr = headers.map(header => {
    return `    '${header.key}': '${header.value}',`;
  });
  codeArr.push(`${headerArr.join('\n')}`);
  codeArr.push(`  }${body ? ',' : ''}`);
  if (body) {
    codeArr.push(`  data : data`);
  }
  codeArr.push('};');

  codeArr.push('\naxios(config)');
  codeArr.push('.then(function (response) {');
  codeArr.push(`  console.log(JSON.stringify(response.data));`);
  codeArr.push('})');
  codeArr.push('.catch(function (error) {');
  codeArr.push(`  console.log(error);`);
  codeArr.push('});');

  return codeArr.join('\n');
};

import React from 'react';
import { styled } from '@abyss/web/tools/styled';

import { PageLayout } from 'src/common/PageLayout/PageLayout';

const TextCenter = styled('div', {
  textAlign: 'center',
  color: 'Blue',
  fontSize: '50px',
  marginTop: '150px',
  lineHeight: '100px',
});

export const UnderConstruction = () => {
  return (
    <div>
      <PageLayout>
        <TextCenter>This page is under construction</TextCenter>
      </PageLayout>
    </div>
  );
};

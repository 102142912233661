import React from 'react';
import { styled } from '@abyss/ui/tools/styled';
import PropTypes from 'prop-types';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Button } from '@abyss/web/ui/Button';
import { event } from '@abyss/web/tools/event';
import { useCopy } from '@abyss/web/hooks/useCopy';

const Wrapper = styled.div``;

const IdBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    margin-bottom: 0;
  }
`;

export const DisplayField = ({ label, value, environment }) => {
  const { onCopy } = useCopy(value);
  const copyEvent = () => {
    const interactionName = 'credentials';
    const interactionValue = `${environment} ${label}`;
    const interactionContext = 'copy code';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };
  return (
    <Wrapper>
      <p id={`${label}`} className="label">
        {label}
      </p>
      <IdBox>
        <p id={`${value}`}>{value}</p>
        <Button
          id={`${label}-${value}`}
          size="$sm"
          onClick={() => {
            onCopy();
            copyEvent();
          }}
          variant="outline"
          css={{
            'abyss-button-root': {
              border: 'none',
            },
          }}
        >
          <IconMaterial icon="content_copy" color="$primary1" />
        </Button>
      </IdBox>
    </Wrapper>
  );
};

DisplayField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
};

import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';

const Container = styled('div', {
  paddingBottom: '16px',
  paddingTop: '16px',
  background: 'rgb(255, 255, 255)',
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
});

const ContainerDesc = styled('p', {
  color: '#424242',
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
});

const ContainerDesc2 = styled('p', {
  textAlign: 'center',
  justifyContent: 'center',
  fontWeight: 400,
  fontSize: '15px',
  marginTop: '24px',
  lineHeight: '20px',
});

const ContainerDesc1 = styled('div', {
  fontWeight: 700,
  fontSize: '15px',
  color: '#C40000',
  textAlign: 'center',
  justifyContent: 'center',
  lineHeight: '18px',
  marginTop: '7px',
});

const RegistrationLinkContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  wrap: 'wrap',
  fontWeight: 'bold',
});

const IconMargin = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

export const DeniedConnectToOrganization = () => {
  return (
    <Container>
      <ContainerDesc>API Subscription Request Status</ContainerDesc>
      <RegistrationLinkContent>
        <IconMargin>
          <IconMaterial icon="error_outline" size="29px" color="$error1" />
        </IconMargin>
      </RegistrationLinkContent>
      <ContainerDesc1>Request Denied</ContainerDesc1>
      <ContainerDesc2>
        Please reach out to &nbsp;
        <Link href="mailto:apiconsultant@uhc.com">apiconsultant@uhc.com</Link>
        &nbsp; for further questions.
      </ContainerDesc2>
    </Container>
  );
};

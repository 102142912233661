import { ACTION_TYPE } from './useClaimSummary';

const initialState = {
  data: [],
};

export const reducerClaimSummary = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_MOCK`:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  },
};

import React, { useEffect, useContext, useState } from 'react';
import { registerAnalytics } from 'src/tools/analytics';
import { Switch } from '@abyss/ui/router/Switch';
import { Route } from '@abyss/ui/router/Route';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { useSelector } from 'react-redux';
import {
  onBreadcrumbsHandler,
  onBreadcrumbsHandlerIgnoreLatest,
} from 'src/hooks/useBreadcrumbs';
import { config } from '@abyss/ui/tools/config';
import { SecretExpireModal } from './Content/Welcome/Credentials/SecretExpireModal/SecretExpireModal';
import { Header as NewHeader } from './NewHeader';
import { Footer } from './Footer';
import { Landing } from './Content/Landing';
import { AllAPIs } from './Content/AllAPIs/AllAPIs';
import { AppContext } from './AppProvider';
import { UnderConstruction } from './Content/UnderConstruction';
import { AllClaimsAPI } from './Content/AllAPIs/AllClaimsAPI/AllClaimsAPI';
import { ClaimStatusX12API } from './Content/AllAPIs/ClaimStatusX12API/ClaimStatusX12API';
import { EligibilityLightWeightAPI } from './Content/AllAPIs/EligibilityLightWeightAPI/EligibilityLightWeightAPI';
import { ReferralsAPI } from './Content/AllAPIs/ReferralsAPI/ReferralsAPI';
import { EligibilityBasicServicesAPI } from './Content/AllAPIs/EligibilityBasicServicesAPI/EligibilityBasicServicesAPI';
import { EligibilityAndBenefits } from './Content/AllAPIs/EligibilityAndBenefits/EligibilityAndBenefits';
import { PriorAuthAPI } from './Content/AllAPIs/PriorAuthAPI/PriorAuthAPI';
import { Credentials } from './Content/Welcome/Credentials/Credentials';
import { ProviderDemographicAPI } from './Content/AllAPIs/ProviderDemographicAPI/ProviderDemographicAPI';
import { ReviewAndRemoveTins } from './Content/Welcome/TinManager/ReviewAndRemoveTins';
import { Documentation } from './Content/Documentation';
import { SiteMap } from './Content/SiteMap/SiteMap';
import { Welcome } from './Content/Welcome/Welcome';
import { GettingStarted } from './Content/GettingStarted/GettingStarted';
import { ApiFeedback } from './Content/Welcome/OrganizationApi/OrganizationList/ApiFeedback';
import { ReleaseNotes } from './Content/Welcome/ReleaseNotes';
import { LagoonKnowledgeBase } from './Content/Welcome/LagoonKnowledgeBase';
import { UploadTins } from './Content/Welcome/TinManager/UploadTins';
import { AllCommunications } from './Content/Welcome/AllCommunications/AllCommunications';
import { CreateCommunication } from './Content/Welcome/CreateCommunication/CreateCommunication';

const MainContainer = styled('div', {
  background: '#F4F4F4',
  flex: '1 0 auto',
  minHeight: window.innerHeight - 150,

  '@media print': {
    minHeight: 0,
    height: '100%',
    paddingBottom: 3,
  },
});

const Body = styled('div', {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',

  '@media print': {
    minHeight: 0,
    height: '100%',
  },
});

export const App = () => {
  const [setBreadcrumbs] = onBreadcrumbsHandler();
  const [setBreadcrumbsIgnoreLatest] = onBreadcrumbsHandlerIgnoreLatest();
  const { setPreviousPath } = useContext(AppContext);
  const userStatus = useSelector(state => state.GET_CREDENTIALS);
  const featureToggles = useLagoon('feature-toggles');
  const communicationCorner = featureToggles('COMMUNICATION_CORNER')?.enabled;
  const clientView = featureToggles('COMMUNICATION_CORNER_CLIENT_VIEW')
    ?.enabled;
  const providerDemographic = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;

  useEffect(() => {
    registerAnalytics();
  }, []);

  useEffect(() => {
    const handleKey = e => {
      if (e.ctrlKey && e.keyCode === 80) {
        event('PRINT');
      }
    };
    window.addEventListener('keydown', handleKey);
    return function cleanup() {
      window.removeEventListener('keydown', handleKey);
    };
  }, []);

  const [showSecretModal, setShowSecretModal] = useState(false);

  useEffect(() => {
    if (
      userStatus?.data?.credentials?.prod?.showCredentialsExpiryAlert ||
      userStatus?.data?.credentials?.stage?.showCredentialsExpiryAlert
    ) {
      setShowSecretModal(true);
    }
  }, [userStatus]);

  const breadcrumbsHandler = data => {
    setBreadcrumbs(data);
  };

  const breadcrumbsHandlerIgnoreLatest = data => {
    setBreadcrumbsIgnoreLatest(data);
  };

  const setPath = data => {
    setPreviousPath(data.history.location.pathname);
  };

  return (
    <Body>
      <NewHeader />
      <MainContainer>
        <SecretExpireModal
          showModal={showSecretModal}
          modalOnClose={setShowSecretModal}
        />
        <Switch title="API Marketplace">
          <Route
            path="/all-apis/eligibility-api"
            exact
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <EligibilityBasicServicesAPI />;
            }}
          />
          <Route
            path="/all-apis/eligibility-and-benefits-extended-x12-api"
            exact
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <EligibilityAndBenefits />;
            }}
          />
          <Route
            path="/all-apis/eligibility-lightweight-api"
            exact
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <EligibilityLightWeightAPI />;
            }}
          />
          <Route
            path="/all-apis/referral-graphQL-api"
            exact
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <ReferralsAPI />;
            }}
          />
          <If condition={providerDemographic}>
            <Route
              path="/all-apis/provider-demographic-api"
              exact
              render={props => {
                setPath(props);
                breadcrumbsHandler(props);
                return <ProviderDemographicAPI />;
              }}
            />
          </If>
          <Route
            path="/all-apis/all-claims-api"
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <AllClaimsAPI />;
            }}
          />
          <Route
            path="/all-apis/prior-auth-api"
            exact
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <PriorAuthAPI />;
            }}
          />
          <Route
            path="/all-apis/claim-status-X12-api"
            exact
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <ClaimStatusX12API />;
            }}
          />

          <Route
            path="/documentation/:service/:slug?"
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <Documentation />;
            }}
          />
          <Route
            title="Credentials"
            exact
            path="/welcome/credentials"
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <Credentials />;
            }}
          />
          <If condition={communicationCorner}>
            <Route
              title="Create Communication"
              exact
              path="/welcome/create-communication"
              render={props => {
                setPath(props);
                breadcrumbsHandler(props);
                return <CreateCommunication />;
              }}
            />
            <If condition={clientView}>
              <Route
                title="All Communications"
                exact
                path="/welcome/all-communications"
                render={props => {
                  setPath(props);
                  breadcrumbsHandler(props);
                  return <AllCommunications />;
                }}
              />
            </If>
          </If>
          <If condition={config('TIN_MANAGEMENT_CONFIG')}>
            <Route
              title="ReviewTins"
              exact
              path="/welcome/review-tins"
              render={props => {
                setPath(props);
                breadcrumbsHandler(props);
                return <ReviewAndRemoveTins />;
              }}
            />

            <Route
              title="AddTins"
              path="/welcome/add-tins"
              render={props => {
                return <UploadTins />;
              }}
            />
          </If>
          <Route
            title="releaseNotes"
            path="/welcome/release-notes/:resource-:type-notes"
            render={props => {
              setPath(props);
              breadcrumbsHandlerIgnoreLatest(props);
              return <ReleaseNotes />;
            }}
          />
          <Route
            title="KnowledgeBase"
            exact
            path="/welcome/knowledge-base/:resource"
            render={props => {
              setPath(props);
              breadcrumbsHandlerIgnoreLatest(props);
              return <LagoonKnowledgeBase />;
            }}
          />
          <Route
            title="KnowledgeBase"
            // exact
            path="/welcome/knowledge-base"
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <LagoonKnowledgeBase />;
            }}
          />
          <Route
            title="releaseNotes"
            // exact
            path="/welcome/release-notes"
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <ReleaseNotes />;
            }}
          />
          <Route
            title="Feedback"
            exact
            path="/welcome/:service?-feedback"
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <ApiFeedback />;
            }}
          />
          <Route
            path="/under-construction"
            exact
            render={props => {
              setPath(props);
              breadcrumbsHandler(props);
              return <UnderConstruction />;
            }}
          />
          <Route
            path="/all-apis"
            render={props => {
              setPath(props);
              return <AllAPIs {...props} />;
            }}
          />
          <Route
            path="/site-map"
            render={props => {
              return <SiteMap />;
            }}
          />
          <Route
            path="/getting-started"
            render={props => {
              setPath(props);
              return <GettingStarted />;
            }}
          />

          <Route
            path="/contact-us"
            render={props => {
              return <GettingStarted />;
            }}
          />

          <Route
            title="Dashboard"
            exact
            path="/"
            render={props => {
              setPath(props);
              return <Landing />;
            }}
          />
          <Route
            title="Welcome"
            exact
            path="/welcome"
            render={props => {
              setPath(props);
              return <Welcome />;
            }}
          />
        </Switch>
      </MainContainer>
      <Footer />
    </Body>
  );
};

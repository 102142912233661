import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/ui/tools/classNames';

import { baseStyles } from './AccordionPanel.styles';
import { AccordionContext } from '../AccordionContext';

export const AccordionPanel = ({
  id,
  index,
  className,
  styles,
  isDisabled,
  isPanelOpen,
  isOpen,
  defaultOpen,
  size,
  variant,
  onClick,
  onChange,
  isVisible,
  ...props
}) => {
  if (!isVisible) {
    return null;
  }

  const isOpenFlag = typeof isOpen === 'boolean' ? isOpen : isPanelOpen;
  const styleProps = { isOpen: isOpenFlag, size, variant };
  const classes = useStyles(baseStyles, { ...styles, ...styleProps });

  const contextValues = {
    panelId: id,
    index,
    isOpen: isOpenFlag,
    size,
    variant,
    onAccordionClick: onClick,
  };

  return (
    <AccordionContext.Provider value={contextValues}>
      <div {...props} className={classNames(classes.panel, className)} />
    </AccordionContext.Provider>
  );
};

AccordionPanel.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  isPanelOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'custom']),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isVisible: PropTypes.bool,
};

AccordionPanel.defaultProps = {
  id: null,
  index: null,
  className: null,
  styles: null,
  isDisabled: false,
  isPanelOpen: false,
  isOpen: null,
  defaultOpen: false,
  size: 'size700',
  variant: 'default',
  onClick: null,
  onChange: null,
  isVisible: true,
};

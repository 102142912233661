import React, { useState, useEffect } from 'react';
import { useRegenerateSecret } from 'src/hooks/useRegenerateSecret';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { useSelector } from 'react-redux';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { DisplayField } from './DisplayField';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '18px',
  width: '100%',
  background: '#ffffff',
  border: '1px solid #000000',
  boxSizing: 'border-box',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
});

const StyledBoxHeading = styled('p', {
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '25.63px',
  lineHeight: '32px',
  color: '#002677',
  margin: '0',
});

const InpContainer = styled('div', {
  padding: '10px',
  width: '100%',
  '.label': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '0',
  },
});

const DetailContainer = styled('div', {
  paddingTop: '20px',
  '.line1': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#000000',
  },

  '.secretg': {
    fontWeight: 'normal',
    ontSize: '14px',
  },
});
const NonData = styled('div', {
  padding: '20px 10px 10px',
  p: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#000000',
  },
});
const BtnWrapper = styled('div', {
  width: '100%',
  alignItems: 'left',
  display: 'flex',
  direction: 'rtl',
  paddingTop: '10px',
  paddingBottom: '10px',
  flexGap: '26px',
  flexWrap: 'Wrap',
});

const SecretSubText = styled('p', {
  color: 'red',
  fontSize: '15px',
});

const GenerateRequiredError = styled('p', {
  color: 'red',
  fontSize: '15px',
});

export const Box = ({
  clientId,
  clientSecret,
  createdTs,
  createdBy,
  expiryDate,
  heading,
  showData,
  isSecretKeeper,
  description,
  regenSecret,
  setRegenSecretEnv,
  isSecretGenerated,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [displayGenerateError, setDisplayGenerateError] = useState(false);
  const [regeneratedSecret, getRegeneratedSecret] = useRegenerateSecret();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  useEffect(() => {
    setIsLoading(regeneratedSecret.loading);
    if (!regeneratedSecret.loading && regeneratedSecret.error) {
      setDisplayGenerateError(true);
    }
  }, [regeneratedSecret]);

  const regenerateSecret = () => {
    if (heading === 'Production') {
      setRegenSecretEnv('prod');
    } else {
      setRegenSecretEnv('stage');
    }

    regenSecret(true);
    const jobName = 'regenerate secret key';
    const modalName = 'change API secret key modal pop up';
    event('JOB_START', {
      event,
      jobName,
    });
    event('MODAL_VIEW', {
      event,
      modalName,
    });
  };

  const generateSecret = () => {
    let isProd = false;
    let env = 'stage';
    if (heading === 'Production') {
      setRegenSecretEnv('prod');
      env = 'prod';
      isProd = true;
    } else {
      setRegenSecretEnv('stage');
    }
    const values = { clientId, isProd, isSecretGenerated };
    getRegeneratedSecret({
      variables: { values, regenerateEnv: env, isSecretGenerated },
      userInfo,
    });
    const jobName = 'generate secret key';

    event('JOB_START', {
      event,
      jobName,
    });
  };

  return (
    <Wrapper>
      <StyledBoxHeading id={`${heading}`}>{heading}</StyledBoxHeading>
      {showData ? (
        <React.Fragment>
          <InpContainer>
            <DisplayField
              label="Client Id"
              value={clientId}
              environment={heading}
            />
            <br />
            <If condition={isSecretKeeper}>
              <If condition={clientSecret}>
                <DisplayField
                  id={`${heading}-secret`}
                  label="Secret"
                  value={clientSecret}
                />
                <SecretSubText id={`${heading}-secret-sub-text`}>
                  This secret would be only available to view only now. Please
                  make sure to save and secure it. If you refresh or sign out,
                  it will be lost.
                </SecretSubText>
              </If>
              <Choose>
                <When condition={isSecretGenerated}>
                  <BtnWrapper>
                    <Button
                      size="$md"
                      variant="outline"
                      onClick={regenerateSecret}
                      ariaLoadingLabel="Generating Secret"
                      id="regenerate-secret-button"
                    >
                      Regenerate Secret
                    </Button>
                  </BtnWrapper>
                </When>
                <Otherwise>
                  {displayGenerateError && (
                    <GenerateRequiredError id="regenerate-secret-error">
                      The secret generation was unsuccessful, please try again.
                    </GenerateRequiredError>
                  )}
                  <BtnWrapper>
                    <Button
                      size="$md"
                      variant="outline"
                      onClick={generateSecret}
                      isLoading={isLoading}
                      id="generate-secret-button"
                    >
                      Generate Secret
                    </Button>
                  </BtnWrapper>
                </Otherwise>
              </Choose>
            </If>
            <DetailContainer>
              <p id="detail-container" className="line1">
                Secret generated on:{' '}
                <span id={`${createdTs}`} className="secretg">
                  {createdTs ? `${createdTs} EST` : ''}
                </span>
              </p>
              <p id="secret-expires" className="line1">
                Secret expires on:{' '}
                <span className="secretg">
                  {expiryDate ? `${expiryDate} EST` : ''}
                </span>
              </p>
              <p id="created-by" className="line1">
                Generated by: <span className="secretg">{createdBy}</span>
              </p>
            </DetailContainer>
          </InpContainer>
        </React.Fragment>
      ) : (
        <NonData>
          <p id="description">{description}</p>
        </NonData>
      )}
    </Wrapper>
  );
};

Box.propTypes = {
  clientId: PropTypes.string.isRequired,
  createdTs: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showData: PropTypes.bool.isRequired,
  isSecretKeeper: PropTypes.bool.isRequired,
  regenSecret: PropTypes.func.isRequired,
  setRegenSecretEnv: PropTypes.func.isRequired,
  clientSecret: PropTypes.string.isRequired,
  isSecretGenerated: PropTypes.bool.isRequired,
};

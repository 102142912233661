import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { Button } from '@abyss/web/ui/Button';
import { StyledLink } from 'src/common/Link';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { Testimonials } from 'src/common/Testimonials/Testimonials';
import { testimonialMock } from 'src/app/App/__mock_data__/testimonialMock';
import { APISlider } from 'src/common/APISlider/APISlider';
import { event } from '@abyss/web/tools/event';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { relatedAPIsMock } from 'src/app/App/__mock_data__/eligibilityBasicServices_relatedAPIs';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { SubscribeModal } from '../SubscribeModal';
import { SubscribeToAPIModal } from '../../SubscribeToAPIModal';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { constants } from '../../../../../common/Utils/Constants';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
});

const Container = styled('div', {
  flexBasis: '100%',
});

const Flexed = styled('div', {
  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
  },
});

const PageHeadingContainer = styled('div', {
  display: 'flex',
  marginTop: '8px',
  marginBottom: '32px',
  flexBasis: '50%',
});

const StyledIcon = styled('div', {
  height: '49.5px',
  width: '60px',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $sm': {
    display: 'block',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px',
  fontWeight: 600,
  minHeight: '40px',
  lineHeight: '40px',
  marginTop: '8px',
});

const ContainerHalf = styled('div', {
  flexBasis: '50%',
});

const ButtonMargin = styled('div', {
  margin: '24px 0px',
  textAlign: 'right',

  '@screen >= $sm': {
    textAlign: 'left',
  },

  '@screen >= $xl': {
    textAlign: 'right',
  },
});

const PageSubHeading = styled('h2', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px !important',
  fontWeight: 600,
  minHeight: '32px',
  lineHeight: '32px',
  marginBottom: '16px',
});

const PageDesc = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '16px',
});

const UL = styled('ul', {
  margin: '32px 0px 32px 48px',
  listStyle: 'disc',
});

const LI = styled('li', {
  margin: '8px',
  fontWeight: 'normal',
});

const PageLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  marginTop: '32px',
  marginBottom: '0px',
});

const AnchorBox = styled('div', {
  height: '20px',
  display: 'flex',
  marginBottom: '16px',
  marginTop: '8px',
  marginLeft: '8px',
});

const LinkMargin = styled('div', {
  marginRight: '12px',
  alignSelf: 'center',
});

const PageSecondaySubHeading = styled('h4', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginTop: '16px',
  marginBottom: '16px',
});

const PageContent = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '48px',
});

const SecondHeading = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginTop: '16px',
  marginBottom: '16px',
});

export const EligibilityBasicServicesAPI = () => {
  const router = useRouter();
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const [, getDSUserInfo] = useDSUserInfo();

  const interactionContextPageCTALinkClicks = 'page cta link clicks';
  const interactionName = 'Eligibility Basic Services API';

  useEffect(() => {
    if (userInfo.uuid && !dsUserInfo.data.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  const subscribeToAPI = () => {
    const interactionValue = constants.SUBSCRIBE_TO_API;
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext: interactionContextPageCTALinkClicks,
    });
  };

  const subscribeClicked = () => {
    const jobName = constants.SUB_TO_API_JOB_NAME;

    subscribeToAPI();
    event('SUBSCRIBE_EVENT', {
      event,
      jobName,
    });

    if (userInfo.firstName === '' || userInfo.error) {
      setIsSubscribeModalOpen(true);
    } else {
      setShowSubscribeAPIModal(true);
    }
  };

  useEffect(() => {
    event('API_DETAILS_ELIGIBILITY_PAGE_LOAD', {
      dsUserInfo: dsUserInfo.data,
      userInfo,
    });
  }, []);

  const accessSandbox = () => {
    const interactionValue = 'access sandbox';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext: interactionContextPageCTALinkClicks,
    });
    router.push('/documentation/eligibility');
  };

  const apiDocumentation = () => {
    const interactionValue = 'api technical documentation';
    const interactionContext = 'page cta link clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    router.push('/documentation/eligibility');
  };

  return (
    <PageLayout>
      <SubscribeModal
        setIsSubscribeModalOpen={setIsSubscribeModalOpen}
        isSubscribeModalOpen={isSubscribeModalOpen}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Wrapper>
        <StyledFlex>
          <Container>
            <Breadcrumbs />
            <Flexed>
              <PageHeadingContainer>
                <StyledIcon>
                  <IconBrand icon="id_card" size={48} />
                </StyledIcon>
                <PageHeading>Eligibility API</PageHeading>
              </PageHeadingContainer>
              <ContainerHalf>
                <ButtonMargin>
                  <Button
                    variant="solid"
                    onClick={apiDocumentation}
                    css={{
                      backgroundColor: '#002677',
                      fontSize: '15px',
                      height: '42px',

                      '@screen >= $xs': {
                        width: '100%',
                      },

                      '@media (min-width: 576px)': {
                        width: '339px',
                      },
                    }}
                  >
                    API Technical Documentation
                  </Button>
                </ButtonMargin>
              </ContainerHalf>
            </Flexed>
            <PageSubHeading>Overview</PageSubHeading>
            <PageDesc>
              The Eligibility Basic Services API returns eligibility, benefit
              and copay information in real time. It also retrieves additional
              plan and member information including, but not limited to:
              <UL>
                <LI>Primary care provider (PCP) details</LI>
                <LI> Plan coverage </LI>
                <LI> Benefit details </LI>
                <LI> Member ID card </LI>
              </UL>
            </PageDesc>
            <PageLinkContainer>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    id="SubscribeToAPI"
                    onClick={subscribeClicked}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    {constants.SUBSCRIBE_TO_API}
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    onClick={accessSandbox}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    Access Sandbox
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
            </PageLinkContainer>
            <PageSecondaySubHeading>
              About Eligibility API
            </PageSecondaySubHeading>
            <PageContent>
              Offers following functionalities:
              <UL>
                <LI>Eligibility and benefits information for a member</LI>
                <LI>Member copay information for a base set of services</LI>
                <LI>Member copay information for specified services</LI>
                <LI> Language preference </LI>
                <LI> Member ID card </LI>
                <LI> Network status </LI>
                <LI> PCP details </LI>
                <LI> Employer history </LI>
                <LI> Preferred Lab Network </LI>
                <LI> Service/benefit coordinator </LI>
                <LI> Vision services </LI>
                <LI>
                  Determine Prior Auth Requirement or Check Prior Auth Necessity
                </LI>
              </UL>
              The API uses JavaScript Object Notation (JSON) for requests and
              responses. All API traffic is encrypted over HTTPS and
              authentication is handled with OAuth2. 
              <SecondHeading />
              <PageDesc>
                Allowed Payer IDs for all the Eligibility APIs is 87726.
              </PageDesc>
            </PageContent>
            <SecondHeading>Prerequisites</SecondHeading>
            <PageContent>
              The Consumer should have valid Client-ID/Secrets for the APIs to
              be fully functional.
            </PageContent>
            <SecondHeading>Security</SecondHeading>
            <PageContent>
              API's are secured using OAuth 2.0. All calls to APIs are encrypted
              over HTTPS. APIs support connections using TLS version 1.2 or
              higher. All modern languages and frameworks support TLS 1.2
              (although specific older ones do not).{' '}
            </PageContent>
          </Container>
        </StyledFlex>
        <Testimonials initialData={testimonialMock} />
        <APISlider initialData={relatedAPIsMock} />
      </Wrapper>
    </PageLayout>
  );
};

export const constants = {
  API_FEEDBACK: 'API Feedback',
  FONT_COLOR_BLACK: 'rgb(0, 0, 0)',
  FONT_COLOR_NAVY_BLUE: 'rgb(0, 38, 119)',
  FONT_COLOR_GREY: 'rgb(51, 51, 51)',
  FONT_COLOR_WHITE: 'rgb(255, 255, 255)',
  GETTING_STARTED: 'getting-started',
  IMPORTANT_TAG_ZERO: '0 !important',
  KNOWLEDGE_BASE: 'knowledge-base',
  KNOWLEDGE_BASE_LINK: '/welcome/knowledge-base',
  LAST_VIEWED_DATE: 'last-viewed-date',
  VIEW_API_DETAILS: 'View API Details',
  SUBSCRIBE_TO_API: 'Connect with a consultant to subscribe to an API',
  SUB_TO_API_JOB_NAME: 'request a meeting with an api consultant',
  UHC_NOTIFY_MODAL_NAME: 'unitedHealthcare api notification modal',
};

import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { PageLayout } from 'src/common/PageLayout';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { Loading } from '@abyss/ui/base/Loading';
import { storage } from '@abyss/web/tools/storage';
import { Alert } from '@abyss/web/ui/Alert';
import { global } from '@abyss/ui/tools/styled';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { useSelector } from 'react-redux';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { styled } from '@abyss/web/tools/styled';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';
import { useUserStatus } from 'src/hooks/useUserStatus';
import { StyledMarkdownLink } from '../../../../common/StyledMarkdownLink';
import { OrganizationApi } from './OrganizationApi';
import { BrowseApi } from './BrowseApi';
import { Resources } from './Resources';
import { constants } from '../../../../common/Utils/Constants';
import { SubscribeToAPIModal } from '../SubscribeToAPIModal';
import { useHolidayApi } from '../../../../hooks/useHolidayApi';
import { CommonAlert } from '../../../../common/Alert/CommonAlert';

const Wrapper = styled('div', {
  '.abyss-alert-root': {
    marginBottom: '20px',
  },

  '@screen >= $xs': {
    padding: '44px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '46px',
  fontStyle: 'normal',
  lineHeight: '56px',
  letterSpacing: '0px',
  textAlign: 'left',
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
  marginTop: '32px',
});

const PageHeadingContainer = styled('div', {
  marginTop: '122',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
});

const AlertHeadertyled = global({
  '.abyss-alert-title': {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
  },
  '.abyss-alert-inline-text': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
});

const HeaderResponsiveDiv = styled('div', {
  display: 'block',
});

const APIAlertNameStyle = styled('p', {
  marginBottom: '0px !important',
});

export const Welcome = () => {
  const [isPageLoadTriggered, setIsPageLoadTriggered] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const contentManagement = useLagoon(`alerts`);
  const alertList = contentManagement();
  const alerts =
    alertList?.length > 0 ? alertList?.filter(el => el.active) : null;
  const router = useRouter();
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const [, getUserInfo] = useUserInfo();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const healthCheckApiData = useSelector(state => state.GET_API_HEALTH_CHECK);
  const [, setHolidayDate] = useHolidayApi();
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const [runCredentials, setRunCredentials] = useState(false);
  const [userStatus, getUserStatus] = useUserStatus();
  const [dsApiAssociatedLength, setDsApiAssociatedLength] = useState(0);
  const cards = useLagoon('browse-api-list');
  const cardList = cards()?.filter(card => card.active);
  const featureToggles = useLagoon('feature-toggles');
  const priorAuthToggle = featureToggles('PRIOR_AUTH_TOGGLE')?.enabled;

  let outageAlertMessage;
  let alertApiName;

  const getAlertByType = alertType => {
    return alerts?.length > 0
      ? alerts?.filter(el => el.alertType === alertType)
      : [];
  };

  const releaseNotesAlertList = getAlertByType(
    'welcome-page-marketplace-update'
  );

  const releaseNotesAlert =
    releaseNotesAlertList && releaseNotesAlertList.length
      ? releaseNotesAlertList[0]
      : null;

  useEffect(() => {
    storage.local.set('IS_SIGN_IN_CLICKED', false);
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }
    if (!userInfo.uuid) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (runCredentials && !userStatus?.loading && !userStatus?.data?.userid) {
      const orgId = dsUserInfo?.data?.orgId;
      getUserStatus({ userInfo, orgId });
      setRunCredentials(false);
    }
  }, [userStatus, runCredentials]);

  useEffect(() => {
    if (dsUserInfo?.data?.uuid) {
      setRunCredentials(true);
    }
    if (
      !dsUserInfo.loading &&
      !isPageLoadTriggered &&
      userInfo.uuid &&
      (dsUserInfo?.data?.uuid || dsUserInfo?.data?.status === 'notprovisioned')
    ) {
      setIsPageLoadTriggered(true);
      setHolidayDate({ userInfo });
      event('NEW_PAGE_LOAD', {
        pageName: 'apimarketplace:welcome',
        siteSectionL1: 'welcome',
        dsUserInfo: dsUserInfo.data,
        userInfo,
      });
    }
  }, [isPageLoadTriggered, dsUserInfo, userInfo]);

  const addDataIntoStorage = () => {
    storage.local.set(constants.LAST_VIEWED_DATE, +new Date());
  };

  const getAlertDateValue = () => {
    let updatedDate = releaseNotesAlert ? releaseNotesAlert.date : '';
    updatedDate = new Date(updatedDate);
    const viwedDate = storage.local.get(constants.LAST_VIEWED_DATE);
    return !(viwedDate && viwedDate > updatedDate);
  };

  const errorAlertVisibility = () => {
    return !!(
      dsUserInfo?.data?.linkedApis?.prod?.length > 0 &&
      !dsUserInfo.data?.linkedApis?.stage
    );
  };

  const getStageAndProdApis = userOrgInfo => {
    return {
      stageApis: userOrgInfo.linkedApis?.stage || [],
      prodApis: userOrgInfo.linkedApis?.prod || [],
    };
  };

  const apiDataList = (apisList, env) => {
    return apisList?.length > 0
      ? apisList.filter(item => {
          const api = healthCheckApiData?.data?.[env]?.[item];
          return api && api !== 200;
        })
      : [];
  };

  const API_OUTAGE_BOTH = 'api-outage-both';
  const MULTIPLE_OUTAGE_ALERT = 'multiple-outage-alert';
  const API_OUTAGE_STAGE = 'api-outage-stage';
  const API_OUTAGE_PROD = 'api-outage-prod';

  const outageAlertVisibility = () => {
    const { stageApis, prodApis } = getStageAndProdApis(dsUserInfo.data);

    const stageDataList = apiDataList(stageApis, 'stage');
    const prodDataList = apiDataList(prodApis, 'prod');

    // Multiple alerts on stage and prod
    if (stageDataList?.length > 0 && prodDataList?.length > 0) {
      // more then 1 alert in both
      if (stageDataList.length > 1 || prodDataList?.length > 1) {
        outageAlertMessage = getAlertByType(MULTIPLE_OUTAGE_ALERT);
      } else if (stageDataList[0] === prodDataList[0]) {
        // only 1 alert in both
        outageAlertMessage = getAlertByType(API_OUTAGE_BOTH);
        [alertApiName] = stageDataList;
      } else {
        outageAlertMessage = getAlertByType(MULTIPLE_OUTAGE_ALERT);
      }
      return true;
    }

    // Single alerts on stage
    if (stageDataList?.length > 0) {
      if (stageDataList?.length > 1) {
        outageAlertMessage = getAlertByType(MULTIPLE_OUTAGE_ALERT);
        return true;
      }
      outageAlertMessage = getAlertByType(API_OUTAGE_STAGE);
      [alertApiName] = stageDataList;
      return true;
    }

    // Single alerts on prod
    if (prodDataList?.length > 0) {
      if (prodDataList?.length > 1) {
        outageAlertMessage = getAlertByType(MULTIPLE_OUTAGE_ALERT);
        return true;
      }
      outageAlertMessage = getAlertByType(API_OUTAGE_PROD);
      [alertApiName] = prodDataList;
      return true;
    }
    return false;
  };

  const onAlertRouting = () => {
    router.push(releaseNotesAlert?.buttonLink);
    storage.local.set(constants.LAST_VIEWED_DATE, +new Date());
  };

  const getAlertMessage = () => {
    return (
      <If condition={releaseNotesAlert?.alertMessage}>
        <Markdown
          id="marketplace-alert-text"
          options={{
            overrides: {
              a: StyledMarkdownLink,
            },
          }}
        >
          {releaseNotesAlert.alertMessage}
        </Markdown>
      </If>
    );
  };

  const getOutageAlertMessage = () => {
    return (
      <HeaderResponsiveDiv>
        <APIAlertNameStyle>
          <If condition={alertApiName !== ''}>{alertApiName}</If>
          <If condition={outageAlertMessage?.length > 0}>
            <Markdown id="marketplace-outage-alert-text">
              {outageAlertMessage[0].alertMessage}
            </Markdown>
          </If>
        </APIAlertNameStyle>
      </HeaderResponsiveDiv>
    );
  };

  const linkedApisLength =
    dsUserInfo?.data?.linkedApis?.stage?.length > 0
      ? dsUserInfo?.data?.linkedApis?.stage?.length
      : 0;
  useEffect(() => {
    let finalLength = linkedApisLength;
    if (
      !priorAuthToggle &&
      dsUserInfo?.data?.linkedApis?.stage?.includes('PriorAuth')
    ) {
      finalLength = linkedApisLength - 1;
    }
    setDsApiAssociatedLength(finalLength);
  }, [dsUserInfo]);

  return (
    <PageLayout>
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Wrapper>
        <CommonAlert alertType="dashboard" />
        <Alert
          title="API Outage"
          variant="warning"
          isVisible={outageAlertVisibility()}
          inlineText={getOutageAlertMessage()}
        />
        <Alert
          title=""
          variant="error"
          isVisible={errorAlertVisibility()}
          inlineText="There seems to be an error with your credentials, please contact your API Consultant immediately."
        />
        <Choose>
          <When condition={userInfo?.firstName !== ''}>
            <If condition={getAlertDateValue() && releaseNotesAlert}>
              <AlertHeadertyled />
              <Alert
                title="Marketplace Update"
                variant="info"
                onAction={() => {
                  setIsVisible(false);
                  onAlertRouting();
                }}
                actionText={releaseNotesAlert?.buttonText}
                inlineText={getAlertMessage()}
                isVisible={isVisible}
                onClose={() => {
                  setIsVisible(false);
                  addDataIntoStorage();
                }}
              />
            </If>

            <StyledFlex>
              <PageHeadingContainer>
                <PageHeading id="welcome-user" style={{ fontSize: '46px' }}>
                  Welcome {userInfo.firstName}
                </PageHeading>
              </PageHeadingContainer>
            </StyledFlex>
          </When>
          <Otherwise>
            <Loading message="Signing In" isLoading />
          </Otherwise>
        </Choose>
        <OrganizationApi />
        <If condition={cardList?.length > dsApiAssociatedLength}>
          <BrowseApi />
        </If>
        <Resources />
      </Wrapper>
    </PageLayout>
  );
};

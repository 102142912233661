import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { APICard } from '../../../../../common/APICard';

const Wrapper = styled('div', {
  margin: '64px 0 0px 0',
});

const Heading = styled('h3', {
  height: '24px',
  color: 'rgb(0, 38, 119)',
  fontSize: '28px ! important',
  fontWeight: 600,
  lineHeight: '32px',
  marginBottom: '45px',
});

const CategoryBox = styled('div', {
  display: 'flex',
  gap: '15px',

  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
    gap: '15px',
  },
});

export const MostPopularApis = () => {
  const allFeaturedApis = useLagoon('featured-apis');
  const activeFeaturedApis = allFeaturedApis()?.filter(api => api.active);

  return (
    <Wrapper>
      <Heading id="mostPopular">Featured APIs</Heading>
      <CategoryBox>
        {activeFeaturedApis?.map((el, i) => {
          return (
            <APICard
              src={el.icon}
              title={el.title}
              description={el.description}
              link={el.link}
              linkLabel={el.linkLabel}
              index={i}
              badge={el.displayBadge}
              badgeMessage={el.badgeMessage}
              badgeLink={el.badgeLink}
              badgeVariant={el.badgeVariant}
              badgeIcon={el.badgeIcon}
              onClick={() => {
                const interactionName = el.title;
                const interactionValue = el.linkLabel;
                const interactionContext = 'tile cta clicks';
                event('INTERACTION_EVENT', {
                  interactionName,
                  interactionValue,
                  interactionContext,
                });
              }}
            />
          );
        })}
      </CategoryBox>
    </Wrapper>
  );
};

import { Card } from './Card';
import { CardHeader } from './CardHeader';
import { CardSection } from './CardSection';
import { VideoSection } from './VideoSection';

Card.Title = CardHeader;
Card.Header = CardHeader;
Card.Section = CardSection;
Card.VideoSection = VideoSection;

export { Card };

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from '@abyss/ui/router/Switch';
import { Route } from '@abyss/ui/router/Route';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { SubmittedTins } from '../SubmittedTins';
import { AddTins } from '../AddTins/AddTins';
import { useDSUserInfo } from '../../../../../../hooks/useDSUserInfo';

export const UploadTins = () => {
  const form = useForm();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const [, getUserInfo] = useUserInfo();
  const [, getDSUserInfo] = useDSUserInfo();
  const history = useRouter();

  useEffect(() => {
    storage.local.set('IS_SIGN_IN_CLICKED', false);
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }
    if (!userInfo?.firstName) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.firstName && !dsUserInfo?.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.firstName && !dsUserInfo.isAdmin) {
      history.goBack();
    }
  }, [dsUserInfo]);

  return (
    <FormProvider state={form}>
      <Switch>
        <Route
          title="Review tins"
          path="/welcome/add-tins/submitted-tins"
          component={SubmittedTins}
        />
        <Route title="Add tins" path="/welcome/add-tins" component={AddTins} />
      </Switch>
    </FormProvider>
  );
};

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'POST_SAVE_AS_DRAFT';
const eapm = window.elasticApm;

export const resetSaveDraftAsApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};
const convertToJson = (res, args) => {
  event('API_TRANSACTION', {
    fields: {
      appVersion: process.env.PACKAGE_VERSION,
      url: window.location.href,
      endpoint: config('COMMUNICATION_URL'),
      userInfo: args?.userInfo,
      method: 'POST',
      code: res.status,
      operationName: ACTION_TYPE,
      message: res.statusText,
      // result: hasReportCode ? 'success' : 'error',
    },
  });

  event('NEWRELIC_API_TRANSACTION', {
    code: res.status,
    message: res.statusText,
    endpoint: config('COMMUNICATION_URL'),
    userInfo: args?.userInfo,
    method: 'POST',
  });
  return new Promise(resolve =>
    res.json().then(response =>
      resolve({
        status: res.status,
        response,
      })
    )
  );
};
const getAction = () => {
  const action = useAction(args => {
    if (eapm) {
      window.elasticApm.startTransaction(ACTION_TYPE, 'http-request', {
        managed: true,
      });
    }
    const { variables = {} } = args;
    const { body } = variables;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
      dispatch({ type: `${ACTION_TYPE}_CALLED` });

      fetch(`${config('APIM_STARGATE_URL')}${config('COMMUNICATION_URL')}`, {
        credentials: 'include',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then(res => convertToJson(res, args))
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 500) {
              throw new Error(
                'Your request was unsuccessful, please try again.'
              );
            } else {
              dispatch({
                type: `${ACTION_TYPE}_ERROR`,
                payload: res.response,
              });
            }
          } else {
            dispatch({
              type: `${ACTION_TYPE}_COMPLETED`,
              payload: res.response,
            });
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          event('API_TRANSACTION_ERROR', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('COMMUNICATION_URL'),
              userInfo: args?.userInfo,
              method: 'POST',
              operationName: ACTION_TYPE,
              // code: res.status,
              // message: res.statusText,
            },
          });
        });
    };
  });
  return action;
};

export const useSaveDraftAsApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = getAction();
  return [result, action];
};

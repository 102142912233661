import React, { useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { InView } from 'react-intersection-observer';
import { CardContext } from '../CardContext';
import { FeedbackModal } from './FeedbackModal';
import { SubscribeToAPIModal } from '../../../app/App/Content/SubscribeToAPIModal/SubscribeToAPIModal';

const VideoSectionStyle = styled('div', {
  lineHeight: '16px',
  fontWeight: '$medium',
  display: 'flex',
  '& + &': {
    borderTop: '1px solid $gray4',
  },
  variants: {
    size: {
      small: {
        fontSize: '$sm',
      },
      medium: {
        fontSize: '$md',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export const VideoSection = ({
  children,
  maxBufferLength,
  maxMaxBufferLength,
  backBufferLength,
  pauseWhenNotInView,
  ...props
}) => {
  const [isPlaying, setIsplaying] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  if (!children) {
    return null;
  }

  const abyssProps = useAbyssProps(props);
  const { size } = useContext(CardContext);

  const handleChange = inView => {
    if (pauseWhenNotInView && !inView) {
      setIsplaying(false);
    }
  };

  return (
    <React.Fragment>
      <FeedbackModal
        isOpen={showFeedbackModal}
        title="Was this video helpful?"
        isClosed={setShowFeedbackModal}
        isShowSubscribeModal={setShowSubscribeAPIModal}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <InView onChange={inView => handleChange(inView)} threshold="0.8">
        <VideoSectionStyle
          {...props}
          {...abyssProps('card-video-section')}
          size={size}
        >
          <ReactPlayer
            {...props}
            {...abyssProps('card-video')}
            config={{
              hlsOptions: {
                maxBufferLength,
                maxMaxBufferLength,
                backBufferLength,
              },
              file: { attributes: { controlsList: 'nodownload' } },
            }}
            onContextMenu={e => e.preventDefault()}
            url={children}
            playing={isPlaying}
            controls
            onPlay={() => setIsplaying(true)}
            onPause={() => setIsplaying(false)}
            onEnded={() => {
              setIsplaying(false);
              setShowFeedbackModal(true);
            }}
            volume={1}
            loop={false}
          />
        </VideoSectionStyle>
      </InView>
    </React.Fragment>
  );
};

VideoSection.propTypes = {
  children: PropTypes.node.isRequired,
  maxBufferLength: PropTypes.number,
  maxMaxBufferLength: PropTypes.number,
  backBufferLength: PropTypes.number,
  pauseWhenNotInView: PropTypes.bool,
};

VideoSection.defaultProps = {
  maxBufferLength: 10,
  maxMaxBufferLength: 30,
  backBufferLength: 10,
  pauseWhenNotInView: false,
};

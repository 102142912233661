import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/ui/tools/classNames';

import { baseStyles } from './AccordionContent.styles';
import { AccordionContext } from '../AccordionContext';

export const AccordionContent = ({ children, className, styles }) => {
  const { panelId, isOpen, variant, size, onChange } = useContext(
    AccordionContext
  );

  const classes = useStyles(baseStyles, {
    ...styles,
    isOpen,
    size,
    variant,
  });

  useEffect(() => {
    if (onChange) {
      onChange(panelId, isOpen);
    }
  }, [isOpen]);

  return (
    <div
      aria-hidden={!isOpen}
      id={`accordion-panel-content-${panelId}`}
      aria-labelledby={`accordion-panel-header-${panelId}`}
      className={classNames(classes.content, className, {
        [classes.hidden]: !isOpen,
      })}
    >
      {children}
    </div>
  );
};

AccordionContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  styles: PropTypes.shape({}),
};

AccordionContent.defaultProps = {
  children: null,
  className: null,
  styles: null,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from '@abyss/ui/router/NavLink';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { event } from '@abyss/web/tools/event';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { Accordion } from 'src/common/Accordion';
import marketingDetailsRoutes from './MarketingDetailsPageRoutes.json';
import { StyledLink } from '../../../../../../common/Link/Link';

const Wrapper = styled('div', {});

const StyledNavLink = styled(NavLink, {
  display: 'block',
  textDecoration: 'none',
  padding: '0px 12px 0px 12px',

  '&:hover': {
    backgroundColor: '#E5F8FB',
    textDecoration: 'none',
  },
  '&.active': {
    color: '#000000',
    borderRight: ({ theme }) => `3px solid ${theme.colors.primary}`,
    backgroundColor: '#E5F8FB',
  },
});

const LinkText = styled('div', {
  display: 'flex',
  padding: '4px 0px 4px 18px',
});

const StyledMethod = styled('span', {
  variants: {
    color: {
      GET: { color: '#007000', fontWeight: 'bold' },
      POST: { color: '#196ecf', fontWeight: 'bold' },
      PUT: { color: '#c40000', fontWeight: 'bold' },
      DELETE: { color: '#c40000', fontWeight: 'bold' },
    },
  },
});

const StyledSummary = styled('div', {
  paddingLeft: 5,
  width: '100%',
});

const NewBadge = styled('span', {
  color: '#ff681f',
  fontWeight: 'bold',
});

const PathSummary = styled('span', {
  paddingRight: '16px',
});

const StyledBackButton = styled(StyledLink, {
  margin: '0px 0px 2px 12px',
  padding: '16px 12px 0px 0px',
});

export const SideNavLink = ({ service, data, currentTags, ...props }) => {
  const router = useRouter();
  const [initialOpen, setInitialOpen] = useState([]);

  useEffect(() => {
    const arr = [];
    data.tagNames.map((item, index) => arr.push(index));
    setInitialOpen(arr);
  }, [data]);
  const handleClick = () => {
    router.push(marketingDetailsRoutes[service]);
  };

  const fireInteractionEvent = path => {
    event('INTERACTION_EVENT', {
      interactionName: data?.info?.title,
      interactionValue: `${path?.method} ${path?.summary}`,
      interactionContext: 'left navigation link click',
    });
  };

  return (
    <Wrapper>
      <StyledBackButton
        onClick={handleClick}
        fontWeight="$bold"
        before={<IconMaterial icon="arrow_back" />}
      >
        Back to API Details
      </StyledBackButton>
      <Accordion variant="custom" initialOpen={initialOpen}>
        <For each="tag" of={data.pathsByTag}>
          <Accordion.Panel defaultOpen key={tag.tagName}>
            <Accordion.Header
              after={null}
              style={{ fontSize: '15px' }}
              disabled={currentTags.includes(tag.tagName)}
              before={({ isOpen }) => (
                <IconMaterial
                  icon={isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                />
              )}
            >
              <div style={{ paddingTop: '8px' }}>{tag.tagName} </div>
            </Accordion.Header>
            <Accordion.Content>
              <For each="path" of={tag.paths}>
                <StyledNavLink
                  {...props}
                  key={path.operationId}
                  to={`/documentation/${service}/${path.operationSlug}`}
                  replace
                  onClick={() => fireInteractionEvent(path)}
                >
                  <LinkText style={{ fontSize: '15px' }}>
                    <StyledMethod color={path.method}>
                      {path.method}
                    </StyledMethod>
                    {path.summary?.includes('||') ? (
                      <StyledSummary>
                        <PathSummary>{path.summary.split('||')[0]}</PathSummary>

                        <NewBadge>{path.summary.split('||')[1]}</NewBadge>
                      </StyledSummary>
                    ) : (
                      <StyledSummary>{path.summary}</StyledSummary>
                    )}
                  </LinkText>
                </StyledNavLink>
              </For>
            </Accordion.Content>
          </Accordion.Panel>
        </For>
      </Accordion>
    </Wrapper>
  );
};

SideNavLink.propTypes = {
  service: PropTypes.string.isRequired,
  data: PropTypes.shape({
    paths: PropTypes.shape({}),
    pathsByTag: PropTypes.arrayOf(PropTypes.shape({})),
    tagNames: PropTypes.arrayOf(PropTypes.string),
    info: PropTypes.shape({}),
  }),
  currentTags: PropTypes.arrayOf(PropTypes.string),
};

SideNavLink.defaultProps = {
  data: {},
  currentTags: [],
};

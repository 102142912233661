import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { useParams } from '@abyss/ui/router/hooks/useParams';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { global } from '@abyss/ui/tools/styled';
import { config } from '@abyss/ui/tools/config';
import { useDSUserInfo } from 'src/hooks/useDSUserInfo';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { saveAs } from 'file-saver';
import { styled } from '@abyss/web/tools/styled';
import { LeftNav } from '../LeftNav';
import { PageContent } from './PageContent';
import { OnThisPage } from './OnThisPage/OnThisPage';
import { leftNavMap, getLeftNavData } from '../../../../../common/Utils/utils';

const BorderLine = styled('div', {
  marginBottom: '20px',

  '@screen >= $lg': {
    borderBottomColor: '#5a5a5a',
    borderBottom: '1px solid',
  },
});

const ResponsivePageMargin = styled('div', {
  marginRight: '24px',
  marginLeft: '24px',

  '@screen >= $xl': {
    marginRight: '0px',
    marginLeft: '0px',
  },
});

const FlexStyle = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

const ResponsiveDivItemLeftNav = styled('div', {
  flex: '0 0 0',

  '@screen >= $lg': {
    flex: '0 0 20%',
  },
});

const LeftNavResponsive = styled('div', {
  '@screen < $lg': {
    display: 'none',
  },
});

const ResponsiveDivContent = styled('div', {
  flex: '0 0 80%',

  '@screen < $lg': {
    flex: '0 0 100%',
  },
});

const PageHeading = styled('h1', {
  color: '#002677',
  fontStyle: 'normal',
  fontWeight: '600 !important',
  fontSize: '36.48px !important',
  lineHeight: '48px',
  margin: 0,
  fontFamily: 'UHC Serif Headline',
});

const UpdatedDate = styled('p', {
  color: 'rgb(0, 38, 119)',
  fontSize: '13px',
  fontWeight: '400',
  minHeight: '20px',
  lineHeight: '20px',
  marginTop: '8px',
  display: 'flex',
});

const IconPadding = styled('div', {
  paddingRight: '10px',
});

const ResponsiveDiv = styled('div', {
  display: 'flex',

  '@screen >= $lg': {
    marginRight: '0px',
  },
});

const HeaderResponsiveDiv = styled('div', {
  display: 'block',

  '@screen >= $lg': {
    display: 'flex',
    marginRight: '0px',
  },
});

const ResponsiveDivItemLeft = styled('div', {
  flex: '0 0 100%',

  '@screen >= $lg': {
    flex: '0 0 80%',
  },
});

const ResponsiveDivItemRight = styled('div', {
  flex: '0 0 20%',

  '@screen < $lg': {
    flex: '0 0 0',
  },
});

const OnThisPageDisplay = styled('div', {
  '@screen < $lg': {
    display: 'none',
  },
});

const SelectInputStyles = global({
  '.abyss-dropdown-menu-trigger': {
    width: '100%',
  },
  '.abyss-dropdown-menu-item': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    color: ' #196ECF',
  },
  '.abyss-dropdown-menu-item-li-1': {
    borderBottom: '1px solid #DADBDC',
    marginBottom: '10px',
  },
});

export const LagoonKnowledgeBase = () => {
  const router = useRouter();
  const { resource } = useParams();
  const leftNavHeaders = useLagoon('knowledge-base-left-nav-headers');
  const leftNavHeaderItems = useLagoon('knowledge-base-header-items');
  const content = useLagoon('knowledge-base-content');
  const dataDictionaries = useLagoon('data-dictionaries');
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const userDSInfo = useSelector(state => state.GET_DS_USER_INFO);
  const userDSData = userDSInfo?.data;
  const [, getUserInfo] = useUserInfo();
  const [, getDSUserInfo] = useDSUserInfo();
  const [isLoading, setIsLoading] = useState(false);

  let leftNavContent = leftNavHeaders();
  const leftHeaderItems = leftNavHeaderItems();

  leftNavContent = leftNavMap(leftNavContent, leftHeaderItems);

  let knowledgeBaseData = {
    pathsByTag: leftNavContent,
  };

  const leftNavData = () => {
    const dataList = getLeftNavData(
      userDSData,
      leftNavContent,
      leftHeaderItems
    );
    knowledgeBaseData = {
      pathsByTag: dataList,
    };
    return knowledgeBaseData;
  };

  const getResource = () => {
    if (resource) {
      return resource;
    }
    return 'api-overview';
  };

  const gettingUserInfo = () => {
    getDSUserInfo({ userInfo });
  };

  const isUserSignedIn = userInfo?.firstName !== '';
  const callDsUserInfo = !userDSData?.uuid && !userDSData?.status;

  useEffect(() => {
    if (userInfo.firstName === '') {
      getUserInfo();
    }
    if (!resource) {
      router.push('/welcome/knowledge-base/api-overview');
    }
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');
      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';
      document.head.appendChild(script);
    }
    if (isUserSignedIn) {
      setIsLoading(true);
      if (callDsUserInfo) {
        gettingUserInfo();
      }
    }
  }, []);

  useEffect(() => {
    if (userDSData?.uuid) {
      setIsLoading(false);
    }
  }, [userDSData]);

  useEffect(() => {
    if (isUserSignedIn) {
      setIsLoading(true);
      if (callDsUserInfo) {
        gettingUserInfo();
      }
    }
  }, [userInfo]);

  const pageContentList = content();
  const filteredPageContent = pageContentList?.filter(
    el => el.id === getResource()
  );
  const pageContent = filteredPageContent?.length ? filteredPageContent[0] : '';

  const datadownload = (excelFile, fileName) => {
    saveAs(excelFile, fileName);
  };

  const getDataDictionaryList = () => {
    const dataDictionariesLagoonList = dataDictionaries();

    const resourceDataDictionaryList = dataDictionariesLagoonList?.filter(
      el => {
        return resource.includes(el.api.toLowerCase()) && el.active;
      }
    );

    const dataDictionaryList = [];
    if (resourceDataDictionaryList) {
      resourceDataDictionaryList.forEach(element => {
        const dataObject = {
          title: element.title,
          onClick: () => datadownload(element.fileUrl, element.fileName),
          icon: <IconMaterial icon="download" size="16px" />,
          isSeparated: element.isSeparated,
        };
        dataDictionaryList.push(dataObject);
      });
    }

    return dataDictionaryList;
  };

  return (
    <PageLayout>
      <ResponsivePageMargin>
        <FlexStyle>
          <ResponsiveDivItemLeftNav />
          <Breadcrumbs />
        </FlexStyle>
        <FlexStyle>
          <ResponsiveDivItemLeftNav>
            <LeftNavResponsive>
              <LeftNav>
                <LeftNav.Link
                  loading={isLoading && userDSInfo.loading}
                  data={leftNavData()}
                />
              </LeftNav>
            </LeftNavResponsive>
          </ResponsiveDivItemLeftNav>
          <ResponsiveDivContent>
            <HeaderResponsiveDiv>
              <ResponsiveDivItemLeft>
                <PageHeading>{pageContent?.pageHeading}</PageHeading>
                <UpdatedDate>
                  <IconPadding>
                    <IconMaterial icon="update" size="20px" color="$primary1" />
                  </IconPadding>
                  Last Updated {pageContent?.date}
                </UpdatedDate>
                <PageContent
                  content={pageContent?.pageDescription}
                  pageHeading={pageContent?.pageHeading}
                  displayDropDown={false}
                />
              </ResponsiveDivItemLeft>
              <ResponsiveDivItemRight>
                <If condition={pageContent?.displayDownloadDropdown}>
                  <SelectInputStyles />
                  <DropdownMenu
                    label={pageContent?.downloadDropdownLabel}
                    outline
                    before={<IconMaterial icon="download" size="20px" />}
                    after={<IconMaterial icon="keyboard_arrow_down" />}
                    menuItems={getDataDictionaryList()}
                  />
                </If>
              </ResponsiveDivItemRight>
            </HeaderResponsiveDiv>
            <BorderLine />
            <ResponsiveDiv>
              <ResponsiveDivItemLeft>
                <PageContent
                  content={pageContent?.pageContent}
                  displayDropDown
                />
              </ResponsiveDivItemLeft>
              <ResponsiveDivItemRight>
                <OnThisPageDisplay>
                  <OnThisPage />
                </OnThisPageDisplay>
              </ResponsiveDivItemRight>
            </ResponsiveDiv>
          </ResponsiveDivContent>
        </FlexStyle>
      </ResponsivePageMargin>
    </PageLayout>
  );
};

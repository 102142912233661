import React, { useState, useEffect } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useForm } from '@abyss/web/hooks/useForm';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@abyss/web/ui/Button';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { v4 as uuidv4 } from 'uuid';
import { Alert } from '@abyss/web/ui/Alert';
import { useUploadFile, resetUploadFile } from 'src/hooks/useUploadFile';
import {
  useDownloadFile,
  resetDownloadFileAPI,
} from 'src/hooks/useDownloadFile';
import { useDeleteFile, resetDeleteFiles } from 'src/hooks/useDeleteFile';
import { styled } from '@abyss/web/tools/styled';
import { CreateCommunicationFormFields } from './CreateCommunicationFormFields/CreateCommunicationFormFields';
import {
  useSaveDraftAsApi,
  resetSaveDraftAsApi,
} from '../../../../../../hooks/useSaveAsDraftApi';
import {
  useDeleteCommunicationAPI,
  resetDeleteCommunicationAPI,
} from '../../../../../../hooks/useDeleteCommunicationAPI';
import { resetDraftApi } from '../../../../../../hooks/useGetDraftApi';
import { useGetCommunicationAPI } from '../../../../../../hooks/useGetCommunicationAPI';
import {
  useSendCommunicationApi,
  resetSendCommunicationApi,
} from '../../../../../../hooks/useSendCommunicationApi';

const Space = styled('div', {
  paddingTop: '18px',
});

export const CreateCommunicationModal = ({
  showModal,
  modalOnClose,
  setConfirmationTitle,
  setShowConfirmationModal,
}) => {
  const [saveDraft, callSaveAsDraft] = useSaveDraftAsApi();
  const [sendCommunication, callSendCommunication] = useSendCommunicationApi();
  const [, getCommunicationAPI] = useGetCommunicationAPI();
  const [, resetFileDownload] = resetDownloadFileAPI();
  const [
    deleteCommunication,
    deleteCommunicationAPI,
  ] = useDeleteCommunicationAPI();
  const [deleteFiles, deleteUploadedFiles] = useDeleteFile();
  const [, resetDeleteFilesData] = resetDeleteFiles();
  const [, resetDeleteCommunication] = resetDeleteCommunicationAPI();
  const [s3File, downloadS3File] = useDownloadFile();
  const [, resetSaveAsDraft] = resetSaveDraftAsApi();
  const [, resetSendCommunication] = resetSendCommunicationApi();
  const [, resetDraftCommunication] = resetDraftApi();
  const [, resetUploadFiles] = resetUploadFile();
  const [commAction, setCommAction] = useState('');
  const [sendComm, setSendComm] = useState(false);
  // const [fileStatus, setFileStatus] = useState('');
  const communicationsList = useSelector(
    state => state.GET_COMMUNICATION_API.data
  );
  const [upload, uploadFile] = useUploadFile();
  const [fileUploadComplete, setFileUploadComplete] = useState(false);
  const [draftFiles, setDraftFiles] = useState([]);
  const [callSaveDraft, setCallSaveDraft] = useState(false);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const draftStore = useSelector(state => state.GET_DRAFT_API);
  const draftCommunication = draftStore.data;
  const form = useForm();
  const [serverErrorMessage, setServerErrorMessage] = useState({
    header: '',
    message: '',
  });
  const [displayError, setDisplayError] = useState(false);
  const [deleteFlowTriggered, setDeleteFlow] = useState(false);
  const dateFieldValue = new Date(draftCommunication?.date).setHours(
    0,
    0,
    0,
    0
  );
  const newDate = new Date();
  const [reuploadFiles, setReuploadFiles] = useState(false);
  const commSubject = form.watch('subject');
  const commDate = form.watch('date');
  const currentDate = new Date(newDate).setHours(0, 0, 0, 0);
  const fileFormLength = form.getValues('fileUpload');
  const errorMessages = useLagoon('error-messages')();
  const showConfirmationModal =
    !!(!saveDraft?.loading && !saveDraft?.error && saveDraft?.data?.message) ||
    !!(
      !sendCommunication?.loading &&
      !sendCommunication?.error &&
      sendCommunication?.data?.message
    );

  const onModalClose = () => {
    resetSaveAsDraft();
    resetSendCommunication();
    resetDraftCommunication();
    resetUploadFiles();
    resetDeleteFilesData();
    resetDeleteCommunication();
    setDisplayError(false);
    resetFileDownload();
    form.reset();
    setDraftFiles([]);
    modalOnClose(false);
    setDeleteFlow(false);
  };

  const getErrorMessage = messageKey => {
    const messageExist = errorMessages.find(
      message => message?.active && message?.key === messageKey
    );
    if (messageExist) {
      setDisplayError(true);
      const { header = '', message = '' } = messageExist || {};
      setServerErrorMessage({ header, message });
    }
  };

  useEffect(() => {
    if (
      (draftFiles.length > 0 || draftFiles.length === fileFormLength?.length) &&
      !reuploadFiles
    ) {
      setReuploadFiles(true);
    }
  }, [draftFiles]);

  useEffect(() => {
    if (
      !deleteCommunication.loading &&
      deleteCommunication.data.message &&
      !deleteCommunication?.error
    ) {
      getCommunicationAPI({ userInfo: { data: userInfo }, allMessages: true });
      const { fileIds = [] } = draftCommunication || {};
      if (fileIds?.length) {
        const fileKeyList = fileIds?.map(fileId => ({
          Key: `apimarketplace/${fileId}`,
        }));
        deleteUploadedFiles({ userInfo, fileKeyList });
      } else {
        onModalClose();
      }
    } else if (deleteCommunication?.error) {
      getErrorMessage('DELETE_DRAFT_FAILED');
    }
  }, [deleteCommunication]);

  useEffect(() => {
    if (s3File?.error) {
      setDisplayError(true);
      getErrorMessage('FILE_DOWNLOAD_FAILED');
    }
    if (
      s3File?.data?.length === draftCommunication?.fileIds?.length &&
      !s3File.containsFiles
    ) {
      const fileResponse = [];
      const mimeType = 'application/pdf';
      s3File.data.forEach(fileData => {
        fetch(`data:${mimeType};base64,${fileData.fileContent}`)
          .then(blobRes => blobRes.blob())
          .then(blobRes => {
            const { filename } = fileData;
            const file = new File([blobRes], filename, {
              type: mimeType,
            });

            fileResponse.push(file);

            form.setValue('fileUpload', fileResponse);
          });
      });
    }
  }, [s3File]);

  const confirmationModalClick = modalTitle => {
    setShowConfirmationModal(true);
    setConfirmationTitle(modalTitle);
    // if (
    //   !reuploadFiles ||
    //   (reuploadFiles &&
    //     (!draftCommunication.fileIds ||
    //       draftCommunication.fileIds.length === 0))
    // ) {
    onModalClose();
    // }
    form.reset();
  };

  const confModalTitle =
    commAction === 'draft'
      ? 'Your draft has been saved'
      : 'Your communication has been sent';

  useEffect(() => {
    if (!deleteFiles.loading && deleteFiles.data.message && reuploadFiles) {
      if (showConfirmationModal) {
        confirmationModalClick(confModalTitle);
      }
    } else if (
      !deleteFiles.loading &&
      deleteFiles.data.message &&
      deleteFlowTriggered
    ) {
      const deleteTitle = 'Your draft has been deleted';
      confirmationModalClick(deleteTitle);
    }
  }, [deleteFiles]);

  useEffect(() => {
    const files = form.getValues('fileUpload');
    if (
      !upload.loading &&
      files?.length &&
      files?.length === upload.data.length
    ) {
      setFileUploadComplete(true);
    }

    if (upload.error) {
      setDisplayError(true);
      getErrorMessage('FILE_UPLOAD_FAILED');
    }
  }, [upload]);

  useEffect(() => {
    if (fileUploadComplete) {
      if (commAction === 'draft') {
        setCallSaveDraft(true);
      } else {
        setSendComm(true);
      }
    }
  }, [fileUploadComplete]);

  useEffect(() => {
    if (callSaveDraft || sendComm) {
      setCallSaveDraft(false);
      setSendComm(false);
      const {
        isFilesAvailable = '',
        audience = '',
        date = '',
        subject = '',
        topic = '',
        actionByUnitedHealthCare = '',
        clientActions = '',
      } = form.getValues();

      let fileIds = [];
      let files;
      const values = {
        id: draftCommunication.id,
        action: commAction,
        communicationsDetails: {
          audience,
          date,
          subject,
          topicBody: topic,
          actionByUhgBody: actionByUnitedHealthCare,
          clientActionsBody: clientActions,
          fileUploadAvailable: isFilesAvailable === 'yes',
        },
      };

      if (isFilesAvailable === 'yes') {
        files = form.getValues('fileUpload');
        if (reuploadFiles) {
          fileIds = upload.data.map(fileDetails => {
            return fileDetails.s3FileKey;
          });
        } else {
          fileIds = draftCommunication.fileIds || [];
        }

        values.communicationsDetails.fileIds = fileIds;
      }

      if (commAction === 'draft') {
        callSaveAsDraft({
          variables: { body: values },
          userInfo,
        });
      } else {
        callSendCommunication({
          variables: { request: values, files },
          userInfo,
        });
      }
    }
  }, [callSaveDraft, sendComm]);

  const isConfModalShown = () =>
    showConfirmationModal &&
    (!reuploadFiles ||
      (reuploadFiles &&
        (!draftCommunication.fileIds ||
          draftCommunication.fileIds.length === 0)));

  useEffect(() => {
    // removing the files in the draft communication,they have re-aploaded in the previous uploadFile call
    const { fileIds: draftFileIds = [] } = draftCommunication || {};
    if (showConfirmationModal && reuploadFiles && draftFileIds.length) {
      const fileKeyList = draftFileIds?.map(fileId => ({
        Key: `apimarketplace/${fileId}`,
      }));
      deleteUploadedFiles({ fileKeyList });
    }

    const showConfModal = isConfModalShown();
    if (showConfModal) {
      confirmationModalClick(confModalTitle);
    }

    if (sendCommunication?.error || saveDraft?.error) {
      setDisplayError(true);
      if (commAction === 'draft') {
        getErrorMessage('SAVE_DRAFT_FAILED');
      } else {
        getErrorMessage('SEND_COMMUNICATION_FAILED');
      }
    }
  }, [saveDraft, sendCommunication]);

  useEffect(() => {
    if (draftCommunication && draftCommunication.id) {
      if (dateFieldValue === currentDate || dateFieldValue > currentDate) {
        form.setValue('date', draftCommunication.date);
      }
      form.setValue('audience', draftCommunication.audience);
      form.setValue('subject', draftCommunication.subject);
      form.setValue('topic', draftCommunication.topicBody);
      form.setValue(
        'actionByUnitedHealthCare',
        draftCommunication.actionByUhgBody
      );
      form.setValue('clientActions', draftCommunication.clientActionsBody);

      if (!draftCommunication.fileUploadAvailable === null) {
        form.setValue('isFilesAvailable', '');
      } else if (draftCommunication.fileUploadAvailable) {
        form.setValue('isFilesAvailable', 'yes');

        const fileIdsFromDraft = draftCommunication.fileIds;
        if (fileIdsFromDraft?.length > 0) {
          fileIdsFromDraft.forEach(fileId => {
            downloadS3File({ fileKey: fileId, userInfo });
          });
        }
      } else if (draftCommunication.fileUploadAvailable === false) {
        form.setValue('isFilesAvailable', 'no');
      }
    }
  }, [draftCommunication]);

  const readFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result.split(',')[1];
        resolve(fileContent);
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const onSave = () => {
    if (commSubject && commDate) {
      const { fileUpload = [], isFilesAvailable = '' } = form.getValues();
      if (isFilesAvailable === 'yes' && fileUpload.length && reuploadFiles) {
        fileUpload.forEach(file => {
          const fileContent = readFile(file);
          return fileContent.then(content => {
            const key = `${uuidv4()}_${file.name}`;
            const request = {
              fileContent: content,
              fileName: file.name,
              contentType: file.type,
              fileKey: key,
            };
            uploadFile({ request, userInfo });
          });
        });
      } else if (commAction === 'draft') {
        setCallSaveDraft(true);
      } else {
        setSendComm(true);
      }
    } else {
      form.trigger(['subject', 'date']);
    }
  };

  const onSubmit = () => {
    resetUploadFiles();
    setFileUploadComplete(false);
    setDisplayError(false);
    setCommAction('send');
    onSave();
  };

  const deleteDraft = () => {
    const { id = '' } = draftCommunication || {};
    if (id) {
      deleteCommunicationAPI({ userInfo, ids: [id] });
    } else {
      onModalClose();
    }
  };

  return (
    <Modal
      model="closeOnClickOutside-modal"
      closeOnClickOutside={false}
      title="Create communication"
      isOpen={showModal}
      onClose={onModalClose}
      css={{
        'abyss-modal-header-container': {
          color: '#002677',
          fontSize: '28.83px',
        },
        'abyss-modal-content-title': { 'font-size': '26px !important' },
      }}
    >
      <Modal.Section>
        <p style={{ fontSize: '15px' }} id="modalDescription">
          Please fill out all the fields as needed to create the communication.
          Select the designated audience the email is being sent out to, as well
          as upload any attachments that need to be sent out with the
          communication to the clients. The communication should not include any
          PHI/PII Information and is limited to a 2.5 MB limit per attachment.
          <div>
            The communication can be saved as a draft to be sent out on a future
            date, as long as the date is marked as the current date for the
            communication to be sent out. Limit 5 drafts.
          </div>
        </p>
        <FormProvider state={form} onSubmit={onSubmit}>
          <CreateCommunicationFormFields setDraftFiles={setDraftFiles} />
          {displayError ? (
            <Alert title={serverErrorMessage?.header}>
              {serverErrorMessage?.message}
            </Alert>
          ) : null}
          <Space />
          <Layout.Group alignLayout="right">
            <Button
              id="deleteDraftButton"
              type="button"
              variant="destructive"
              fontSize="15px"
              onClick={() => {
                deleteDraft();
                setDeleteFlow(true);
              }}
              isLoading={
                (deleteFlowTriggered &&
                  deleteCommunication.loading &&
                  !(callSaveDraft || sendComm)) ||
                (deleteFlowTriggered && deleteFiles.loading)
              }
              ariaLoadingLabel="Deleting communication"
            >
              Delete
            </Button>
            <Button
              id="saveDraftButton"
              type="button"
              variant="outline"
              fontSize="15px"
              onClick={() => {
                resetUploadFiles();
                setFileUploadComplete(false);
                setDisplayError(false);
                setCommAction('draft');
                onSave();
              }}
              isLoading={
                commAction === 'draft' &&
                (upload.loading ||
                  saveDraft.loading ||
                  deleteCommunication.loading ||
                  deleteFiles.loading)
              }
              ariaLoadingLabel="Saving communication"
              disabled={
                !draftCommunication?.id &&
                communicationsList?.draftMessages?.length > 4
              }
            >
              Save as draft
            </Button>
            <Button
              id="sentCommunicationButton"
              type="submit"
              fontSize="15px"
              isDisabled={
                new Date(commDate).setHours(0, 0, 0, 0) !== currentDate
              }
              isLoading={
                commAction === 'send' &&
                (upload.loading ||
                  sendCommunication.loading ||
                  deleteCommunication.loading ||
                  deleteFiles.loading)
              }
              ariaLoadingLabel="Sending communication"
            >
              Send
            </Button>
          </Layout.Group>
        </FormProvider>
      </Modal.Section>
    </Modal>
  );
};

CreateCommunicationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  modalOnClose: PropTypes.func.isRequired,
  setConfirmationTitle: PropTypes.func.isRequired,
  setShowConfirmationModal: PropTypes.func.isRequired,
};

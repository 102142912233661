import React from 'react';
import PropTypes from 'prop-types';
import { RequiredIcon } from '../RequiredIcon';

export const CellKey = ({ cell, value }) => {
  const {
    row: { original },
  } = cell;

  if (original?.required) {
    return (
      <div style={{ display: 'flex' }}>
        <b>{value}</b>
        <RequiredIcon />
      </div>
    );
  }
  return value;
};

CellKey.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        required: PropTypes.bool,
      }),
    }),
  }).isRequired,
  value: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { CodeHighlighter } from '@abyss/web/ui/CodeHighlighter';

const PRISM_LANGUAGES = {
  csharp: 'csharp',
  curl: 'bash',
  dart: 'javascript',
  go: 'go',
  http: 'http',
  java: 'java',
  javascript: 'javascript',
  c: 'c',
  nodejs: 'javascript',
  'objective-c': 'objectivec',
  ocaml: 'ocaml',
  php: 'java',
  powershell: 'bash',
  python: 'python',
  ruby: 'javascript',
  shell: 'bash',
  swift: 'javascript',
};

export const LanguageHighlighter = ({ code, selectedLanguage }) => {
  return (
    <CodeHighlighter
      language={PRISM_LANGUAGES[selectedLanguage.value]}
      code={code}
      showLineNumbers
    />
  );
};

LanguageHighlighter.propTypes = {
  code: PropTypes.string.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

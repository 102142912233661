import React from 'react';
import { AppContext } from 'src/app/App/AppProvider';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { Checkbox } from '@abyss/web/ui/Checkbox';

const Wrapper = styled('div', {
  padding: '16px 16px 16px 80px',
  '@screen >= $xs': {
    paddingLeft: '16px',
  },
  '@screen >= $xl': {
    paddingLeft: '80px',
  },
});

const Title = styled('h4', {
  minHeight: '20px !important',
  color: 'rgb(0, 38, 119) !important',
  fontSize: '16px !important',
  fontWeight: 'bold !important',
  lineHeight: '20px !important',
  margin: '20px 0 !important',
});

const SubTitle = styled('h5', {
  minHeight: '18px !important',
  color: 'rgb(51, 51, 51) !important',
  fontSize: '16px !important',
  fontWeight: 'bold !important',
  letterSpacing: '0px !important',
  lineHeight: '18px !important',
  margin: '10px 0 !important',
});

const StyledHr = styled('hr', {
  margin: '15px 0',
});

export function Filters() {
  const featureToggles = useLagoon('feature-toggles');
  const providerDemoToggle = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;
  const priorAuthToggle = featureToggles('PRIOR_AUTH_TOGGLE')?.enabled;

  const { filters = [], updateFilters } = React.useContext(AppContext);
  let filtersData = providerDemoToggle
    ? filters
    : filters.filter(item => item.title !== 'Provider Demographic');
  filtersData = priorAuthToggle
    ? filtersData
    : filtersData.filter(item => item.title !== 'Prior Authorizations');

  const filtersInteractionEvent = idx => {
    const interactionName = filtersData?.[idx].checked
      ? 'category select'
      : 'category deselect';
    const interactionValue = `Category: ${filtersData?.[idx]?.title}`;
    const interactionContext = 'filter clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  const onChange = (e, idx) => {
    filtersData[idx].checked = !filtersData[idx].checked;
    updateFilters([...filtersData]);
    filtersInteractionEvent(idx);
  };

  return (
    <Wrapper>
      <SubTitle>Filters</SubTitle>
      <hr />
      <Title>Category</Title>
      {filtersData.map((filter, idx) => (
        <Checkbox
          label={filter.title}
          isChecked={filter.checked}
          onChange={e => onChange(e, idx)}
        />
      ))}
      <StyledHr />
    </Wrapper>
  );
}

import { ACTION_TYPE } from './useUserStatus';

const initialState = {
  data: {},
  loading: false,
  error: false,
};

export const reducerUserStatus = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_CALLED`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${ACTION_TYPE}_COMPLETED`:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case `${ACTION_TYPE}_STAGE_UPDATE`:
        return {
          ...state,
          data: {
            ...state.data,
            credentials: {
              ...state.data.credentials,
              stage: {
                ...state.data.credentials.stage,
                isSecretGenerated: true,
              },
            },
          },
          loading: false,
          error: false,
        };
      case `${ACTION_TYPE}_PROD_UPDATE`:
        return {
          ...state,
          data: {
            ...state.data,
            credentials: {
              ...state.data.credentials,
              prod: {
                ...state.data.credentials.prod,
                isSecretGenerated: true,
              },
            },
          },
          loading: false,
          error: false,
        };
      case `${ACTION_TYPE}_ERROR`:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: true,
        };
      default:
        return state;
    }
  },
};

import eligibility from '../../../../../assets/eligibility.png';
import referrals from '../../../../../assets/referrals.png';
import priorAuthorizations from '../../../../../assets/prior-authorizations.png';
import { constants } from '../../../../../common/Utils/Constants';

export const cards = [
  {
    id: 1,
    src: `${eligibility}`,
    title: 'Eligibility (Light Weight)',
    description:
      'Basic eligibility and member information by member demographics.',
    link: '/all-apis/eligibility-lightweight-api',
    linkLabel: constants.VIEW_API_DETAILS,
  },
  {
    id: 1,
    src: `${priorAuthorizations}`,
    title: 'Prior Auth',
    description: 'Search, review, submit and update prior authorizations.',
    link: '/all-apis/prior-auth-api',
    linkLabel: constants.VIEW_API_DETAILS,
  },
  {
    id: 1,
    src: `${referrals}`,
    title: 'Referrals',
    description: 'Review referral status and submissions by providerID/MPN.',
    link: '/all-apis/referral-graphQL-api',
    linkLabel: constants.VIEW_API_DETAILS,
  },
];

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@abyss/web/tools/styled';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { PageLayout } from 'src/common/PageLayout';
import { Button } from '@abyss/web/ui/Button';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { useFetchTinApi } from '../../../../../../hooks/useFetchTinApi';
import { usePatchTinApi } from '../../../../../../hooks/usePatchTinApi';
import { AttestAndSubmitModal } from '../AttestAndSubmitModal/AttestAndSubmitModal';
import { SuccessModal } from '../SuccessModal/SuccessModal';
import { FailureModal } from '../FailureModal/FailureModal';
import { useDSUserInfo } from '../../../../../../hooks/useDSUserInfo';

const Wrapper = styled('div', {
  '.abyss-alert-root': {
    marginBottom: '20px',
  },

  '@screen >= $xs': {
    padding: '44px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const PageHeading = styled('h1', {
  color: '#002677',
  fontSize: '41.04px',
  fontWeight: '600',
  lineHeight: '52px',
  marginBottom: '32px',
  '@screen < $sm': {
    fontSize: '25px',
  },
});

const DeleteButton = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '32px',
  height: '48px',
});

const ContainerBreadcrumb = styled('div', {
  marginTop: '61px',
});

export const ReviewAndRemoveTins = () => {
  const [tinAPI, fetchTinAPI] = useFetchTinApi();
  const [patchTinManagementAPI] = usePatchTinApi();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const [, getUserInfo] = useUserInfo();
  const [, getDSUserInfo] = useDSUserInfo();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitAndAttestModalOpen, setIsSubmitAndAttestModalOpen] = useState(
    false
  );
  const history = useRouter();
  const [isLoadingOverlayOpen, setIsLoadingOverlayOpen] = useState(false);

  const initialTins = tinAPI?.data?.tins || [];
  const [updatedTins, setUpdatedTins] = useState(initialTins);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const text = 'modifying';

  useEffect(() => {
    storage.local.set('IS_SIGN_IN_CLICKED', false);
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }
    if (!userInfo?.firstName) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.firstName) {
      fetchTinAPI({ userInfo });
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.firstName && !dsUserInfo?.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.firstName && !dsUserInfo.isAdmin) {
      history.goBack();
    }
  }, [dsUserInfo]);

  useEffect(() => {
    const tinApiStatus = patchTinManagementAPI?.data?.status;
    if (tinApiStatus && tinApiStatus === 200) {
      setIsLoadingOverlayOpen(false);
      setIsSuccessModalOpen(true);
    } else if (
      patchTinManagementAPI?.error ||
      (tinApiStatus && tinApiStatus !== 200)
    ) {
      setIsLoadingOverlayOpen(false);
      setIsFailureModalOpen(true);
    }
  }, [patchTinManagementAPI]);

  const title = () => {
    return (
      <div style={{ display: 'inline-flex' }}>
        <div>
          <IconBrand icon="folder" size={32} />
        </div>
        <div style={{ alignSelf: 'center', marginLeft: '15px' }}>
          Review TINs
        </div>
      </div>
    );
  };

  const createData =
    updatedTins?.map((tin, ind) => ({
      key: ind,
      col1: tin,
    })) || [];

  const columns = [
    {
      Header: 'TIN',
      accessor: 'tin',
    },
  ];

  const data = useMemo(() => [...createData], [tinAPI]);

  const dataTableProps = useDataTable({
    initialData: data,
    initialColumns: columns,
    showSelection: true,
    showPagination: true,
    showDownloadButton: true,
    showBottomPagination: false,
    showGlobalFilter: true,
    noDataMessage: 'No TINs found for this organization',
  });

  useEffect(() => {
    setIsLoadingOverlayOpen(tinAPI?.loading);

    setUpdatedTins(tinAPI?.data?.tins || []);
    const updateData =
      tinAPI?.data?.tins?.map((tin, ind) => ({
        key: ind,
        tin,
      })) || [];
    dataTableProps.setData(updateData, true);
  }, [tinAPI]);

  const selectedTins = dataTableProps?.state?.selectedRowIds;

  useEffect(() => {
    if (Object.keys(selectedTins || {}).length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedTins]);

  const deleteSelectedTins = () => {
    const newTins = updatedTins?.filter((tin, idx) => selectedTins[idx]);
    setUpdatedTins(newTins);
    setIsSubmitAndAttestModalOpen(true);
  };

  const loadingMessage = () => {
    let description = 'while we apply your changes';
    if (tinAPI?.loading) {
      description = 'while we access your organization TINs.';
    }
    return description;
  };

  return (
    <LoadingOverlay
      loadingTitle="Please Wait"
      loadingMessage={loadingMessage()}
      ariaLoadingLabel="Example loading aria label"
      isLoading={isLoadingOverlayOpen}
      isOpen={isLoadingOverlayOpen}
      width="384px"
    >
      <AttestAndSubmitModal
        isDeleteFlow
        isOpen={isSubmitAndAttestModalOpen}
        onClosed={setIsSubmitAndAttestModalOpen}
        isLoadingOverlayOpen={setIsLoadingOverlayOpen}
        tins={updatedTins}
        text={text}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        isClosed={setIsSuccessModalOpen}
      />
      <FailureModal
        isOpen={isFailureModalOpen}
        isClosed={setIsFailureModalOpen}
      />
      <PageLayout>
        <Wrapper>
          <SuccessModal
            isOpen={isSuccessModalOpen}
            isClosed={setIsSuccessModalOpen}
          />
          <FailureModal
            isOpen={isFailureModalOpen}
            isClosed={setIsFailureModalOpen}
          />
          <ContainerBreadcrumb>
            <Breadcrumbs />
          </ContainerBreadcrumb>
          <PageHeading>Review or Remove TINs</PageHeading>
          <DataTable
            title={title()}
            tableState={dataTableProps}
            css={{
              '.abyss-table-cell': {
                paddingLeft: '10px !important',
                paddingRight: '10px !important',
              },
            }}
          />
          <DeleteButton>
            <Button
              onClick={deleteSelectedTins}
              isDisabled={isDisabled}
              after={
                <IconMaterial
                  icon="delete_outline"
                  size={17}
                  color={isDisabled ? '$gray5' : '$white'}
                />
              }
            >
              Delete Selected TINs
            </Button>
          </DeleteButton>
        </Wrapper>
      </PageLayout>
    </LoadingOverlay>
  );
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/ui/tools/classNames';
import { Enhancer } from './Enhancer';
import { AccordionContext } from '../AccordionContext';

import { baseStyles } from './AccordionHeader.styles';
import { AccordionIcon } from './AccordionIcon';

export const AccordionHeader = ({
  children,
  className,
  styles,
  before,
  deactivateOnclick,
  after,
  onClick,
  ...props
}) => {
  const { panelId, isOpen, size, variant, onAccordionClick } = useContext(
    AccordionContext
  );
  const styleProps = { isOpen, size, variant };
  const classes = useStyles(baseStyles, { ...styles, ...styleProps });

  const handleClick = e => {
    onAccordionClick();

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Choose>
      <When condition={deactivateOnclick === false}>
        <button
          {...props}
          type="button"
          id={`accordion-panel-header-${panelId}`}
          aria-controls={`accordion-panel-content-${panelId}`}
          aria-expanded={isOpen}
          className={classNames(classes.header, className)}
          onClick={deactivateOnclick ? '' : handleClick}
        >
          {before && (
            <Enhancer position="before" styles={styles}>
              {typeof before === 'function' ? before(styleProps) : before}
            </Enhancer>
          )}
          <div className={classes.title}>
            {typeof children === 'function' ? children(styleProps) : children}
          </div>
          {after && (
            <Enhancer position="after" styles={styles}>
              {typeof after === 'function' ? after(styleProps) : after}
            </Enhancer>
          )}
        </button>
      </When>
      <Otherwise>
        <div
          {...props}
          role="button"
          tabIndex="0"
          id={`accordion-panel-header-${panelId}`}
          aria-controls={`accordion-panel-content-${panelId}`}
          aria-expanded={isOpen}
          className={classNames(classes.header, className)}
          onKeyDown={deactivateOnclick ? '' : handleClick}
          onClick={deactivateOnclick ? '' : handleClick}
        >
          {before && (
            <Enhancer position="before" styles={styles}>
              {typeof before === 'function' ? before(styleProps) : before}
            </Enhancer>
          )}
          <div className={classes.title}>
            {typeof children === 'function' ? children(styleProps) : children}
          </div>
        </div>
      </Otherwise>
    </Choose>
  );
};

AccordionHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  before: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  after: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
  deactivateOnclick: PropTypes.bool,
};

AccordionHeader.defaultProps = {
  className: null,
  styles: null,
  before: null,
  after: AccordionIcon,
  onClick: null,
  deactivateOnclick: false,
};

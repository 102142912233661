import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';

const ErrorMessage = styled('p', {
  fontSize: '13px',
  color: 'rgb(208, 2, 28)',
  lineHeight: '13px',
  paddingTop: ' 5px',
});

export const ErrorComponent = ({ children }) => {
  return <ErrorMessage>{children}</ErrorMessage>;
};

ErrorComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/ui/tools/classNames';

import { baseStyles } from './Accordion.styles';
import { AccordionPanel } from './AccordionPanel';

const getChildrenActiveItems = children => {
  const activeItems = [];

  []
    .concat(children)
    .filter(c => c)
    .forEach((child, index) => {
      const { isDisabled, isOpen, defaultOpen } = child.props;
      if (!isDisabled && (isOpen || defaultOpen)) {
        activeItems.push(index);
      }
    });

  return activeItems;
};

const getActiveItems = (children, allowMultiple) => {
  let activeItems = getChildrenActiveItems(children);

  if (!allowMultiple && activeItems.length > 0) {
    activeItems = activeItems.slice(0, 1);
  }

  return activeItems;
};

export const Accordion = ({
  children,
  allowMultiple,
  className,
  styles,
  onChange,
  openNextAccordionItem,
  size,
  variant,
  initialOpen,
  ...props
}) => {
  const classes = useStyles(baseStyles, styles);
  const [accordionItems, setAccordionItems] = useState([]);
  const [activeItems, setActiveItems] = useState([]);

  const handleChange = useCallback(index => {
    setActiveItems(prevItems => {
      let newActiveItems = prevItems.slice(0);
      const position = newActiveItems.indexOf(index);

      if (position !== -1) {
        newActiveItems.splice(position, 1);

        if (openNextAccordionItem && index !== children.length - 1) {
          newActiveItems.push(index + 1);
        }
      } else if (allowMultiple) {
        newActiveItems.push(index);
      } else {
        newActiveItems = [index];
      }

      if (onchange) {
        onChange(newActiveItems);
      }

      return newActiveItems;
    });
  }, []);

  useEffect(() => {
    setAccordionItems(children);
    setActiveItems(getActiveItems(children, allowMultiple));
  }, [accordionItems]);

  useEffect(() => {
    setActiveItems(initialOpen);
  }, [initialOpen]);

  return (
    <div {...props} className={classNames(classes.accordion, className)}>
      {[].concat(children).reduce((acc, child, index) => {
        if (child.type !== AccordionPanel) {
          return child;
        }

        const {
          props: { id, isDisabled },
        } = child;

        const panelId = String(id || index);
        const isPanelOpen = !isDisabled && activeItems.indexOf(index) !== -1;

        const element = React.cloneElement(child, {
          key: panelId,
          id: panelId,
          index,
          size,
          variant,
          isPanelOpen,
          onClick: () => handleChange(index),
        });
        acc.push(element);

        return acc;
      }, [])}
    </div>
  );
};

Accordion.propTypes = {
  allowMultiple: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  size: PropTypes.string,
  onChange: PropTypes.func,
  openNextAccordionItem: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'custom']),
  initialOpen: PropTypes.arrayOf(PropTypes.number),
};

Accordion.defaultProps = {
  children: null,
  className: null,
  styles: null,
  allowMultiple: true,
  size: 'size700',
  variant: 'default',
  onChange: null,
  openNextAccordionItem: null,
  initialOpen: [],
};

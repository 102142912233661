import React from 'react';
import { Card } from '@abyss/web/ui/Card';
import { styled } from '@abyss/web/tools/styled';

const CardContent = styled('div', {
  marginBottom: '25px',
  marginTop: '-10px',
  fontSize: '15px',
  fontWeight: '400',
});

export const AllCommunicationPageDesc = () => {
  return (
    <Card
      id="allCommunicationsCornerCard"
      header="Communications Corner"
      css={{
        'abyss-card-title': {
          backgroundColor: '#0000',
          color: '#002677',
          fontSize: '20px',
          marginTop: '10px',
        },
      }}
    >
      <Card.Section>
        <CardContent>
          Welcome to Communications Corner. Here you will be able to see all the
          communications that have been sent out to your organization regarding
          any updates for all APIs that are on the API Marketplace. We encourage
          you to view them as needed. These will be available for you to view
          for 6 months after the communication has been sent out.
        </CardContent>
      </Card.Section>
    </Card>
  );
};

export const getDSUserAdobeData = (dsUserInfo = {}, userInfo = {}) => {
  let apiSubscription;
  let apiRegistrationRequest;
  let stageLinkedApis;
  let prodLinkedApis;

  if (dsUserInfo?.uuid !== '') {
    switch (dsUserInfo?.status) {
      case 'ACTIVE':
        apiSubscription = 'yes';
        apiRegistrationRequest = 'yes';
        stageLinkedApis = dsUserInfo?.linkedApis?.stage.join('|');
        prodLinkedApis = dsUserInfo?.linkedApis?.prod.join('|');
        break;

      case 'PENDING':
        apiSubscription = 'no';
        apiRegistrationRequest = 'In Progress';
        break;

      case 'INACTIVE':
        apiSubscription = 'no';
        apiRegistrationRequest = 'Inactive';
        break;

      case 'DENIED':
        apiSubscription = 'no';
        apiRegistrationRequest = 'Denied';
        break;

      case 'notprovisioned':
        apiSubscription = 'no';
        apiRegistrationRequest = 'no';
        break;

      default:
        apiSubscription = '';
        apiRegistrationRequest = '';
        break;
    }
  }

  const parsedData = {
    providerUuid: userInfo?.uuid || '',
    apiSubscription: apiSubscription || '',
    apiSubscribedProductStage: stageLinkedApis || '',
    apiSubscribedProductProd: prodLinkedApis || '',
    apiRegistrationRequest: apiRegistrationRequest || '',
  };
  return parsedData;
};

import kebabCase from 'lodash/kebabCase';

const SEARCH_OPTIONS = [
  {
    searchType: 'MemberIDDateOfBirth',
    path: 'byIdDob',
    summary: 'by Member ID Date of Birth',
    filterOut: ['firstName', 'lastName', 'groupNumber'],
    description:
      'SearchOption (use value MemberID, DateOfBirth), ProviderLastName, NPI, PayerId, MemberID and DateOfBirth are the required request parameters.',
  },
  {
    searchType: 'MemberIDLastNameDateOfBirth',
    path: 'byIdLastDob',
    summary: 'by Member ID Last Name Date of Birth',
    filterOut: ['firstName', 'groupNumber'],
    description:
      'searchOption (use value MemberIDLastNameDateOfBirth), ProviderLastName, NPI, PayerId, MemberID, LastName and DateOfBirth are the required request parameters.',
  },
  {
    searchType: 'MemberIDFirstNameDateOfBirth',
    path: 'byIdFirstDob',
    summary: 'by Member ID First Name Date of Birth',
    filterOut: ['lastName', 'groupNumber'],
    description:
      'searchOption(use value MemberIDFirstNameDateOfBirth), ProviderLastName, NPI, PayerId, MemberID, FirstName and DateOfBirth are the required request parameters.',
  },
  {
    searchType: 'MemberIDName',
    path: 'byIdName',
    summary: 'by Member ID Name',
    filterOut: ['groupNumber', 'dateOfBirth'],
    description:
      'searchOption (use value MemberIDName ), ProviderLastName, NPI, PayerId, MemberID, FirstName and LastName are the required request parameters.',
  },
  {
    searchType: 'MemberIDNameDateOfBirth',
    path: 'byIdNameDob',
    summary: 'by Member ID Name Date of Birth',
    filterOut: ['groupNumber'],
    description:
      'searchOption (use value MemberIDNameDateOfBirth), ProviderLastName, NPI, PayerId, DateOfBirth, MemberID, FirstName and LastName are the required request parameters.',
  },
  {
    searchType: 'MemberIDNameGroupNumberDateOfBirth',
    path: 'byIdNameGroupDob',
    summary: 'by Member ID Name Group Date of Birth',
    filterOut: [],
    description:
      'searchOption (use value MemberIDNameGroupNumberDateOfBirth), ProviderLastName, NPI, PayerId, GroupNumber, DateOfBirth, MemberID, FirstName and LastName are the required request parameters.',
  },
  {
    searchType: 'NameDateOfBirth',
    path: 'byNameDob',
    summary: 'by Name Date of Birth',
    filterOut: ['memberId', 'groupNumber'],
    description:
      'searchOption (use value NameDateOfBirth), ProviderLastName, NPI, PayerId, DateOfBirth,FirstName and LastName are the required request parameters.',
  },
];

const expandMemberSearch = (option, pathItem) => {
  const parsePathItem = JSON.parse(JSON.stringify(pathItem));
  const operationId = `${parsePathItem.operationId}-${option.path}`;

  parsePathItem.summary = `${parsePathItem.summary} ${option.summary}`;
  parsePathItem.operationId = operationId;
  parsePathItem.operationSlug = kebabCase(operationId);
  parsePathItem.description = `${parsePathItem.description} ${option.description}`;

  const filteredParameters = parsePathItem.parameters.filter(
    param => !option.filterOut.includes(param.name)
  );
  parsePathItem.parameters = filteredParameters;
  const searchOptionIndex = parsePathItem.parameters.findIndex(
    param => param.name === 'searchOption'
  );
  if (searchOptionIndex > -1) {
    if (!parsePathItem.parameters[searchOptionIndex].enum) {
      parsePathItem.parameters[searchOptionIndex].enum = [];
    }
    parsePathItem.parameters[searchOptionIndex].enum[0] = option.searchType;
  }

  return parsePathItem;
};

export const parseEligibility = data => {
  const parsedData = [];
  const pathDataDeepCopy = JSON.parse(JSON.stringify(data));

  SEARCH_OPTIONS.forEach(option => {
    const expandedData = expandMemberSearch(option, pathDataDeepCopy);
    parsedData.push(expandedData);
  });

  return parsedData;
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { event } from '@abyss/web/tools/event';
import { styled } from '@abyss/web/tools/styled';
import { GettingStarted } from './GettingStarted';
import { APIsCategory } from './APIsCategory';
import { NewestAPIs } from './NewestAPIs';
import { MostPopularApis } from './MostPopularApis';
import { AppContext } from '../../AppProvider';

const WelcomeHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px ! important',
  fontWeight: 600,
  lineHeight: '40px',
  marginTop: '64px',
  marginBottom: '16px',
});

const TopInstruction = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',

  '@screen >= $md': {
    width: '624px',
  },
});

const Wrapper = styled('div', {
  paddingLeft: '0px',

  '@screen >= $xs': {
    paddingLeft: '16px',
    paddingRight: '16px',
  },

  '@screen >= $xl': {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export const Landing = () => {
  const { filters = [], updateFilters } = React.useContext(AppContext);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);

  useEffect(() => {
    updateFilters([...filters].map(filter => ({ ...filter, checked: false })));
    event('LANDING_PAGE_LOAD', { dsUserInfo, userInfo });
  }, []);

  return (
    <PageLayout>
      <Wrapper>
        <WelcomeHeading>Better data, seamless integration </WelcomeHeading>
        <TopInstruction>
          Find, integrate and manage your UnitedHealthcare APIs all in one
          place. Save time and money with fewer interruptions by automating data
          into existing workflows.
        </TopInstruction>
        <GettingStarted />
        <APIsCategory />
        <NewestAPIs />
        <MostPopularApis />
      </Wrapper>
    </PageLayout>
  );
};

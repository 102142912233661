import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { StepIndicator } from '@abyss/web/ui/StepIndicator';
import { Label } from '@abyss/web/ui/Label';

const Container = styled('div', {
  paddingBottom: '32px',
  paddingTop: '32px',
  background: 'rgb(255, 255, 255)',
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
});

const TrackerHeader = styled('div', {
  textAlign: 'center',
  paddingTop: '1%',
  paddingBottom: '2%',
});

export const OrganizationTracker = ({ currentStep }) => {
  return (
    <Container>
      <TrackerHeader>
        <Label id="tracker-label" size="lg">
          API Subscription Request Status
        </Label>
      </TrackerHeader>
      <StepIndicator currentStep={currentStep}>
        <StepIndicator.Step
          id="step-request-submitted"
          label="Request Submitted"
        />
        <StepIndicator.Step id="step-review" label="Reviewing Request" />
        <StepIndicator.Step id="step-approved" label="API Request Approved" />
      </StepIndicator>
    </Container>
  );
};

OrganizationTracker.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

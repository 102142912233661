export const baseStyles = theme => {
  return {
    panel: ({ variant }) => {
      if (variant === 'default') {
        return {
          borderBottom: `1px solid #333333`,
          paddingBottom: '18px',
          '&:last-child': {
            borderBottom: 0,
          },
        };
      }
      return {};
    },
  };
};

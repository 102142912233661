export const baseStyles = theme => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      zIndex: 1,
      padding: 2,
      // padding: ({ variant }) => {
      //   return {
      //     default: '18px 0',
      //   }[variant];
      // },
    },
    title: {
      flex: '1 1 0%',
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: ({ size }) => size,
    },
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { event } from '@abyss/web/tools/event';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { saveAs } from 'file-saver';

const NavItem = styled('div', {
  padding: '28px 0px 0px 0px',
});

const StyledNavLink = styled('div', {
  display: 'block',
  color: '#196ECF',
  textDecoration: 'none',
  marginBottom: '0',
});
const Heading = styled('div', {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: '#424242',
  textTransform: 'uppercase',
});

const LinkText = styled('a', {
  display: 'flex',
  padding: '4px 0px 4px 0px',
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '0px',
  textAlign: 'left',
  width: 'fit-content',

  '&:hover': {
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

const LinkTextBold = styled('p', {
  display: 'flex',
  padding: '4px 0px 4px 0px',
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '0px',
  textAlign: 'left',
  width: 'fit-content',
  marginBottom: 0,
  fontWeight: 'bold',
});

const BottomMargin = styled('div', {
  marginBottom: '16px',
});

const DownloadDiv = styled('div', {
  paddingRight: '10px',
});

export const LeftNavLink = ({ data, loading, ...props }) => {
  const router = useRouter();
  const linkClick = path => {
    const interactionName = path.name.includes('Release Notes')
      ? 'Release Notes'
      : 'Knowledge Base';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue: path.name,
      interactionContext: 'left navigation link click',
    });
    if (path.id.includes('download')) {
      saveAs(path.path);
    } else {
      router.push(path.path);
      props.handleSideNav(path.path);
    }
  };
  return (
    <NavItem>
      <Choose>
        <When condition={loading}>
          <LoadingSpinner isLoading={loading} />
        </When>
        <Otherwise>
          <If condition={data.pathsByTag !== undefined}>
            <For each="tag" of={data.pathsByTag}>
              <If condition={tag.accessibleToAll}>
                <Heading>{tag.tag}</Heading>
                <BottomMargin>
                  <For each="path" of={tag.paths}>
                    <StyledNavLink>
                      <Choose>
                        <When
                          condition={window.location.href.includes(path.id)}
                        >
                          <LinkTextBold>{path.name}</LinkTextBold>
                        </When>
                        <Otherwise>
                          <LinkText onClick={() => linkClick(path)}>
                            <DownloadDiv>{path.name}</DownloadDiv>

                            {path.id.includes('download') && (
                              <DownloadDiv>
                                <IconMaterial
                                  icon="download"
                                  size="16px"
                                  css={{
                                    'abyss-icon-material': {
                                      verticalAlign: 'middle',
                                    },
                                  }}
                                />
                              </DownloadDiv>
                            )}
                          </LinkText>
                        </Otherwise>
                      </Choose>
                    </StyledNavLink>
                  </For>
                </BottomMargin>
              </If>
            </For>
          </If>
        </Otherwise>
      </Choose>
    </NavItem>
  );
};

LeftNavLink.propTypes = {
  data: PropTypes.shape({
    pathsByTag: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  loading: PropTypes.bool.isRequired,
  handleSideNav: PropTypes.func.isRequired,
};

LeftNavLink.defaultProps = {
  data: {},
};

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from '@abyss/ui/router/NavLink';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { styled } from '@abyss/web/tools/styled';
import ArrowRight from '../../assets/Arrow-Right.svg';

const Wrapper = styled('div', {
  background: 'rgb(255, 255, 255)',
  border: '1px solid rgb(219, 219, 219)',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '4px',
  margin: '24px 0px 24px 0',
  minHeight: '266px',
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',

  '@screen >= $xl': {
    width: '408px',
    minHeight: '312px',
  },
});

const IconBox = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
});

const Title = styled('h3', {
  color: 'rgb(0, 38, 119)',
  fontSize: '24px ! important',
  fontWeight: '600',
  lineHeight: '24px',
  margin: '16px 0 16px 0',
  minHeight: '24px',
});

const Description = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  letterSpacing: '0px',
  lineHeight: '24px',
  margin: '0',
  wordBreak: 'break-word',
  minHeight: '48px',
  marginBottom: '26px',
});

const DescBox = styled('div', {
  minHeight: '48px',
  height: 'auto',
});

const LinkBox = styled('div', {
  color: 'rgb(25, 110, 207)',
  fontSize: '15px',
  fontWeight: 'bold',
  letterSpacing: '0px',
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  margin: '16px 0',
  marginTop: 'auto',
  marginBottom: '12px',
});

const ImgPadding = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
});

const LabelPadding = styled('p', {
  paddingRight: '8px',
  paddingTop: '16px',
});

export const APICardWithIcon = ({
  iconName,
  title,
  description,
  link,
  linkLabel,
  onClick,
  index,
}) => {
  return (
    <Wrapper style={index === 2 ? { marginRight: '0px' } : {}}>
      <IconBox>
        <IconBrand id={`api-${title}-icon`} size="40px" icon={iconName} />
      </IconBox>
      <Title id={`api-${title}`}>{title}</Title>
      <DescBox>
        <Description id={`api-${title}-desc`}>{description}</Description>
      </DescBox>
      <LinkBox>
        <NavLink to={link} id={`api-${title}-link`} onClick={onClick}>
          <ImgPadding>
            <LabelPadding>{linkLabel}</LabelPadding>
            <img
              id={`api-${title}-link-img`}
              src={ArrowRight}
              alt="link icon"
            />
          </ImgPadding>
        </NavLink>
      </LinkBox>
    </Wrapper>
  );
};

APICardWithIcon.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
};

APICardWithIcon.defaultProps = {
  iconName: '',
  title: '',
  description: '',
  link: '',
  linkLabel: '',
  onClick: () => {},
};

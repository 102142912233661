import React, { useState, useEffect } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { event } from '@abyss/web/tools/event';
import { useRegenerateSecret } from 'src/hooks/useRegenerateSecret';
import { useSelector } from 'react-redux';

const ModalButtons = styled('div', {
  display: 'inline-flex',
  gap: '20px',
});

const ModalText = styled('p', {
  fontSize: '15px',
  color: 'rgb(0, 38, 119)',
});

const RegenerateRequiredError = styled('p', {
  color: 'red',
  fontSize: '15px',
});

export const RegenerateSecretModal = ({
  isOpen,
  closeModal,
  clientId,
  regenerateEnv,
  isProd,
}) => {
  const [displayRegenerateError, setDisplayRegenerateError] = useState(false);
  const [regeneratedSecret, getRegeneratedSecret] = useRegenerateSecret();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const [isLoading, setIsLoading] = useState(false);
  const modalName = 'change API secret key modal pop up';
  const jobName = 'regenerate secret key';
  const regenerateSecret = () => {
    const values = { clientId, isProd, isSecretGenerated: true };
    getRegeneratedSecret({
      variables: { values, regenerateEnv, isSecretGenerated: true },
      userInfo,
    });
  };
  const closeRegenModal = () => {
    setDisplayRegenerateError(false);
    closeModal();
    event('MODAL_CLOSE', {
      event,
      modalName,
    });
    event('JOB_CANCEL', {
      event,
      jobName,
    });
  };
  useEffect(() => {
    setIsLoading(regeneratedSecret.loading);
    if (!regeneratedSecret.loading) {
      if (regeneratedSecret.error) {
        setDisplayRegenerateError(true);
      } else {
        closeModal();
      }
    }
  }, [regeneratedSecret]);

  return (
    <Modal
      size="lg"
      title="You are about to change the API secret."
      isOpen={isOpen}
      onClose={isLoading ? '' : closeRegenModal}
      className="abyss-modal-content-title"
      css={{
        'abyss-modal-content-title': {
          color: 'rgb(0, 38, 119)',
        },
      }}
    >
      <Modal.Section>
        <ModalText>
          This change cannot be undone. You will have to let your users know
          that the secret's been changed. Your old secret will expire
          immediately. All API transactions will fail unless the new secret is
          used. You will not be able to regenerate a new secret until 15 minutes
          after you have generated your old one.
        </ModalText>
        <If condition={displayRegenerateError}>
          <RegenerateRequiredError>
            The secret regeneration was unsuccessful, please try again.
          </RegenerateRequiredError>
        </If>
        <ModalButtons>
          <Button
            variant="outline"
            onClick={closeRegenModal}
            disabled={isLoading}
          >
            Close
          </Button>
          <Button
            isLoading={isLoading}
            ariaLoadingLabel="Regenerating Secret"
            onClick={regenerateSecret}
          >
            Regenerate Secret
          </Button>
        </ModalButtons>
      </Modal.Section>
    </Modal>
  );
};

RegenerateSecretModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  regenerateEnv: PropTypes.string.isRequired,
  isProd: PropTypes.bool.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';

const Wrapper = styled.div({
  padding: '16px 32px 32px 0px',
});

const TableHead = styled('p', {
  color: 'rgb(0, 38, 119)',
  marginBottom: '0',
});

const StyledUL = styled('ul', {
  listStyleType: 'disc',
  marginLeft: '12px',
});

const StyledLI = styled('li', {
  fontWeight: 'normal',
});

const Note = styled('p', {
  marginBottom: 0,
});

const DescWithNote = styled('div', {});

export const ErrorCodes = ({ currentApi }) => {
  const [responses, setResponses] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: <TableHead>Code</TableHead>,
        accessor: 'code',
        disableResizing: true,
      },
      {
        Header: <TableHead>Description</TableHead>,
        accessor: 'description',
      },
    ],
    []
  );

  const dataTableProps = useDataTable({
    showPagination: false,
    initialData: responses,
    initialColumns: columns,
  });

  const updateTable = newData => {
    dataTableProps.setData(newData, true);
  };

  const createHtmlData = string => {
    if (string.includes('||')) {
      const splitted = string.split('||');
      let note = null;
      const dat = splitted.map(el => {
        if (el.includes('Note:')) {
          note = el;
          return null;
        }
        return <StyledLI>{el}</StyledLI>;
      });
      if (note) {
        return (
          <DescWithNote>
            <StyledUL>{dat}</StyledUL>
            <Note>{note}</Note>
          </DescWithNote>
        );
      }
      return <StyledUL>{dat}</StyledUL>;
    }
    return string;
  };

  useEffect(() => {
    if (currentApi.responses.length) {
      const data = [...currentApi.responses].map(el => {
        return { code: el.status, description: createHtmlData(el.description) };
      });
      updateTable(data);
      setResponses(data);
    }
  }, [currentApi]);

  return (
    <Wrapper>
      <DataTable tableState={dataTableProps} />
    </Wrapper>
  );
};

ErrorCodes.propTypes = {
  currentApi: PropTypes.shape({
    description: PropTypes.string,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        description: PropTypes.string,
      })
    ),
  }),
};

ErrorCodes.defaultProps = {
  currentApi: { description: '', responses: [] },
};

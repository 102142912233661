import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_CREDENTIALS';

export const useUserStatus = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const eapm = window.elasticApm;
    if (eapm) {
      window.elasticApm.startTransaction(ACTION_TYPE, 'http-request', {
        managed: true,
      });
    }
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      const orgIdHeader = args.orgId;
      let addHeaders = {};
      if (orgIdHeader) {
        addHeaders = {
          orgId: orgIdHeader,
        };
      }
      fetch(`${config('AWS_PROXY_URL')}${config('CREDENTIALS_URL')}`, {
        credentials: 'include',
        headers: { ...addHeaders },
      })
        .then(res => {
          event('API_TRANSACTION', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('CREDENTIALS_URL'),
              userInfo: args?.userInfo,
              method: 'GET',
              code: res.status,
              operationName: ACTION_TYPE,
              message: res.statusText,
            },
          });

          event('NEWRELIC_API_TRANSACTION', {
            code: res.status,
            message: res.statusText,
            endpoint: config('CREDENTIALS_URL'),
            userInfo: args?.userInfo,
            method: 'GET',
          });

          if (res.status !== 200) {
            throw new Error('Get credentials call failed');
          }
          return res.json();
        })
        .then(res => {
          if (!res.credentials) {
            throw new Error('Incomplete Get credentials data returned');
          }
          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: res,
          });
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          event('API_TRANSACTION_ERROR', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('CREDENTIALS_URL'),
              userInfo: args?.userInfo,
              method: 'GET',
              operationName: ACTION_TYPE,
            },
          });
        });
    };
  });

  return [result, action];
};

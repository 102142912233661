import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { useCssProps } from '@abyss/web/hooks/useCssProps';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Button } from '@abyss/web/ui/Button';
import { CardContext } from '../CardContext';

const CardHeaderStyle = styled('div', {
  static: {
    color: '$gray4',
    paddingLeft: '$md',
    paddingRight: '$md',
    lineHeight: '17px',
    fontWeight: '$bold',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    variants: {
      size: {
        small: {
          minHeight: 52,
          fontSize: '$sm',
        },
        medium: {
          minHeight: 68,
          fontSize: '$lg',
        },
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  },

  dynamic: ({ cssProps }) => {
    let color = '$gray3';
    if (cssProps.isNested) {
      color = '$gray2';
    }
    if (cssProps?.backgroundColor) {
      color = cssProps.backgroundColor;
    }

    return {
      color: cssProps.color,
      backgroundColor: color,
    };
  },
});

const ButtonContainer = styled('div', { marginLeft: '$sm' });

export const CardHeader = ({ backgroundColor, color, children, ...props }) => {
  const abyssProps = useAbyssProps(props);
  const { size, collapse, buttonProps, isOpen, isNested } = useContext(
    CardContext
  );
  const cssProps = useCssProps({ color, backgroundColor, isNested });

  return (
    <CardHeaderStyle
      {...props}
      {...abyssProps('card-title')}
      size={size}
      collapse={collapse}
      cssProps={cssProps}
    >
      {children}
      {collapse && (
        <ButtonContainer>
          <Button rounded size="$sm" {...buttonProps} variant="ghost">
            <IconMaterial
              icon={isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
            />
          </Button>
        </ButtonContainer>
      )}
    </CardHeaderStyle>
  );
};

CardHeader.displayName = '@abyss/web/ui/Card.Title';

CardHeader.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

CardHeader.defaultProps = {
  children: null,
  color: '$gray8',
  backgroundColor: null,
};

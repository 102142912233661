export const convertHttp = ({ url, method, headers, body }) => {
  const codeArr = [];

  const splitUrl = url.split('//')[1];
  const [host, ...pathParts] = splitUrl.split('/');
  const path = `/${pathParts.join('/')}`;

  codeArr.push(`${method} ${path} HTTP/1.1`);
  codeArr.push(`Host: ${host}`);

  headers.forEach(header => {
    codeArr.push(`${header.key}: ${header.value}`);
  });

  if (body) {
    const bodyString = JSON.stringify(body, null, 2);
    codeArr.push(`Content-Length: ${bodyString.length}`);
    codeArr.push(`\n${bodyString}`);
  }

  return codeArr.join('\n');
};

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';

const StyledDiv = styled('div', {
  backgroundColor: '#FFFFFF',
  border: ({ theme }) => `2px solid ${theme.colors.gray200}`,
  marginRight: '10',
  height: '100%',
});

export const SideNav = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};

SideNav.propTypes = {
  children: PropTypes.node.isRequired,
};

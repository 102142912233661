import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  width: '100%',
  background: '#ffffff',
  border: '1px solid #000000',
  boxSizing: 'border-box',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
});

const StyledBoxHeading = styled('p', {
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '23px !important',
  lineHeight: '32px',
  color: '#002677',
  margin: '0',
  paddingTop: '8px',
});

const InpContainer = styled('div', {
  width: '100%',
  paddingTop: '24px',
  fontSize: '15px ! important',
  '.label': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px ! important',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '0',
  },
});

const BtnWrapper = styled('div', {
  width: '100%',
  alignItems: 'bottom',
  display: 'flex',
  paddingTop: '24px',
  flexGap: '26px',
  flexWrap: 'Wrap',
  marginTop: 'auto',
});

export const CardComponentWrapper = ({ title, section, button }) => {
  return (
    <Wrapper>
      <StyledBoxHeading id={`${title}`}>{title}</StyledBoxHeading>
      <InpContainer>{section}</InpContainer>
      <BtnWrapper>{button}</BtnWrapper>
    </Wrapper>
  );
};

CardComponentWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

export const CellValue = ({ cell, value, onChange }) => {
  const {
    row: { original },
  } = cell;

  if (original?.in === 'formData' && original?.type === 'file') {
    return (
      <input
        type="file"
        onChange={event => {
          onChange(event.target.files[0], original);
        }}
      />
    );
  }

  return value;
};

CellValue.propTypes = {
  cell: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        in: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CellValue.defaultProps = {
  value: '',
};

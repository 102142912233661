import React from 'react';
import { event } from '@abyss/web/tools/event';
import { Link } from '@abyss/web/ui/Link';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { config } from '@abyss/ui/tools/config';

const Wrapper = styled('div', {
  display: 'flex',
  marginBottom: '44px',
  marginTop: '69px',
  '@screen >= $xs': {
    display: 'block',
  },
  '@screen >= $xl': {
    display: 'flex',
  },
});

const AnchorBox = styled('div', {
  height: '20px',
  display: 'flex',
  marginBottom: '36px',
});

const LinkWrap = styled('div', {
  display: 'flex',
  fontWeight: 'bold',
  fontSize: '15px !important',
});

const FirstContainer = styled('div', {
  minHeight: '257px',
  marginBottom: '20px',
  flexBasis: '52%',
});

const FirstContainerTitle = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px ! important',
  fontWeight: '600',
  lineHeight: '40px',
  '@screen >= $xs': {
    marginBottom: '16px',
  },
  '@screen >= $xl': {
    marginBottom: '0px',
  },
});

const FirstContainerDescription = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '16px',
  fontWeight: '400',
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '28px',
});

const SecondContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  flexBasis: '48%',
  '@screen >= $xs': {
    height: 'auto',
    paddingLeft: 0,
  },
  '@screen >= $xl': {
    paddingLeft: '76px',
    height: '277px',
  },
});

const SecondContainerTitle = styled('h1', {
  height: '32px',
  color: 'rgb(0, 38, 119)',
  fontSize: '28px ! important',
  fontWeight: 600,
  lineHeight: '32px',
});

const SecondContainerDescription = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  fontWeight: '400',
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '-10px',
  marginTop: '15px',
});

export const IntroSection = () => {
  return (
    <Wrapper>
      <FirstContainer>
        <FirstContainerTitle>
          Application Programming Interface (API)
        </FirstContainerTitle>
        <FirstContainerDescription>
          A new way to automate your data feeds. API is designed to help your
          organization improve efficiency, reduce costs and increase cash flow.
        </FirstContainerDescription>
        <AnchorBox>
          <Link
            href={config('SELF_PACED_USER_GUIDE')}
            hideIcon
            onClick={() => {
              const interactionName = `api self paced user guide`;
              const interactionValue = 'view our self paced guide';
              const interactionContext = '';
              const fileName = 'Our Self Placed User Guide';
              const fileURL = config('SELF_PACED_USER_GUIDE');

              const fileContext = 'pdf guide';

              event('DOWNLOAD_CLICK', {
                fileName,
                fileURL,
                fileContext,
              });

              event('INTERACTION_EVENT', {
                interactionName,
                interactionValue,
                interactionContext,
              });
            }}
            after={<IconMaterial icon="arrow_forward" size="20px" />}
          >
            <LinkWrap>View Our Self-Paced User Guide</LinkWrap>
          </Link>
        </AnchorBox>
        <AnchorBox>
          <Link
            href={config('API_OVERVIEW')}
            hideIcon
            onClick={() => {
              const interactionName = `api overview quick review guide`;
              const interactionValue = 'api overview';
              const interactionContext = 'pdf guide';
              const fileName = 'API Overview';
              const fileURL = config('API_OVERVIEW');
              const fileContext = 'API Overview';
              event('INTERACTION_EVENT', {
                interactionName,
                interactionValue,
                interactionContext,
              });
              event('DOWNLOAD_CLICK', {
                fileName,
                fileURL,
                fileContext,
              });
            }}
            after={<IconMaterial icon="arrow_forward" size="20px" />}
          >
            <LinkWrap>API Overview</LinkWrap>
          </Link>
        </AnchorBox>
      </FirstContainer>
      <SecondContainer>
        <SecondContainerTitle>New to APIs?</SecondContainerTitle>
        <SecondContainerDescription>
          API is becoming the newest digital method in health care to distribute
          information to health care professionals and external organizations in
          a timely and effective manner. It’s not a UnitedHealthcare-only term
          or software. API is a common programming interface that interacts
          between multiple applications. Data is in real-time and can be
          programmed to be pulled repetitively to automate data feeds.
        </SecondContainerDescription>
      </SecondContainer>
    </Wrapper>
  );
};

export const elasticApmTags = {
  API_TRANSACTION: data => {
    let uuid = data?.fields?.userInfo?.uuid;
    let username = data?.fields?.userInfo?.username;
    if (process.env.NODE_ENV === 'development') {
      uuid = data?.fields?.userInfo?.sub;
      // eslint-disable-next-line camelcase
      username = data?.fields?.userInfo?.preferred_username;
    }
    return {
      event: 'api-transaction',
      data: {
        result:
          data.fields.code && data.fields.code === 200 ? 'success' : 'error',
        appVersion: process.env.PACKAGE_VERSION,
        url: window.location.href,
        uuid,
        ipAddress: data.fields?.userInfo?.ipAddress || '',
        optumId: username,
        username,
        status: data.fields.code,
        endpoint: data.fields.endpoint,
        method: data.fields.method,
        operationName: data.fields.operationName,
        message: data.fields.message,
      },
    };
  },
  API_TRANSACTION_ERROR: data => {
    let uuid = data?.fields?.userInfo?.uuid;
    let username = data?.fields?.userInfo?.username;
    if (process.env.NODE_ENV === 'development') {
      uuid = data?.fields?.userInfo?.sub;
      // eslint-disable-next-line camelcase
      username = data?.fields?.userInfo?.preferred_username;
    }
    return {
      event: 'api-transaction',
      data: {
        result: 'js error',
        appVersion: process.env.PACKAGE_VERSION,
        url: window.location.href,
        uuid,
        ipAddress: data.fields?.userInfo?.ipAddress || '',
        optumId: username,
        username,
        status: data.fields.code,
        endpoint: data.fields.endpoint,
        method: data.fields.method,
        operationName: data.fields.operationName,
        message: data.fields.message,
      },
    };
  },
};

import { getDSUserAdobeData } from '../../utils/utils';

const getPageData = data => {
  return {
    pageName: '',
    siteSectionL1: '',
    siteSectionL2: '',
    siteSectionL3: '',
    siteSectionL4: '',
    siteSectionL5: '',
    website: 'api marketplace',
    businessUnit: 'uhc',
    referringSite: document.referrer,
    loginStatus: 'not loggedin',
    language: 'english',
  };
};

const getNewPageData = ({ userInfo = {} }) => {
  return {
    pageName: '',
    siteSectionL1: '',
    siteSectionL2: '',
    siteSectionL3: '',
    siteSectionL4: '',
    siteSectionL5: '',
    website: 'api marketplace',
    businessUnit: 'uhc',
    referringSite: document?.referrer,
    loginStatus: userInfo?.uuid ? 'loggedin' : 'not loggedin',
    language: 'english',
  };
};

export const adobeTags = {
  LANDING_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: 'apimarketplace:home',
      },
    };
  },
  GETTING_STARTED_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: 'apimarketplace:getting started',
        siteSectionL1: 'getting started',
      },
    };
  },
  DOCUMENTATION_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:documentation:${data.service}:${data.slug}`,
        siteSectionL1: 'documentation',
        siteSectionL2: data.service,
        siteSectionL3: data.slug,
      },
    };
  },
  ALL_API_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: 'apimarketplace:all api',
        siteSectionL1: 'all api',
      },
    };
  },
  PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getPageData(data),
        pageName: data.pageName,
        siteSectionL1: data.siteSectionL1,
        siteSectionL2: data.siteSectionL2 || '',
        siteSectionL3: data.siteSectionL3 || '',
      },
    };
  },
  INTERACTION_EVENT: data => {
    return {
      event: 'interaction',
      data: {
        interactionName: data.interactionName,
        interactionValue: data.interactionValue,
        interactionContext: data.interactionContext,
      },
    };
  },
  API_DETAILS_ALLCLAIMS_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:all claims-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'all claims api',
        siteSectionL3: '',
      },
    };
  },
  API_DETAILS_ELIGIBILITY_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:eligibility-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'eligibility api',
        siteSectionL3: '',
      },
    };
  },
  API_DETAILS_REFERRALGRAPQLAPI_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:referral-graphql-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'referral graphql api',
        siteSectionL3: '',
      },
    };
  },
  API_DETAILS_CLAIMSTATUSX12API_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:claimstatusx12-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'claim status X12 api',
        siteSectionL3: '',
      },
    };
  },
  API_DETAILS_ELIGIBILITYX12API_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:eligibilityandbenefits-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'eligibility and benefits extended x12 api',
        siteSectionL3: '',
      },
    };
  },
  API_DETAILS_ELIGIBILITYLIGHTWEIGHT_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:eligibilitylightweight-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'eligibility lightweight api',
        siteSectionL3: '',
      },
    };
  },
  API_DETAILS_PRIORAUTHAPI_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:prior-auth-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'prior auth api',
        siteSectionL3: '',
      },
    };
  },
  API_DETAILS_PROVIDERDEMOGRAPHIC_PAGE_LOAD: data => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: `apimarketplace:all api:provider-demographic-api`,
        siteSectionL1: 'all api',
        siteSectionL2: 'provider-demographic-api',
        siteSectionL3: '',
      },
    };
  },
  DOWNLOAD_CLICK: data => {
    return {
      event: 'download click',
      data: {
        fileName: data.fileName,
        fileURL: data.fileURL,
        fileContext: data.fileContext,
      },
    };
  },

  EXIT_EVENT: data => {
    return {
      event: 'exit click',
      data: {
        exitLink: data.exitLink, // ex: "go to provider express.com"
        exitURL: data.exitURL,
      },
    };
  },
  SUBSCRIBE_EVENT: data => {
    return {
      event: 'jobStart',
      data: {
        jobName: data.jobName,
      },
    };
  },

  JOB_START: data => {
    return {
      event: 'jobStart',
      data: {
        jobName: data.jobName,
      },
    };
  },
  JOB_SUBSCRIPTION_COMPLETE: data => {
    return {
      event: 'jobSubscriptionComplete',
      data: {
        jobName: data.jobName,
        emailAddress: data.emailAddress,
        orgName: data.orgName,
      },
    };
  },
  PRINT: () => {
    return {
      event: 'print',
    };
  },
  MODAL_CLOSE: data => {
    return {
      event: 'modal close',
      data: {
        modalName: data.modalName,
      },
    };
  },
  MODAL_VIEW: data => {
    return {
      event: 'modal view',
      data: {
        modalName: data.modalName,
      },
    };
  },
  GENERATE_SECRET_FAILURE: data => {
    return {
      event: 'jobGenerateSecretKeyFailure',
      data: {
        jobName: data.jobName,
        apiFieldName: data.apiFieldName,
        environment: data.environment,
      },
    };
  },
  GENERATE_SECRET_COMPLETE: data => {
    return {
      event: 'jobGenerateSecretKeyComplete',
      data: {
        jobName: data.jobName,
        apiFieldName: data.apiFieldName,
        environment: data.environment,
      },
    };
  },
  JOB_COMPLETE: data => {
    return {
      event: 'jobComplete',
      data: {
        jobName: data.jobName,
      },
    };
  },
  JOB_REQUEST_API_SUBSCRIPTION_SUBMIT: data => {
    return {
      event: 'jobRequestApiSubscriptionSubmit',
      data: {
        jobName: data.jobName,
        requestedAPI: data.requestedAPI,
        organizationName: data.organizationName,
        organizationType: data.organizationType,
      },
    };
  },
  JOB_CANCEL: data => {
    return {
      event: 'jobCancel',
      data: {
        jobName: data.jobName,
      },
    };
  },
  SITE_ERROR: data => {
    return {
      event: 'siteError',
      data: {
        errorMessage: data.errorMessage,
        errorApiCode: data.errorApiCode,
        errorHttpsCode: data.errorHttpsCode,
        errorType: data.errorType,
      },
    };
  },
  REGENERATE_SECRET_FAILURE: data => {
    return {
      event: 'jobReGenerateSecretKeyFailure',
      data: {
        jobName: data.jobName,
        apiFieldName: data.apiFieldName,
        environment: data.environment,
      },
    };
  },
  REGENERATE_SECRET_COMPLETE: data => {
    return {
      event: 'jobReGenerateSecretKeyComplete',
      data: {
        jobName: data.jobName,
        apiFieldName: data.apiFieldName,
        environment: data.environment,
      },
    };
  },

  NEW_PAGE_LOAD: (data = {}) => {
    return {
      event: 'pageload',
      data: {
        ...getNewPageData(data),
        ...getDSUserAdobeData(data.dsUserInfo, data.userInfo),
        pageName: data.pageName,
        siteSectionL1: data.siteSectionL1,
        siteSectionL2: data.siteSectionL2 || '',
        siteSectionL3: data.siteSectionL3 || '',
      },
    };
  },
  FORM_ERROR: data => {
    return {
      event: 'formError',
      data: {
        errorMessage: data.errorMessage,
        errorFields: data.errorFields,
        errorType: data.errorType,
      },
    };
  },
};

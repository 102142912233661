import React from 'react';
import { Card } from '@abyss/web/ui/Card';
import { styled } from '@abyss/web/tools/styled';

const CardContent = styled('div', {
  marginBottom: '25px',
  marginTop: '-10px',
  fontSize: '15px',
  fontWeight: '400',
});

export const CreateCommunicationPageDesc = () => {
  return (
    <Card
      id="communicationsCornerCard"
      header="Communications Corner"
      css={{
        'abyss-card-title': {
          backgroundColor: '#0000',
          color: '#002677',
          fontSize: '19px',
          marginTop: '10px',
        },
      }}
    >
      <Card.Section>
        <CardContent>
          Welcome to the Communications Corner. This is reserved to be used by
          API Marketplace Onboarding team only. You can draft out the
          communications or send them out to designated clients. All
          communications and drafts will be saved here for future use up to 6
          months. Drafts are limited to 5 only.
        </CardContent>
      </Card.Section>
    </Card>
  );
};

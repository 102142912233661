import React, { useEffect, useState } from 'react';
import { useParams } from '@abyss/ui/router/hooks/useParams';
import { Link } from '@abyss/web/ui/Link';
import { styled } from '@abyss/web/tools/styled';

const Wrapper = styled('div', {
  paddingLeft: '24px',
  hyphens: 'auto',
});

const Heading = styled('div', {
  color: '#5a5a5a',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16px',
  textAlign: 'left',
  textTransform: 'uppercase',
  margin: '8px 0px',
});

const StyledLink = styled(Link, {
  textAlign: 'left',
  marginBottom: '8px',
  display: 'block',
  fontSize: '15px ! important',
  fontWeight: '400 ! important',
});

export const OnThisPage = () => {
  const [headings, setHeadings] = useState([]);
  const { resource } = useParams();

  const getHeadings = () => {
    const elements = Array.from(document.querySelectorAll('h2'))
      .filter(element => element.id)
      .map(element => ({
        id: element.id,
        text: element.textContent ?? '',
      }));
    setHeadings(elements);
  };

  const getSubheaderView = id => {
    const element = document.getElementById(id);
    element.scrollIntoView();
  };

  useEffect(() => {
    getHeadings();
  }, []);

  useEffect(() => {
    getHeadings();
  }, [resource]);

  return (
    <Wrapper>
      <Heading>ON THIS PAGE</Heading>
      <For each="topic" of={headings}>
        <StyledLink
          key={topic.id}
          size="$md"
          onClick={() => getSubheaderView(topic.id)}
        >
          {topic.text}
        </StyledLink>
        <br />
      </For>
    </Wrapper>
  );
};

import fromPairs from 'lodash/fromPairs';
import kebabCase from 'lodash/kebabCase';
import orderBy from 'lodash/orderBy';
import { parseEligibility } from './parseEligibility';

const parsePathData = (data, path, method) => {
  const pathData = data.paths[path][method];
  const responses = Object.keys(pathData.responses).map(status => {
    return {
      ...pathData.responses[status],
      status,
    };
  });
  const isDisabled = /disabled/i.test(pathData.operationId);

  return {
    ...pathData,
    method: method.toUpperCase(),
    operationSlug: kebabCase(pathData.operationId),
    path,
    responses,
    isDisabled,
  };
};

export const parseByTag = (data, service) => {
  const tags = {};

  if (data.tags) {
    data.tags.forEach(tag => {
      tags[tag.name] = {
        tagName: tag.name.replace(/-/g, ' '),
        paths: [],
      };
    });
  }

  Object.keys(data.paths).forEach(path => {
    const pathItem = data.paths[path];
    Object.keys(pathItem).forEach(method => {
      const pathData = parsePathData(data, path, method);
      const pathTag = pathData.tags ? pathData.tags[0] : 'APIs';

      const isHidden = /hidden/i.test(pathData.operationId);
      if (isHidden) {
        return;
      }

      if (tags[pathTag]) {
        tags[pathTag].paths.push(pathData);
      } else {
        tags[pathTag] = {
          tagName: pathTag.replace(/-/g, ' '),
          paths: [pathData],
        };
      }

      const isExpandEligibility =
        /eligibility/i.test(service) &&
        pathData.path === '/api/appservices/eligibility/v2.0' &&
        /get/i.test(method);
      if (isExpandEligibility) {
        const expandedPaths = parseEligibility(pathData);
        tags[pathTag].paths = expandedPaths;
      }
    });
  });

  // Filter tags with no paths
  const filteredResult = Object.entries(tags).filter(
    ([key, value]) => value.paths.length !== 0
  );

  const returnValue = Object.values(fromPairs(filteredResult));

  return orderBy(returnValue, 'tagName');
};

export const parseTagNames = pathsByTag => {
  const tagNames = pathsByTag.map(obj => obj.tagName);
  return tagNames;
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { LanguageHighlighter } from './LanguageHighlighter';
import { LanguageSelect } from './LanguageSelect';
import { propsToCode } from './propsToCode';

const Wrapper = styled('div', {
  padding: '20px',
});

const HeadingContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '$md',
});

const Title = styled('p', {
  fontSize: '$xl',
  fontWeight: '$bold',
  marginBottom: 0,
  marginRight: '$md',
  whiteSpace: 'nowrap',
});

export const CodeSnippet = ({
  languages,
  url,
  method,
  query,
  headers,
  body,
  formData,
}) => {
  const [selectedSnippet, setSelectedSnippet] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('curl');

  useEffect(() => {
    const conversion = propsToCode({
      language: selectedLanguage,
      url,
      method,
      query,
      headers,
      body,
      formData,
    });
    setSelectedSnippet(conversion);
  }, [selectedLanguage]);

  return (
    <Wrapper>
      <HeadingContainer>
        <Title>Code Snippets</Title>
        <LanguageSelect
          languages={languages}
          setSelectedLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
        />
      </HeadingContainer>
      <LanguageHighlighter
        code={selectedSnippet}
        selectedLanguage={selectedLanguage}
      />
    </Wrapper>
  );
};

CodeSnippet.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  query: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  body: PropTypes.shape({}),
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

CodeSnippet.defaultProps = {
  languages: [
    'curl',
    'http',
    'java',
    'javascript',
    'nodejs',
    'python',
    'csharp',
    'php',
  ],
  method: null,
  query: [],
  headers: [],
  body: null,
  formData: [],
};

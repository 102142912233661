import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { RequiredIcon } from './RequiredIcon';
import { CellKey } from './CellKey';
import { CellValue } from './CellValue';

export const ParameterTable = ({ parameters, title, setValue }) => {
  const handleChange = (value, row) => {
    const model = `${row.in}.${row.key}`;
    setValue(model, value);
  };

  const columns = React.useMemo(() => {
    const colArray = [
      {
        Header: 'Key',
        accessor: 'key',
        Cell: props => {
          return <CellKey {...props} />;
        },
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: props => {
          return <CellValue {...props} onChange={handleChange} />;
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ];

    return colArray;
  }, []);

  const dataTableProps = useDataTable({
    initialData: parameters,
    initialColumns: columns,
    showPagination: false,
    disableResizing: true,
  });

  useEffect(() => {
    dataTableProps.setData(parameters);
  }, [parameters]);

  return (
    <React.Fragment>
      <Layout.Group alignItems="top" space={4}>
        <RequiredIcon />
        <Text size="$sm">Required</Text>
      </Layout.Group>
      <DataTable
        title={title}
        css={{
          '.abyss-table-cell': {
            fontSize: '13px',
            b: {
              fontWeight: 400,
            },
          },
          'abyss-table-header-data': {
            fontSize: '13px',
          },
        }}
        tableState={dataTableProps}
      />
    </React.Fragment>
  );
};

ParameterTable.propTypes = {
  title: PropTypes.string.isRequired,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
    })
  ).isRequired,
  setValue: PropTypes.func.isRequired,
};

export const cards = [
  {
    id: 1,
    iconName: `book`,
    title: 'Knowledge Base',
    description: 'Explore different guides to get you started with our APIs.',
    link: '/welcome/knowledge-base/api-overview',
    linkLabel: 'View Knowledge Base',
    apiTitle: 'KnowledgeBase',
    key: 'knowledge-base',
  },
  {
    id: 2,
    iconName: `document_multiple`,
    title: 'Release Notes',
    description:
      'Learn more about new updates to the API Marketplace and your APIs.',
    link: '/welcome/release-notes/apim-latest-notes',
    linkLabel: 'View Release Notes',
    apiTitle: 'Eligibility',
    key: 'release-notes',
  },
  {
    id: 3,
    iconName: `lock`,
    title: 'Security',
    description:
      'To ensure a high data security standard for your stored and transmitted data, UnitedHealthcare applies adhesive security resourses in several areas.',
    link: '/welcome/knowledge-base/security',
    linkLabel: 'View Security',
    apiTitle: 'KnowledgeBase',
    key: 'security',
  },
];

import React from 'react';
import PropTypes from 'prop-types';

export const LeftNavHeader = ({ children }) => {
  return <h2>{children}</h2>;
};

LeftNavHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

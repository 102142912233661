import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event, event as adobeEvent } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_REGENERATED_SECRET';

export const resetGeneratedSecret = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

export const useRegenerateSecret = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const secretFailure = (variables, res) => {
    let jobName;
    let eventName;
    const environment = variables.regenerateEnv;
    const apiFieldName = 'client Id';
    const errorMessage =
      'the secret regeneration was unsuccessful, please try again';
    const errorType = 'site';
    if (!variables.isSecretGenerated) {
      jobName = 'generate secret key';
      eventName = 'GENERATE_SECRET_FAILURE';
    } else {
      jobName = 'regenerate secret key';
      eventName = 'REGENERATE_SECRET_FAILURE';
    }
    adobeEvent(eventName, {
      jobName,
      apiFieldName,
      environment,
    });
    const errorApiCode = res.faultCode || '';
    const errorHttpsCode = res.status || '';
    adobeEvent('SITE_ERROR', {
      errorMessage,
      errorApiCode,
      errorHttpsCode,
      errorType,
    });
    throw new Error('Error in API');
  };

  const prodCredentials = (variables, dispatch) => {
    if (!variables.isSecretGenerated) {
      dispatch({
        type: 'GET_CREDENTIALS_PROD_UPDATE',
      });
    }
  };

  const stageCredentials = (variables, dispatch) => {
    if (!variables.isSecretGenerated) {
      dispatch({
        type: 'GET_CREDENTIALS_STAGE_UPDATE',
      });
    }
  };

  const action = useAction(args => {
    const { variables } = args;
    const body = variables.values;
    const environment = variables.regenerateEnv;
    const apiFieldName = 'client Id';
    const errorMessage =
      'the secret regeneration was unsuccessful, please try again';
    const errorType = 'site';
    const eapm = window.elasticApm;
    if (eapm) {
      window.elasticApm.startTransaction(
        `${ACTION_TYPE}_${environment}`,
        'http-request',
        {
          managed: true,
        }
      );
    }
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('AWS_PROXY_URL')}${config('CREDENTIALS_URL')}`, {
        credentials: 'include',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then(res => {
          event('API_TRANSACTION', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('CREDENTIALS_URL'),
              userInfo: args?.userInfo,
              method: 'POST',
              code: res.status,
              operationName: ACTION_TYPE,
              message: res.statusText,
              // result: hasReportCode ? 'success' : 'error',
            },
          });

          event('NEWRELIC_API_TRANSACTION', {
            code: res.status,
            message: res.statusText,
            endpoint: config('CREDENTIALS_URL'),
            userInfo: args?.userInfo,
            method: 'POST',
          });
          if (res.status !== 200 && res.status !== 429) {
            secretFailure(variables, res);
          }
          return res.json();
        })

        .then(res => {
          let jobName;
          let eventName;
          if (!variables.isSecretGenerated) {
            jobName = 'generate secret key';
            eventName = 'GENERATE_SECRET_COMPLETE';
          } else {
            jobName = 'regenerate secret key';
            eventName = 'REGENERATE_SECRET_COMPLETE';
          }
          adobeEvent(eventName, {
            adobeEvent,
            jobName,
            apiFieldName,
            environment,
          });
          if (res.faultCode === 'AAMPCBK') {
            const errorApiCode = res.faultCode || '';
            const errorHttpsCode = res.status || '';
            adobeEvent('SITE_ERROR', {
              errorMessage,
              errorApiCode,
              errorHttpsCode,
              errorType,
            });
            dispatch({
              type: `${ACTION_TYPE}_SECERT_REG_ERROR`,
              payload: res,
            });
          } else if (variables.regenerateEnv === 'prod') {
            dispatch({
              type: `${ACTION_TYPE}_PROD_COMPLETED`,
              payload: res,
            });
            prodCredentials(variables, dispatch);
          } else {
            dispatch({
              type: `${ACTION_TYPE}_STAGE_COMPLETED`,
              payload: res,
            });
            stageCredentials(variables, dispatch);
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          event('API_TRANSACTION_ERROR', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('CREDENTIALS_URL'),
              userInfo: args?.userInfo,
              method: 'POST',
              operationName: ACTION_TYPE,
              // code: res.status,
              // message: res.statusText,
            },
          });
        });
    };
  });

  return [result, action];
};

export const resetSecertAlertData = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_ALERT_DATA_RESET` });
    };
  });
  return [result, action];
};

import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';
import { event } from '@abyss/web/tools/event';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import PropTypes from 'prop-types';
import { IconBrand } from '@abyss/web/ui/IconBrand';

const Wrapper = styled('div', {
  display: 'flex',
  minHeight: '142px',
  background: 'rgb(255, 255, 255)',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: '3px',
  padding: '20px',
  margin: '20px 0',
});

const IconBox = styled('div', {
  flexBasis: '10%',
  borderRadius: '0px',
  minWidth: '78px',
});

const IconWrap = styled('div', {});

const InfoBox = styled('div', {
  flexBasis: '85%',
});

const StyledParagraph = styled('p', {
  minHeight: '48px',
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
});

const StyledLabel = styled('h3', {
  minHeight: '24px',
  color: 'rgb(0, 38, 119)',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '24px',
  margin: '10px 0',
  textAlign: 'initial',
  display: 'inline-flex',
});

export const OverviewCard = ({ src, label, description, route }) => {
  const router = useRouter();

  const navigateHandler = () => {
    event('INTERACTION_EVENT', {
      interactionName: 'all apis',
      interactionValue: label,
      interactionContext: 'quick link clicks',
    });
    router.push(route);
  };

  return (
    <Wrapper>
      <IconBox>
        <IconWrap>
          <IconBrand icon={src} size={48} />
        </IconWrap>
      </IconBox>
      <InfoBox>
        <Link
          variant="nounderline"
          href={route}
          onClick={e => {
            e.preventDefault();
            navigateHandler();
          }}
        >
          <StyledLabel>{label}</StyledLabel>
        </Link>
        <StyledParagraph>{description}</StyledParagraph>
      </InfoBox>
    </Wrapper>
  );
};

OverviewCard.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  route: PropTypes.string,
};

OverviewCard.defaultProps = {
  src: '',
  label: '',
  description: '',
  route: 'new-page',
};

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { RenderHtml } from 'src/common/RenderHtml';

const Wrapper = styled('div', {
  padding: '16px 32px 32px 32px',
  fontSize: '15px',
  b: {
    fontWeight: 400,
  },
});

export const APIDetails = ({ currentApi }) => {
  return (
    <Wrapper>
      <RenderHtml>{currentApi.description}</RenderHtml>
    </Wrapper>
  );
};

APIDetails.propTypes = {
  currentApi: PropTypes.shape({ description: PropTypes.string }),
};

APIDetails.defaultProps = {
  currentApi: { description: '' },
};

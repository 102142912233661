import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';

const NavView = styled('section', {
  background: 'rgb(255, 255, 255)',
  borderRadius: '3px',
  minWidth: '360px',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $xl': {
    display: 'block',
  },
});

export const LeftNav = ({ children }) => {
  return <NavView>{children}</NavView>;
};

LeftNav.propTypes = {
  children: PropTypes.node,
};

LeftNav.defaultProps = {
  children: null,
};

import React, { useEffect, useState } from 'react';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useSelector } from 'react-redux';
import { styled } from '@abyss/web/tools/styled';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { IconMaterial } from '@abyss/web/ui/IconMaterial/IconMaterial';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { Accordion } from '@abyss/web/ui/Accordion';
import { event } from '@abyss/web/tools/event';
import { Link } from '@abyss/web/ui/Link';
import PropTypes from 'prop-types';
import { Badge } from '@abyss/web/ui/Badge';
import apiData from './OrganizationListReviewUrl.json';
import 'regenerator-runtime/runtime';
import { constants } from '../../../../../../common/Utils/Constants';

const Wrapper = styled('div', {});

const AccordianLinks = styled('div', {
  display: 'grid',
});

const ApiStatusStyles = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 8px',
  gap: '8px',

  '@screen >= $xs': {
    padding: '10px',
  },

  '@screen >= $sm': {
    padding: '10px',
  },
});

const ApiCategoryStyles = styled('div', {
  width: '100%',
});

const DisplayFlex = styled('div', {
  display: 'flex',
});

const IconPadding = styled('div', {
  marginTop: '2px',
  paddingRight: '10px',
  marginLeft: '2px',
});

const UpdatedDate = styled('p', {
  color: '#424242',
  fontSize: '$sm',
  fontWeight: '$normal',
  minHeight: '20px',
  lineHeight: '20px',
  display: 'flex',
  marginBottom: '0px',
});

const StyledLink = styled(Link, {});

const AccordianLink = styled('div', {});

export const OrganizationList = ({ orgUserInfo }) => {
  const [date, setDate] = useState('');
  const healthCheckApiData = useSelector(state => state.GET_API_HEALTH_CHECK);
  const healthCheckData = healthCheckApiData?.data;
  const router = useRouter();
  const configs = useLagoon('configs');
  const orgViewPageSize = configs('ORG_VIEW_PAGE_SIZE');
  const featureToggles = useLagoon('feature-toggles');
  const providerDemoToggle = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;

  const getApiEnvironment = row => {
    return (
      <Wrapper>
        <If condition={row.includes('Production')}>
          <ApiStatusStyles>{row[1]}</ApiStatusStyles>
        </If>
        <If condition={row.includes('Staging')}>
          <ApiStatusStyles>{row[0]}</ApiStatusStyles>
        </If>
      </Wrapper>
    );
  };

  const columns = [
    {
      Header: <span style={{ fontSize: '13px' }}>Product Name</span>,
      accessor: 'apiCategory',
    },
    {
      Header: <span style={{ fontSize: '13px' }}>Environment</span>,
      accessor: 'environment',
    },
    {
      Header: <span style={{ fontSize: '13px' }}>Status</span>,
      accessor: 'status',
    },
  ];

  const getApiCategory = row => {
    const apiInfo = apiData[row?.toLowerCase()];
    const interactionName = apiInfo.name;
    const interactionContext = 'product link clicks';
    const feedbackRoute = `/welcome/${row?.toLowerCase()}-feedback`;
    const getReviewApi = apiLink => {
      const interactionValue = 'review api';
      event('INTERACTION_EVENT', {
        interactionName,
        interactionValue,
        interactionContext,
      });
      router.push(apiLink);
    };
    const getReleaseNote = releaseNoteLink => {
      const interactionValue = 'release notes';
      event('INTERACTION_EVENT', {
        interactionName,
        interactionValue,
        interactionContext,
      });
      router.push(releaseNoteLink);
    };
    const getFeedBackPage = () => {
      const interactionValue = 'api feedback';
      event('INTERACTION_EVENT', {
        interactionName,
        interactionValue,
        interactionContext,
      });
      router.push(feedbackRoute);
    };
    return (
      <ApiCategoryStyles>
        <Accordion
          type="multiple"
          style={{
            backgroundColor: 'inherit',
            width: '100%',
          }}
        >
          <Accordion.Item value={apiInfo?.name}>
            <Accordion.Trigger
              style={{
                backgroundColor: 'inherit',
                fontWeight: 'bold',
                borderBottom: 0,
                fontSize: '14px',
              }}
            >
              {apiInfo?.name}
            </Accordion.Trigger>
            <Accordion.Content style={{ backgroundColor: 'inherit' }}>
              <AccordianLinks>
                <AccordianLink>
                  <StyledLink
                    size="13px"
                    fontWeight="$normal"
                    onClick={() => getReviewApi(apiInfo?.technicalDetailsPage)}
                  >
                    Review API
                  </StyledLink>
                </AccordianLink>
                <AccordianLink>
                  <StyledLink
                    size="13px"
                    fontWeight="$normal"
                    onClick={() => getFeedBackPage()}
                  >
                    API Feedback
                  </StyledLink>
                </AccordianLink>
                <If condition={apiInfo?.releaseNotesPage}>
                  <AccordianLink>
                    <StyledLink
                      size="13px"
                      fontWeight="$normal"
                      onClick={() => getReleaseNote(apiInfo?.releaseNotesPage)}
                    >
                      Release Notes
                    </StyledLink>
                  </AccordianLink>
                </If>
              </AccordianLinks>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      </ApiCategoryStyles>
    );
  };

  const getSortedDataList = data => {
    if (data) {
      const apiSortedData = [...data];
      return apiSortedData?.sort((a, b) => a.localeCompare(b));
    }
    return [];
  };

  const stageList = providerDemoToggle
    ? orgUserInfo.linkedApis?.stage
    : orgUserInfo.linkedApis?.stage.filter(
        item => item !== 'ProviderDemographic'
      );
  const stageApiList = getSortedDataList(stageList);

  const getApiStatus = (item, row) => {
    let stageColor = 'neutral';
    let stageStatusValue = 'Fetching status';
    if (healthCheckData?.stage) {
      stageColor = healthCheckData?.stage[item] === 200 ? 'success' : 'error';
      stageStatusValue = stageColor === 'success' ? 'Operational' : 'Outage';
    }

    let prodColor = 'neutral';
    let prodStatusValue = 'Fetching status';
    if (healthCheckData?.prod) {
      prodColor = healthCheckData?.prod[item] === 200 ? 'success' : 'error';
      prodStatusValue = prodColor === 'success' ? 'Operational' : 'Outage';
    }

    if (healthCheckApiData?.error) {
      stageColor = 'warning';
      stageStatusValue = 'Not available';
      prodColor = 'warning';
      prodStatusValue = 'Not available';
    }

    return (
      <Wrapper>
        <If condition={row.includes('Production')}>
          <ApiStatusStyles>
            <Badge
              rounded
              variant={prodColor}
              css={{
                'abyss-badge-content': {
                  fontSize: '13px !important',
                },
              }}
            >
              {prodStatusValue}
            </Badge>
          </ApiStatusStyles>
        </If>
        <If condition={row.includes('Staging')}>
          <ApiStatusStyles>
            <Badge
              rounded
              variant={stageColor}
              css={{
                'abyss-badge-content': {
                  fontSize: '13px !important',
                },
              }}
            >
              {stageStatusValue}
            </Badge>
          </ApiStatusStyles>
        </If>
      </Wrapper>
    );
  };

  const dataList =
    stageApiList.length > 0
      ? stageApiList.map(item => {
          const status = ['Staging'];
          const prodList = orgUserInfo.linkedApis?.prod;
          if (prodList?.includes(item)) {
            status.push('Production');
          }
          const data = {
            apiCategory: getApiCategory(item),
            environment: getApiEnvironment(status),
            status: getApiStatus(item, status),
          };
          return data;
        })
      : [];

  const lastUpdatedText = () => {
    return (
      <DisplayFlex>
        <IconPadding>
          <IconMaterial icon="update" size={16} />
        </IconPadding>
        <UpdatedDate style={{ fontSize: '13px' }}>
          last updated {date}
        </UpdatedDate>
      </DisplayFlex>
    );
  };

  const dataTableProps = useDataTable({
    showPagination: true,
    initialData: dataList,
    initialColumns: columns,
    pageSizeDefault: orgViewPageSize?.value || 10,
    showTopPagination: false,
    additionalPaginationText: lastUpdatedText(),
  });

  useEffect(() => {
    dataTableProps.setData(dataList);

    setDate(healthCheckApiData?.lastUpdatedDate);
  }, [healthCheckApiData]);

  return (
    <Wrapper>
      <DataTable
        title={
          <span style={{ fontSize: '19px' }}>Your Organization's APIs</span>
        }
        tableState={dataTableProps}
        css={{
          '.abyss-data-table-pagination-top-root': {
            display: 'none !important',
          },
          '.abyss-data-table-pagination-bottom-root': {
            marginTop: constants.IMPORTANT_TAG_ZERO,
            paddingTop: 'var(--space-sm) !important',
            paddingBottom: 'var(--space-sm) !important',
            backgroundColor: 'white !important',
            borderBottomRightRadius: '4 !important',
            borderBottomLeftRadius: '4 !important',
          },
          '.abyss-table-td': {
            padding: constants.IMPORTANT_TAG_ZERO,
          },
          '.abyss-accordion-root': {
            border: 'none !important',
          },
          '.abyss-table-cell': {
            padding: constants.IMPORTANT_TAG_ZERO,
            fontSize: '13px !important',
          },
        }}
      />
    </Wrapper>
  );
};

OrganizationList.propTypes = {
  orgUserInfo: PropTypes.shape({
    linkedApis: PropTypes.shape({
      stage: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

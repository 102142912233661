import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout/PageLayout';
import { event } from '@abyss/web/tools/event';
import { styled } from '@abyss/web/tools/styled';
import { APIInfoSection } from './APIInfoSection/APIInfoSection';
import { IntroSection } from './IntroSection/IntroSection';
import { GetStartedAndContactSection } from './GetStartedAndContactSection/GetStartedAndContactSection';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },
  '@screen >= $xl': {
    padding: 0,
  },
});

export const GettingStarted = () => {
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);

  useEffect(() => {
    event('GETTING_STARTED_PAGE_LOAD', { dsUserInfo, userInfo });
  }, []);

  return (
    <PageLayout>
      <Wrapper>
        <IntroSection />
        <GetStartedAndContactSection />
        <APIInfoSection />
      </Wrapper>
    </PageLayout>
  );
};

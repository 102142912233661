import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import PropTypes from 'prop-types';
import { Modal } from '@abyss/web/ui/Modal';

const Body = styled('div', {
  padding: '32px',

  p: {
    fontWeight: '800',
  },
});

const Wrapper = styled('div', {});

export const CommonModal = ({ children, heading, closeModal }) => {
  return (
    <Wrapper>
      <Modal
        size="md"
        title={heading}
        isOpen
        onClose={closeModal}
        className="abyss-modal-content-title"
      >
        <Body>{children}</Body>
      </Modal>
    </Wrapper>
  );
};

CommonModal.propTypes = {
  children: PropTypes.element,
  heading: PropTypes.string,
  closeModal: PropTypes.func,
};

CommonModal.defaultProps = {
  children: null,
  heading: '',
  closeModal: () => {},
};

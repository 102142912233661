export const baseStyles = () => {
  return {
    before: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 10,
      flex: '0 1 auto',
    },
    after: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 10,
      flex: '0 1 auto',
    },
  };
};

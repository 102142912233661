import React, { useEffect, useState } from 'react';
import { event } from '@abyss/web/tools/event';
import { useSelector } from 'react-redux';
import { APICard } from 'src/common/APICard';
import { styled } from '@abyss/web/tools/styled';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useLagoon } from '@abyss/web/hooks/useLagoon';

const Wrapper = styled('div', {
  margin: '64px 0 0px 0',
});

const Heading = styled('h3', {
  color: '#002677',
  width: '624px',
  height: '32px',
  left: '0px',
  top: '0px',
  fontStyle: 'normal',
  fontWeight: '600 !important',
  fontSize: '28px ! important',
  lineHeight: '32px',
});

const Description = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '16px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
  left: '0px',
  marginTop: '15px',
  marginBottom: '45px',
});

const CategoryBox = styled('div', {
  display: 'flex',
  gap: '15px',

  '@screen >= $xs': {
    display: 'block',
  },
  '@screen >= $xl': {
    display: 'flex',
    gap: '15px',
  },
  '& .abyss-swiperslide-content': {
    maxHeight: 'unset',
    paddingBottom: '0px',
  },
  '& .abyss-slide-content-wrapper': {
    padding: '0px',

    '@screen < $lg': {
      width: '100%',
    },
  },
  '& .abyss-carousel-pagination': {
    margin: '3px',
  },
  '& .abyss-carousel-pagination-container': {
    margin: '3px',
  },
  '& .abyss-c-PJLV': {
    marginRight: '10px',
  },
  '& .abyss-icon': {
    marginRight: '5px',
  },
  '& .abyss-carousel-controls': {
    height: '50px',
    '@screen >= $xl': {
      width: '1200px',
    },
  },
});

export const BrowseApi = () => {
  const [filteredCards, setFilteredCards] = useState([]);
  const matches = useMediaQuery('(max-width: 984px)');
  const dsData = useSelector(state => state.GET_DS_USER_INFO);
  const dsUserInfo = dsData.data;
  const cards = useLagoon('browse-api-list');
  const cardList = cards()?.filter(card => card.active);
  useEffect(() => {
    const dataAc = cardList?.sort((a, b) => (a.title > b.title ? 1 : -1));
    if (!dsUserInfo?.linkedApis || dsUserInfo?.stage?.length === 0) {
      setFilteredCards(dataAc);
      return;
    }

    let apiCardsDup = dsUserInfo.linkedApis?.stage
      ? [...dsUserInfo.linkedApis?.stage]
      : [];

    if (dsUserInfo.linkedApis?.stage) {
      apiCardsDup = [...apiCardsDup, ...dsUserInfo.linkedApis?.stage];
    }
    const apiCards = [];
    apiCardsDup.forEach(el => {
      if (!apiCards.includes(el)) {
        apiCards.push(el);
      }
    });

    const data = [];
    dataAc.forEach(el => {
      if (!apiCards.includes(el.apiTitle)) {
        data.push(el);
      }
    });
    setFilteredCards(data);
  }, [dsUserInfo]);

  const getSlidesData = () => {
    const slides = filteredCards?.map((el, idx) => {
      return (
        <Slide>
          <Slide.Container>
            <Slide.Content>
              <APICard
                key={el.key}
                src={el.icon}
                title={el.title}
                description={el.description}
                link={el.link}
                linkLabel={el.linkLabel}
                index={idx}
                badge={el.displayBadge}
                badgeMessage={el.badgeMessage}
                badgeLink={el.badgeLink}
                badgeVariant={el.badgeVariant}
                badgeIcon={el.badgeIcon}
                onClick={() => {
                  const interactionName = el.title;
                  const interactionValue = el.linkLabel;
                  const interactionContext = 'tile cta clicks';
                  event('INTERACTION_EVENT', {
                    interactionName,
                    interactionValue,
                    interactionContext,
                  });
                }}
              />
            </Slide.Content>
          </Slide.Container>
        </Slide>
      );
    });

    return slides;
  };

  return (
    <Wrapper>
      <Heading id="browse-api-heading">Browse APIs</Heading>
      <Description id="browse-api-description">
        See what these APIs can do to further support your practice's continued
        efforts to increase efficiency.
      </Description>
      <CategoryBox>
        <If condition={!dsData.loading && dsUserInfo.status}>
          <If condition={matches}>
            <Carousel slidesPerView={1} slides={getSlidesData()} rounded />
          </If>
          <If condition={!matches}>
            <Carousel slidesPerView={3} slides={getSlidesData()} rounded />
          </If>
        </If>
      </CategoryBox>
    </Wrapper>
  );
};

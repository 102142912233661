import { ACTION_TYPE } from './useDocumentation';

const initialState = {
  data: {
    info: { title: '', version: '', description: '' },
    paths: {},
    tags: [],
    pathsByTag: [],
    definitions: {},
    tagNames: [],
  },
  loading: false,
  error: false,
};

export const reducerDocumentation = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_CALLED`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${ACTION_TYPE}_COMPLETED`:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case `${ACTION_TYPE}_ERROR`:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  },
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { StyledLink } from 'src/common/Link';
import { Button } from '@abyss/web/ui/Button';
import { event } from '@abyss/web/tools/event';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { Testimonials } from 'src/common/Testimonials/Testimonials';
import { testimonialMock } from 'src/app/App/__mock_data__/testimonialMock';
import { APISlider } from 'src/common/APISlider/APISlider';
import { relatedAPIsMock } from 'src/app/App/__mock_data__/referrals_relatedAPIs';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { SubscribeModal } from '../SubscribeModal';
import { SubscribeToAPIModal } from '../../SubscribeToAPIModal';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { constants } from '../../../../../common/Utils/Constants';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
});

const Container = styled('div', {
  flexBasis: '100%',
});

const PageHeadingContainer = styled('div', {
  display: 'flex',
  marginTop: '8px',
  marginBottom: '32px',
  flexBasis: '50%',
});

const Icon = styled('div', {
  height: '49.5px',
  width: '60px',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $sm': {
    display: 'block',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px',
  fontWeight: '600',
  minHeight: '40px',
  lineHeight: '40px',
  marginTop: '8px',
});

const PageSubHeading = styled('h2', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px !important',
  fontWeight: '600',
  minHeight: '32px',
  lineHeight: '32px',
  marginBottom: '16px',
});

const PageSecondaySubHeading = styled('h4', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginBottom: '16px',
  marginTop: '16px',
});

const PageLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  marginTop: '32px',
  marginBottom: '0px',
});

const PageDesc = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '16px',
});

const PageContent = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '48px',
});

const AnchorBox = styled('div', {
  height: '20px',
  display: 'flex',
  alignContent: 'center',
  marginBottom: '16px',
  marginTop: '8px',
  marginLeft: '8px',
});

const ButtonMargin = styled('div', {
  margin: '24px 0',

  '@screen >= $xs': {
    button: {
      width: '100%',
    },
  },

  '@screen >= $sm': {
    button: {
      width: '339px',
    },
  },

  '@screen >= $xl': {
    textAlign: 'right',
  },
});

const Flexed = styled('div', {
  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
  },
});

const ContainerHalf = styled('div', {
  flexBasis: '50%',
});

const LinkMargin = styled('div', {
  marginRight: '12px',
  alignSelf: 'center',
});

export const ReferralsAPI = () => {
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const [, getDSUserInfo] = useDSUserInfo();
  const router = useRouter();

  useEffect(() => {
    if (userInfo.uuid && !dsUserInfo.data.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  const subscribeToAPI = () => {
    const interactionName = 'Referrals GraphQl api';
    const interactionValue = constants.SUBSCRIBE_TO_API;
    const interactionContext = 'page cta link clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  const subscribeClicked = () => {
    const jobName = constants.SUB_TO_API_JOB_NAME;

    subscribeToAPI();
    event('SUBSCRIBE_EVENT', {
      event,
      jobName,
    });

    if (userInfo.firstName === '' || userInfo.error) {
      setIsSubscribeModalOpen(true);
    } else {
      setShowSubscribeAPIModal(true);
    }
  };
  useEffect(() => {
    event('API_DETAILS_REFERRALGRAPQLAPI_PAGE_LOAD', {
      dsUserInfo: dsUserInfo.data,
      userInfo,
    });
  }, []);

  const accessSandbox = () => {
    const interactionName = 'Referrals GraphQl api';
    const interactionValue = 'access sandbox';
    const interactionContext = 'page cta link clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    router.push('/documentation/referrals');
  };

  const apiDocumentation = () => {
    const interactionName = `referralsgraphql api`;
    const interactionValue = 'api technical documentation';
    const interactionContext = 'referralsgraphql api';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    router.push('/documentation/referrals');
  };

  return (
    <PageLayout>
      <SubscribeModal
        setIsSubscribeModalOpen={setIsSubscribeModalOpen}
        isSubscribeModalOpen={isSubscribeModalOpen}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Wrapper>
        <StyledFlex>
          <Container>
            <Breadcrumbs />
            <Flexed>
              <PageHeadingContainer>
                <Icon>
                  <IconBrand icon="chat" size={48} />
                </Icon>
                <PageHeading>Referrals API</PageHeading>
              </PageHeadingContainer>
              <ContainerHalf>
                <ButtonMargin>
                  <Button
                    variant="solid"
                    onClick={apiDocumentation}
                    css={{
                      backgroundColor: '#002677',
                      fontSize: '15px',
                      height: '42px',

                      '@screen >= $xs': {
                        width: '100%',
                      },

                      '@media (min-width: 576px)': {
                        width: '339px',
                      },
                    }}
                  >
                    API Technical Documentation
                  </Button>
                </ButtonMargin>
              </ContainerHalf>
            </Flexed>
            <PageSubHeading>Overview</PageSubHeading>
            <PageDesc>
              The Referrals API fetches referral information from the past year.
            </PageDesc>
            <PageLinkContainer>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    id="SubscribeToAPI"
                    onClick={subscribeClicked}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    {constants.SUBSCRIBE_TO_API}
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    onClick={accessSandbox}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    Access Sandbox
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
            </PageLinkContainer>
            <PageSecondaySubHeading>About Referrals API</PageSecondaySubHeading>
            <PageContent>
              This is a Referrals API that provides detailed information about
              previous referrals and referral submissions. The API uses
              JavaScript Object Notation (JSON) for requests and responses. All
              API traffic is encrypted over HTTPS and authentication is handled
              with OAuth2.
            </PageContent>
            <PageSecondaySubHeading>Prerequisites </PageSecondaySubHeading>
            <PageContent>
              The Consumer should have valid Client-ID/Secrets the APIs to be
              fully functional.
            </PageContent>
            <PageSecondaySubHeading>Security </PageSecondaySubHeading>
            <PageContent>
              API's are secured using OAuth 2.0 facilitated by the Link API
              Gateway. All calls to APIs are encrypted over HTTPS. APIs support
              connections using TLS version 1.2 or higher. All modern languages
              and frameworks support TLS 1.2 (although specific older ones do
              not).
            </PageContent>
          </Container>
        </StyledFlex>
        <Testimonials initialData={testimonialMock} />
        <APISlider initialData={relatedAPIsMock} />
      </Wrapper>
    </PageLayout>
  );
};

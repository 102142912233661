import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/ui/tools/classNames';

import { baseStyles } from './AccordionIcon.styles';

export const AccordionIcon = ({ className, isOpen, size, ...props }) => {
  const classes = useStyles(baseStyles, { isOpen, size });

  return <div {...props} className={classNames(classes.icon, className)} />;
};

AccordionIcon.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
};

AccordionIcon.defaultProps = {
  className: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Badge } from '@abyss/web/ui/Badge';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { StyledLink } from 'src/common/Link';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { AppContext } from 'src/app/App/AppProvider';
import { event } from '@abyss/web/tools/event';
import Markdown from 'markdown-to-jsx';

const Wrapper = styled('div', {
  background: 'rgb(255, 255, 255)',
  border: '1px solid rgb(219, 219, 219)',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '4px',
  margin: '0',
  display: 'flex',
  flexDirection: 'column',

  '@screen >= $xl': {
    width: '100%',
    minHeight: '366px',
  },
  '@media (min-width: 984px)': {
    minHeight: '366px',
  },
  '@media (max-width: 984px)': {
    minHeight: '320px',
  },
});

const IconBox = styled('div', {
  display: 'flex',
});

const BadgeStyle = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '5px',
  '.abyss-badge-content': {
    fontSize: '13px',
  },
});

const Title = styled('h3', {
  color: 'rgb(0, 38, 119)',
  fontSize: '22px ! important',
  fontWeight: '600',
  textAlign: 'center',
  lineHeight: '24px ! important',
  margin: '0 10px 10px 10px',
});
const Description = styled('p', {
  flexBasis: '20%',
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  letterSpacing: '0px',
  lineHeight: '18px',
  margin: '0 35px',
  wordBreak: 'break-word',
  minHeight: '48px',
  textAlign: 'center',
});

const DescBox = styled('div', {
  minHeight: '48px',
  height: 'auto',
  marginBottom: '24px',
});

const LinkBox = styled('div', {
  color: 'rgb(25, 110, 207)',
  fontSize: '15px',
  fontWeight: 'bold',
  letterSpacing: '0px',
  lineHeight: '20px',
  display: 'flex',
  justifyContent: 'center',
  margin: '16px 0',
  marginTop: 'auto',
  marginBottom: '24px',

  a: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  img: {
    marginLeft: '6px',
  },
});

const StyledIconBrand = styled(IconBrand, {
  margin: '30px auto',
});

export const APIsCategoryCard = ({
  src,
  title,
  description,
  link,
  linkLabel,
  badge,
  badgeMessage,
  badgeLink,
  onClick,
  index,
  badgeVariant,
  badgeIcon,
}) => {
  const router = useRouter();
  const { filters, updateFilters } = React.useContext(AppContext);
  const onViewApiClicked = async (callback, routeLink, filterTitle) => {
    const newFilters = filters.map(el => {
      const checked = el.title === filterTitle;
      return {
        ...el,
        checked,
      };
    });
    await updateFilters(newFilters);
    callback();
    router.push({
      pathname: routeLink,
      state: { filter: filterTitle },
    });
  };
  const badgeEvent = () => {
    const interactionName = title;
    const interactionValue = badgeMessage;
    const interactionContext = 'tile cta clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    router.push(badgeLink);
  };
  return (
    <Wrapper style={index === 3 ? { marginRight: '0px' } : {}}>
      <IconBox>
        <StyledIconBrand icon={src} size={80} />
      </IconBox>
      <Title>{title}</Title>
      {badge && (
        <BadgeStyle>
          <Badge
            icon={
              badgeLink !== null && badgeLink !== '' ? (
                <IconMaterial
                  color={`$${badgeVariant}1`}
                  icon={badgeIcon || ''}
                  size="$sm"
                />
              ) : null
            }
            variant={badgeVariant}
            style={badgeLink ? { cursor: 'pointer' } : {}}
            onClick={
              badgeLink
                ? () => {
                    badgeEvent();
                  }
                : {}
            }
          >
            {badgeMessage}
          </Badge>
        </BadgeStyle>
      )}
      <DescBox>
        <Markdown
          options={{
            overrides: {
              p: Description,
              span: Description,
            },
          }}
        >
          {description}
        </Markdown>
      </DescBox>
      <LinkBox>
        <StyledLink
          onClick={() => {
            onViewApiClicked(onClick, link, title);
          }}
          fontWeight="$bold"
          size="15px"
          after={<IconMaterial icon="arrow_forward" size="20px" />}
        >
          {linkLabel}
        </StyledLink>
      </LinkBox>
    </Wrapper>
  );
};

APIsCategoryCard.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  badge: PropTypes.bool,
  badgeMessage: PropTypes.string,
  badgeLink: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
  badgeVariant: PropTypes.string,
  badgeIcon: PropTypes.string,
};

APIsCategoryCard.defaultProps = {
  src: '',
  title: '',
  description: '',
  link: '',
  linkLabel: '',
  badge: false,
  badgeMessage: '',
  badgeLink: '',
  badgeVariant: '',
  badgeIcon: '',
  onClick: () => {},
};

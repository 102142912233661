import React from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';

export const FeedbackModal = ({
  isOpen,
  isClosed,
  title,
  isShowSubscribeModal,
}) => {
  const modalClose = () => {
    isClosed(false);
  };

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      closeOnClickOutside={false}
      onClose={() => modalClose()}
      css={{
        'abyss-modal-header-container': {
          color: '#002677',
          fontSize: '28.83px',
        },
        'abyss-modal-content-title': {
          fontSize: '26px !important',
        },
      }}
    >
      <Modal.Section>
        <Layout.Group alignLayout="right">
          <Button
            id="submitButton"
            size="$md"
            css={{
              'abyss-button-root': {
                fontSize: '15px',
              },
            }}
            onClick={() => modalClose()}
          >
            Yes, it was helpful
          </Button>

          <Button
            variant="outline"
            id="closeButton"
            size="$md"
            css={{
              'abyss-button-root': {
                fontSize: '15px',
              },
            }}
            onClick={() => {
              modalClose();
              isShowSubscribeModal(true);
            }}
          >
            No, I think I need more help
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};
FeedbackModal.propTypes = {
  isClosed: PropTypes.func.isRequired,
  isShowSubscribeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

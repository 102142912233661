export const convertCSharp = ({ url, method, headers, body }) => {
  const codeArr = [];
  let contentType;
  codeArr.push(`var client = new RestClient("${url}");`);
  codeArr.push('client.Timeout = -1;');
  codeArr.push(`var request = new RestRequest(Method.${method});`);

  const headerArr = headers.map(header => {
    if (header.key === 'Content-Type') {
      contentType = header.value;
    }
    return `request.AddHeader("${header.key}", "${header.value}");`;
  });
  codeArr.push(`${headerArr.join('\n')}`);

  if (body) {
    codeArr.push(`var body = ${JSON.stringify(JSON.stringify(body))};`);
    codeArr.push(
      `request.AddParameter("${contentType}", body,  ParameterType.RequestBody);`
    );
  }

  codeArr.push(`IRestResponse response = client.Execute(request);`);
  codeArr.push(`Console.WriteLine(response.Content);`);

  return codeArr.join('\n');
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'BREADCRUMBS';

export const onBreadcrumbsHandler = () => {
  const action = useAction(data => {
    const list = data.match.url.split('/').filter(el => el);
    const mapped = list.map((m, i) => {
      const selected = i === list.length - 1;
      return { url: m, selected, label: m };
    });

    return dispatch => {
      dispatch({
        type: `${ACTION_TYPE}_CALLED`,
        payload: mapped,
      });
    };
  });

  return [action];
};

export const onBreadcrumbsHandlerMultiLevel = () => {
  const action = useAction(data => {
    const list = data.match.url.split('/').filter(el => el);
    const mapped = list.map((m, i) => {
      const selected = i === list.length - 1;
      if (list.length > 2) {
        const urlList = list.slice(0, i + 1);
        const urlString = urlList.join('/');
        return { url: urlString, selected, label: m };
      }
      return { url: m, selected, label: m };
    });

    return dispatch => {
      dispatch({
        type: `${ACTION_TYPE}_CALLED`,
        payload: mapped,
      });
    };
  });

  return [action];
};

export const onBreadcrumbsHandlerIgnoreLatest = () => {
  const action = useAction(data => {
    const list = data.match.url.split('/').filter(el => el);
    list.pop(list.length - 1);
    const mapped = list.map((m, i) => {
      const selected = i === list.length - 1;
      return { url: m, selected, label: m };
    });

    return dispatch => {
      dispatch({
        type: `${ACTION_TYPE}_CALLED`,
        payload: mapped,
      });
    };
  });

  return [action];
};

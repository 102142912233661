import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { event } from '@abyss/web/tools/event';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { Layout } from '@abyss/web/ui/Layout';

export const SubscribeModal = ({
  isSubscribeModalOpen,
  setIsSubscribeModalOpen,
}) => {
  const router = useRouter();
  const modalName =
    'meeting request with an api consultant before sign in modal';

  useEffect(() => {
    if (isSubscribeModalOpen) {
      event('MODAL_VIEW', {
        event,
        modalName,
      });
    }
  }, [isSubscribeModalOpen]);

  const cancelEvent = () => {
    event('MODAL_CLOSE', {
      event,
      modalName,
    });
  };
  const onModalClose = () => {
    cancelEvent();
    setIsSubscribeModalOpen(false);
  };
  const signIn = () => {
    const interactionName = `welcome`;
    const interactionValue = 'welcome';
    const interactionContext = '';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });

    if (process.env.NODE_ENV === 'development') {
      router.push('/welcome');
    } else {
      window.location = `${config('IDENTITY_API_URL')}/login`;
    }

    storage.local.set('IS_SIGN_IN_CLICKED', true);
    if (storage.local.get('IS_SIGNED_OUT')) {
      storage.local.remove('IS_SIGNED_OUT');
    }

    setIsSubscribeModalOpen(false);
  };
  return (
    <Modal
      title="Request a meeting with an API consultant"
      isOpen={isSubscribeModalOpen}
      size="md"
      onClose={() => onModalClose()}
      css={{
        'abyss-modal-content-title': { 'font-size': '25px !important' },
      }}
    >
      <Modal.Section>
        <p style={{ fontSize: '15px' }}>
          To request an API subscription, sign into your account now. If you do
          not have an account, you can create one.
        </p>
      </Modal.Section>
      <Modal.Section>
        <Layout.Group alignLayout="right">
          <Button
            variant="outline"
            fontSize="15px"
            onClick={() => {
              setIsSubscribeModalOpen(false);
              cancelEvent();
            }}
          >
            Cancel
          </Button>
          <Choose>
            <When condition={process.env.NODE_ENV === 'development'}>
              <Button fontSize="15px" onClick={signIn}>
                Sign In/Up
              </Button>
            </When>
            <Otherwise>
              <Button
                href={`${config('IDENTITY_API_URL')}/login`}
                onClick={signIn}
                fontSize="15px"
              >
                Sign In/Up
              </Button>
            </Otherwise>
          </Choose>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};

SubscribeModal.propTypes = {
  setIsSubscribeModalOpen: PropTypes.func.isRequired,
  isSubscribeModalOpen: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/ui/tools/classNames';

import { baseStyles } from './Enhancer.styles';

export const Enhancer = ({ styles, styleProps, position, ...props }) => {
  const classes = useStyles(baseStyles, {
    ...styles,
    ...styleProps,
    position,
  });

  return (
    <div
      {...props}
      className={classNames({
        [classes.before]: position === 'before',
        [classes.after]: position === 'after',
      })}
    />
  );
};

Enhancer.propTypes = {
  styles: PropTypes.shape({}),
  styleProps: PropTypes.shape({}),
  position: PropTypes.oneOf(['before', 'after']),
};

Enhancer.defaultProps = {
  styles: null,
  styleProps: null,
  position: 'before',
};

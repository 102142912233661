import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import style from './APISlider.css';
import { DesktopViewAPISlider } from './DesktopViewAPISlider/DesktopViewAPISlider';
import { TabletViewAPISlider } from './TabletViewAPISlider/TabletViewAPISlider';
import { MobileViewAPISlider } from './MobileViewAPISlider/MobileViewAPISlider';

export const APISlider = ({ initialData }) => {
  const featureToggles = useLagoon('feature-toggles');
  const providerDemoToggle = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;
  const sliderData = providerDemoToggle
    ? initialData
    : initialData.filter(api => api.title !== 'Provider Demographic');
  const [deviceView, setDeviceView] = useState('');

  const widthHandler = () => {
    if (window.outerWidth > 1200) {
      setDeviceView('desktop');
    } else if (window.outerWidth < 1200 && window.outerWidth > 576) {
      setDeviceView('tablet');
    } else if (window.outerWidth < 576) {
      setDeviceView('mobile');
    }
  };

  useEffect(() => {
    widthHandler();
    window.addEventListener('resize', widthHandler);
    return () => {
      window.removeEventListener('resize', widthHandler);
    };
  }, []);

  return (
    <div className={style.Wrapper}>
      {deviceView === 'desktop' ? (
        <DesktopViewAPISlider initialData={sliderData} />
      ) : null}
      {deviceView === 'tablet' ? (
        <TabletViewAPISlider initialData={sliderData} />
      ) : null}
      {deviceView === 'mobile' ? (
        <MobileViewAPISlider initialData={sliderData} />
      ) : null}
    </div>
  );
};

APISlider.propTypes = {
  initialData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkLabel: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

APISlider.defaultProps = {
  initialData: [],
};

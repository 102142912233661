import React, { Fragment } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { styled } from '@abyss/web/tools/styled';
import { resetPostTinManagement } from '../../../../../../hooks/usePostTinApi/usePostTinApi';
import { resetPatchTinManagement } from '../../../../../../hooks/usePatchTinApi/usePatchTinApi';

const SubmitButton = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '32.5',
});
const Description = styled('div', {
  marginTop: '-10px',
  fontSize: '14.22px',
});

export const FailureModal = ({ isOpen, isClosed }) => {
  const [, resetTinManagement] = resetPostTinManagement();
  const [, resetPatchTinManagementApi] = resetPatchTinManagement();

  const onClick = () => {
    isClosed(false);
    resetTinManagement();
    resetPatchTinManagementApi();
  };
  return (
    <Fragment>
      <Modal
        title="Your updates have failed"
        isOpen={isOpen}
        onClose={onClick}
        css={{
          'abyss-modal-header-container': {
            color: '#002677',
            fontSize: '28.83px',
          },
        }}
      >
        <Modal.Section>
          <Description>
            We were unable to process your request. Please try again.
          </Description>
          <SubmitButton>
            <Button
              type="submit"
              size="$md"
              style={{ marginTop: 16 }}
              onClick={onClick}
            >
              Ok
            </Button>
          </SubmitButton>
        </Modal.Section>
      </Modal>
    </Fragment>
  );
};
FailureModal.propTypes = {
  isClosed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

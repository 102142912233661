import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { useCopy } from '@abyss/web/hooks/useCopy';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { event } from '@abyss/web/tools/event';
import { styled } from '@abyss/web/tools/styled';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flexStart',
  padding: '18px',
  width: '100%',
  background: '#ffffff',
  border: '1px solid #000000',
  boxSizing: 'border-box',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
});

const StyledBoxHeading = styled('p', {
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '24.63px',
  lineHeight: '32px',
  color: '#002677',
  margin: '0',
});

const InpContainer = styled('div', {
  padding: '10px',
  width: '100%',
  '.label': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '0',
  },
});

const IdBox = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '3px',
  paddingBottom: '3px',
  paddingLeft: '10px',
  background: '#ffffff',
  border: '1px solid #000000',
  boxSizing: 'border-box',
  borderRadius: '4px',

  p: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#333333',
    marginBottom: '0',
  },
});

const NonData = styled('div', {
  padding: '20px 10px 10px',
  p: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#000000',
  },
});

export const OAuthToken = ({ oauthToken, heading, showData, description }) => {
  const { onCopy } = useCopy(oauthToken);
  const copyEvent = () => {
    const interactionName = 'oauth token';
    const interactionValue = `${heading} oauth token: ${oauthToken}`;
    const interactionContext = 'copy code';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };
  return (
    <Wrapper>
      <StyledBoxHeading id={`oAuth-heading-${heading}`}>
        {heading}
      </StyledBoxHeading>
      {showData ? (
        <React.Fragment>
          <InpContainer id={`oAuth-container-${heading}`}>
            <p id={`oAuth-Token-URL-${heading}`} className="label">
              OAuth Token URL
            </p>
            <IdBox id={`oAuth-id-box-${heading}`}>
              <p id={`${heading}-token`}>{oauthToken}</p>
              <Button
                id={`${heading}-oAuth-button`}
                size="$sm"
                onClick={() => {
                  onCopy();
                  copyEvent();
                }}
                variant="outline"
                css={{
                  'abyss-button-root': {
                    border: 'none',
                  },
                }}
              >
                <IconMaterial icon="content_copy" color="$primary1" />
              </Button>
            </IdBox>
          </InpContainer>
        </React.Fragment>
      ) : (
        <NonData>
          <p id={`non-data-description-${heading}`}>{description}</p>
        </NonData>
      )}
    </Wrapper>
  );
};

OAuthToken.propTypes = {
  oauthToken: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showData: PropTypes.bool.isRequired,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { useAbyssProps } from '@abyss/web/hooks/useAbyssProps';
import { CardContext } from '../CardContext';

const CardSectionStyle = styled('div', {
  padding: '$md',
  lineHeight: '16px',
  fontWeight: '$medium',
  '& + &': {
    borderTop: '1px solid $gray4',
  },
  variants: {
    size: {
      small: {
        fontSize: '$sm',
      },
      medium: {
        fontSize: '$md',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export const CardSection = ({ children, ...props }) => {
  if (!children) {
    return null;
  }

  const abyssProps = useAbyssProps(props);
  const { size } = useContext(CardContext);

  return (
    <CardSectionStyle {...props} {...abyssProps('card-section')} size={size}>
      {children}
    </CardSectionStyle>
  );
};

CardSection.displayName = '@abyss/web/ui/Card.Section';

CardSection.propTypes = {
  children: PropTypes.node,
};

CardSection.defaultProps = {
  children: null,
};

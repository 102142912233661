export const styles = `
  h1,h2,h3{
    font-family: UHCSerif, Georgia Bold, serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: lighter !important;
    }
  body {
    font-family: AbyssEnterpriseSansVF, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: 400;
    font-weight: lighter !important;
    overflow-x: hidden;


  }
  html {
    overflow-x: hidden;
  }
  
`;

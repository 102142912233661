import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import { useParams } from '@abyss/ui/router/hooks/useParams';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { LagoonStyledButton } from 'src/common/LagoonStyledButton';
import { styled, globalCss } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useDSUserInfo } from 'src/hooks/useDSUserInfo';
import { SubscribeToAPIModal } from '../../../SubscribeToAPIModal/SubscribeToAPIModal';
import { constants } from '../../../../../../common/Utils/Constants';

const Wrapper = styled('div', {});

const PageSecondaryHeading = styled('h2', {
  color: '#002677',
  marginBottom: '20px !important',
  lineHeight: '29px !important',
  fontSize: '24px ! important',
});

const PageSecondaySubHeading = styled('h3', {
  color: '#002677',
  marginBottom: '20px',
  fontSize: '20px ! important',
});

const PageDesc = styled('p', {
  color: constants.FONT_COLOR_BLACK,
  fontSize: '15px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '20px',
  marginBottom: '20px',
  whiteSpace: 'pre-line',
});

const OnThisPageResponsiveDiv = styled('div', {
  marginBottom: '40px',
  position: 'sticky',

  '@screen >= $xs': {
    width: '269px',
    height: '40px',
  },

  '@screen >= $sm': {
    width: '288px',
    height: '40px',
  },

  '@screen >= $lg': {
    display: 'none',
  },

  '@screen >= $xl': {
    display: 'none',
  },
});

const BulletedList = styled('ul', {
  listStyleType: 'revert',
  marginBottom: '20px',
  color: constants.FONT_COLOR_BLACK,
});

const NumberedList = styled('ol', {
  listStyleType: 'decimal',
  marginBottom: '20px',
  color: constants.FONT_COLOR_BLACK,
});

const ListFont = styled('li', {
  marginLeft: '35px',
  fontSize: '15px',
  fontWeight: 'normal',
  marginBottom: '10px',
  color: constants.FONT_COLOR_BLACK,
});

const SelectInputStyles = globalCss({
  '.abyss-select-input-option-content': {
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '18px !important',
    color: '#196ECF !important',
  },
  '.abyss-select-input-input': {
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
    color: '#000000 !important',
  },
});

export const PageContent = ({ content, displayDropDown, pageHeading }) => {
  const [headings, setHeadings] = useState([]);
  const [dsUserInfo] = useDSUserInfo();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const { resource } = useParams();
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);

  const getHeadings = () => {
    const elements = Array.from(document.querySelectorAll('h2'))
      .filter(element => !element.id.includes('radix'))
      .map(element => ({
        id: element.id,
        text: element.textContent ?? '',
      }));
    setHeadings(elements);
  };

  useEffect(() => {
    getHeadings();
  }, []);

  useEffect(() => {
    getHeadings();

    if (
      !dsUserInfo.loading &&
      (dsUserInfo.data.uuid || dsUserInfo.data.status === 'notprovisioned')
    ) {
      event('NEW_PAGE_LOAD', {
        pageName: `apimarketplace:welcome:knowledge-base:${resource}`,
        siteSectionL1: 'welcome',
        siteSectionL2: 'knowledge-base',
        siteSectionL3: resource,
        dsUserInfo: dsUserInfo.data,
        userInfo,
      });
    }
  }, [resource, dsUserInfo]);

  const getLeftNavData = () => {
    const dataList = headings.map(element => {
      const dataElement = {
        label: element.text,
        value: element.id,
      };
      return dataElement;
    });
    return dataList;
  };

  const getSubheaderView = id => {
    if (id) {
      const element = document.getElementById(id);
      element.scrollIntoView();
    }
  };

  SelectInputStyles();

  return (
    <Wrapper>
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      {displayDropDown ? (
        <OnThisPageResponsiveDiv>
          <SelectInputStyles />
          <SelectInput
            label=""
            placeholder="ON THIS PAGE"
            onChange={getSubheaderView}
            options={getLeftNavData()}
            validators={{ required: true }}
          />
        </OnThisPageResponsiveDiv>
      ) : null}
      {content && (
        <Markdown
          options={{
            overrides: {
              h2: PageSecondaryHeading,
              h3: PageSecondaySubHeading,
              p: PageDesc,
              a: {
                component: LagoonStyledButton,
                props: { setShowSubscribeAPIModal, pageHeading },
              },
              ul: BulletedList,
              ol: NumberedList,
              li: ListFont,
            },
          }}
        >
          {content}
        </Markdown>
      )}
    </Wrapper>
  );
};

PageContent.propTypes = {
  content: PropTypes.string.isRequired,
  displayDropDown: PropTypes.bool.isRequired,
  pageHeading: PropTypes.string.isRequired,
};

import React, { useState } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';
import { Button } from '@abyss/web/ui/Button';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';
import { SubscribeToAPIModal } from '../../../SubscribeToAPIModal';
import { StartRegistrationButton } from '../../../StartRegistrationButton';
import { CardComponentWrapper } from './CardComponentWrapper';
import { constants } from '../../../../../../common/Utils/Constants';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

const BoxWrapper = styled('div', {
  display: 'flex',
  marginTop: '27px',

  '@screen >= $xs': {
    flexDirection: 'column',
    gap: '16px',
  },

  '@screen >= $xl': {
    flexDirection: 'row',
    gap: '93px',
  },
});

export const ConnectToOrganization = () => {
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);

  const ScheduleMeetingTitle = 'To get started, meet with an API Consultant';
  const ScheduleMeetingSection =
    'Meeting with an API consultant is your first step to discuss, request, or implement API services.';
  const StartRegistrationTitle = ' Register for API Marketplace';

  const StartRegistrationSection = (
    <div>
      Register to proceed with API implementation or to add new users from your
      organization. Your organizations Tax ID is required. View the{' '}
      <Link
        href={config('REGISTRATION_STEPS')}
        fontWeight="700"
        size="15"
        onClick={() => {
          const fileName = 'API Marketplace Registration Guide';
          const fileURL = config('REGISTRATION_STEPS');
          const fileContext = 'self-paced chameleon guide';
          event('DOWNLOAD_CLICK', {
            fileName,
            fileURL,
            fileContext,
          });
        }}
      >
        API Marketplace Registration Guide
      </Link>
      &nbsp; for more information.
    </div>
  );

  const subscribeClicked = () => {
    setShowSubscribeAPIModal(true);
    const interactionValue = 'contact us';
    const interactionContext = 'page cta link clicks';
    const interactionName = 'apimarketplace:welcome';
    const jobName = constants.SUB_TO_API_JOB_NAME;
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    event('SUBSCRIBE_EVENT', {
      event,
      jobName,
    });
  };

  const ScheduleMeetingBtn = (
    <Button onClick={subscribeClicked} size="$lg" id="SubscribeToAPI">
      Schedule a meeting
    </Button>
  );

  const StartRegistrationBtn = (
    <StartRegistrationButton id="register-button" size="lg">
      Start registration
    </StartRegistrationButton>
  );

  return (
    <Container>
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <BoxWrapper>
        <CardComponentWrapper
          title={ScheduleMeetingTitle}
          section={ScheduleMeetingSection}
          button={ScheduleMeetingBtn}
        />
        <CardComponentWrapper
          title={StartRegistrationTitle}
          section={StartRegistrationSection}
          button={StartRegistrationBtn}
        />
      </BoxWrapper>
    </Container>
  );
};

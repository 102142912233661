import apiMapping from './apiMapping.json';

export const parseDSUserInfo = request => {
  const filteredOrg = request?.org?.filter(el => el.userStatus !== 'INACTIVE');
  const selectedOrg =
    filteredOrg?.length > 0 ? filteredOrg[0] : request?.org?.[0];
  const requestedApis = selectedOrg?.apiRequested;
  const stageApis = [];
  const prodApis = [];
  const orgId = selectedOrg?.orgId;
  const orgName = selectedOrg?.orgName;
  const isAdmin = selectedOrg?.isAdmin;
  const orgType = selectedOrg?.orgType;
  const uuid = request?.uuid;
  const isInternalUser = request?.isInternalUser;
  const jobFunction = request?.jobFunction;

  const jobFunctionCodes = jobFunction?.map(data => data.code);

  if (
    selectedOrg?.userStatus === 'ACTIVE' &&
    requestedApis &&
    requestedApis.length
  ) {
    requestedApis.forEach(el => {
      const statusEnv = el?.status?.split('-');
      if (statusEnv?.[0].trim().includes('Active')) {
        if (statusEnv?.[1].trim().includes('Prod')) {
          prodApis.push(...apiMapping[el.api]);
          stageApis.push(...apiMapping[el.api]);
        } else {
          stageApis.push(...apiMapping[el.api]);
        }
      }
    });
  }

  const linkedApis = {
    prod: prodApis,
    stage: stageApis,
  };

  const status =
    request?.error || !selectedOrg ? 'notprovisioned' : selectedOrg?.userStatus;

  let data = {
    orgId,
    orgName,
    orgType,
    status,
    linkedApis,
    isAdmin,
    uuid,
  };

  if (isInternalUser && jobFunctionCodes?.length > 0) {
    data = {
      ...data,
      isInternalUser,
      jobFunctionCodes,
    };
  }

  return data;
};

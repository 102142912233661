import { convertCurl } from './converters/convertCurl';
import { convertJavascript } from './converters/convertJavascript';
import { convertHttp } from './converters/convertHttp';
import { convertJava } from './converters/convertJava';
import { convertCSharp } from './converters/convertCSharp';
import { convertPhp } from './converters/convertPhp';
import { convertNode } from './converters/convertNode';
import { convertPython } from './converters/convertPython';

const getSearchParams = query => {
  let paramsObj = {};
  query.forEach(item => {
    if (item.value) {
      paramsObj = { ...paramsObj, [item.key]: item.value };
    }
  });
  return new URLSearchParams(paramsObj);
};

export const propsToCode = ({
  url,
  language,
  method,
  query,
  headers,
  body,
  formData,
}) => {
  const requestMethod = method.toUpperCase();
  const requestQuery = getSearchParams(query);

  const options = {
    url: requestQuery ? `${url}?${requestQuery}` : url,
    method: requestMethod,
    query: requestQuery,
    headers,
    body,
    formData,
  };

  if (language === 'curl') {
    return convertCurl(options);
  }
  if (language === 'javascript') {
    return convertJavascript(options);
  }
  if (language === 'http') {
    return convertHttp(options);
  }
  if (language === 'java') {
    return convertJava(options);
  }
  if (language === 'csharp') {
    return convertCSharp(options);
  }
  if (language === 'php') {
    return convertPhp(options);
  }
  if (language === 'nodejs') {
    return convertNode(options);
  }
  if (language === 'python') {
    return convertPython(options);
  }
  return '';
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { Alert } from '@abyss/web/ui/Alert';
import { PageLayout } from 'src/common/PageLayout';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { useUserStatus } from 'src/hooks/useUserStatus';
import { useDSUserInfo } from 'src/hooks/useDSUserInfo';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { resetSecertAlertData } from 'src/hooks/useRegenerateSecret';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { Box } from './Box';
import { RegenerateSecretModal } from './RegenerateSecretModal/RegenerateSecretModal';
import { OAuthToken } from './OAuthToken';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },
  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
});

const Container = styled('div', {
  flexBasis: '100%',
});

const PageHeadingContainer = styled('div', {
  display: 'flex',
  marginTop: '8px',
  marginBottom: '32px',
  flexBasis: '50%',
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px !important',
  fontWeight: '600',
  minHeight: '40px',
  lineHeight: '40px',
  marginTop: '8px',
});

const PageDesc = styled('div', {
  color: 'rgb(51, 51, 51)',
  fontSize: '16px',
  fontWeight: 500,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '16px',
});

const Flexed = styled('div', {
  '@screen >=$xs': {
    display: 'block',
  },
  '@screen >=$xl': {
    display: 'flex',
  },
});

const BoxWrapper = styled('div', {
  display: 'flex',
  marginTop: '27px',

  '@screen >= $xs': {
    flexDirection: 'column',
    gap: '16px',
  },

  '@screen >= $xl': {
    flexDirection: 'row',
    gap: '93px',
  },
});

export const Credentials = () => {
  const regeneratedSecret = useSelector(state => state.GET_REGENERATED_SECRET);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const [userStatus, getUserStatus] = useUserStatus();
  const [dsUserInfo, getDSUserInfo] = useDSUserInfo();
  const [regenSecretModalOpen, setRegenSecretModalOpen] = useState(false);
  const [regenSecretEnv, setRegenSecretEnv] = useState('stage');
  const [isLoading, setIsLoading] = useState(true);
  const [, getResetSecertAlertData] = resetSecertAlertData();
  const [runCredentials, setRunCredentials] = useState(false);
  const [isPageLoadTriggered, setIsPageLoadTriggered] = useState(false);

  const showCredentials =
    dsUserInfo?.data?.status && dsUserInfo?.data?.status === 'ACTIVE';

  useEffect(
    () => () => {
      getResetSecertAlertData();
    },
    []
  );

  useEffect(() => {
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }

    const userInfoNotAvailable = !dsUserInfo?.data?.uuid;

    if (userInfoNotAvailable) {
      getDSUserInfo({ userInfo });
    } else {
      setRunCredentials(false);
    }
  }, []);

  useEffect(() => {
    if (runCredentials && !userStatus?.data?.userid) {
      const orgId = dsUserInfo?.data?.orgId;
      getUserStatus({ userInfo, orgId });
    }
  }, [runCredentials]);

  useEffect(() => {
    if (dsUserInfo?.data?.uuid) {
      setRunCredentials(true);
    }

    if (
      (dsUserInfo?.data?.status && userStatus.data.credentials) ||
      userStatus.error
    ) {
      setIsLoading(false);
    }

    if (
      !dsUserInfo.loading &&
      dsUserInfo.data.uuid !== '' &&
      !isPageLoadTriggered
    ) {
      setIsPageLoadTriggered(true);
      event('NEW_PAGE_LOAD', {
        pageName: 'apimarketplace:welcome:credentials',
        siteSectionL1: 'welcome',
        siteSectionL2: 'credentials',
        dsUserInfo: dsUserInfo.data,
        userInfo,
      });
    }
  }, [dsUserInfo, userStatus]);

  const closeModal = () => {
    setRegenSecretModalOpen(false);
  };

  const getClientId = () => {
    if (regenSecretEnv === 'prod') {
      return userStatus?.data?.credentials?.prod?.clientId;
    }

    return userStatus?.data?.credentials?.stage?.clientId;
  };

  const getProdBoolean = () => {
    return regenSecretEnv === 'prod';
  };

  return (
    <PageLayout>
      <LoadingOverlay
        loadingMessage="Please wait while we retrieve your data."
        ariaLoadingLabel="Example loading aria label"
        isOpen={isLoading || userStatus.error}
        isLoading={isLoading}
        statusTitle="Error Fetching Credentials"
        statusMessage="Please try to refresh the page again."
        statusIcon="error"
        width="60%"
      >
        <RegenerateSecretModal
          isOpen={regenSecretModalOpen}
          closeModal={closeModal}
          clientId={getClientId()}
          regenerateEnv={regenSecretEnv}
          isProd={getProdBoolean()}
        />
        <Wrapper>
          <If
            condition={
              regeneratedSecret?.data?.alertData?.faultCode === 'AAMPCBK'
            }
          >
            <Alert
              title="Secret Regeneration Error"
              variant="error"
              inlineText={regeneratedSecret?.data?.alertData?.message}
            />
          </If>
          <StyledFlex>
            <Container>
              <Breadcrumbs />
              <Flexed>
                <PageHeadingContainer>
                  <PageHeading id="credentials-heading">
                    Credentials
                  </PageHeading>
                </PageHeadingContainer>
              </Flexed>
              <PageDesc id="credentials-description">
                You can use your client ID and secret for your APIs. You will
                have a separate set of these credentials for both your Stage and
                Production environments. You will not be able to regenerate a
                new secret until 15 minutes after you have generated your old
                one.{' '}
              </PageDesc>
              <BoxWrapper id="credentials-wrappper">
                <Box
                  clientId={
                    showCredentials
                      ? userStatus?.data?.credentials?.stage?.clientId
                      : ''
                  }
                  createdTs={
                    regeneratedSecret?.data?.stage?.updatedTs
                      ? `${regeneratedSecret?.data?.stage?.updatedTs}`
                      : `${userStatus?.data?.credentials?.stage?.createdTs}`
                  }
                  createdBy={
                    regeneratedSecret?.data?.stage?.updatedBy
                      ? regeneratedSecret?.data?.stage?.updatedBy
                      : userStatus?.data?.credentials?.stage?.createdBy
                  }
                  expiryDate={
                    regeneratedSecret?.data?.stage?.endDateTime
                      ? regeneratedSecret?.data?.stage?.endDateTime
                      : userStatus?.data?.credentials?.stage?.endDateTime
                  }
                  clientSecret={regeneratedSecret?.data?.stage?.secretValue}
                  heading="Staging"
                  showData={dsUserInfo?.data?.linkedApis?.stage?.length}
                  isSecretKeeper={showCredentials && dsUserInfo?.data?.isAdmin}
                  description="Staging credentials will be available once staging onboarding
                process is complete."
                  regenSecret={setRegenSecretModalOpen}
                  setRegenSecretEnv={setRegenSecretEnv}
                  isSecretGenerated={
                    userStatus?.data?.credentials?.stage?.isSecretGenerated
                  }
                />
                <Box
                  clientId={
                    showCredentials
                      ? userStatus?.data?.credentials?.prod?.clientId
                      : ''
                  }
                  createdTs={
                    regeneratedSecret?.data?.prod?.updatedTs
                      ? `${regeneratedSecret?.data?.prod?.updatedTs}`
                      : `${userStatus?.data?.credentials?.prod?.createdTs}`
                  }
                  createdBy={
                    regeneratedSecret?.data?.prod?.updatedBy
                      ? regeneratedSecret?.data?.prod?.updatedBy
                      : userStatus?.data?.credentials?.prod?.createdBy
                  }
                  expiryDate={
                    regeneratedSecret?.data?.prod?.endDateTime
                      ? regeneratedSecret?.data?.prod?.endDateTime
                      : userStatus?.data?.credentials?.prod?.endDateTime
                  }
                  clientSecret={regeneratedSecret?.data?.prod?.secretValue}
                  heading="Production"
                  showData={dsUserInfo?.data?.linkedApis?.prod?.length}
                  isSecretKeeper={showCredentials && dsUserInfo?.data?.isAdmin}
                  description="Production credentials will be available once staging onboarding
                process is complete."
                  regenSecret={setRegenSecretModalOpen}
                  setRegenSecretEnv={setRegenSecretEnv}
                  isSecretGenerated={
                    userStatus?.data?.credentials?.prod?.isSecretGenerated
                  }
                />
              </BoxWrapper>
            </Container>
          </StyledFlex>
          <br />
          <br />
          <StyledFlex>
            <Container>
              <Flexed>
                <PageHeadingContainer>
                  <PageHeading id="oAuth-token-header">OAuth Token</PageHeading>
                </PageHeadingContainer>
              </Flexed>
              <PageDesc id="oAuth-Token-description">
                Generate your OAuth Token in different environments using the
                gateway URLs below.{' '}
              </PageDesc>
              <BoxWrapper id="oAuth-Token-wrapper">
                <OAuthToken
                  oauthToken={config('OAUTH_TOKEN_URL_STAGE')}
                  heading="Staging"
                  showData={dsUserInfo?.data?.linkedApis?.stage?.length}
                  description=""
                />
                <OAuthToken
                  oauthToken={config('OAUTH_TOKEN_URL_PROD')}
                  heading="Production"
                  showData={dsUserInfo?.data?.linkedApis?.prod?.length}
                  description="Production gateway URL will be available once staging onboarding
                process is complete."
                />
              </BoxWrapper>
            </Container>
          </StyledFlex>
        </Wrapper>
      </LoadingOverlay>
    </PageLayout>
  );
};

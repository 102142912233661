import React, { useEffect, useState } from 'react';
import { useParams } from '@abyss/ui/router/hooks/useParams';
import { Link } from '@abyss/web/ui/Link';
import { styled } from '@abyss/web/tools/styled';

const Wrapper = styled('div', {
  position: 'sticky',
  top: '24px',
  overflow: 'auto',
  paddingLeft: '30px',

  '@screen >= lg': {
    display: 'block',
  },
});

const Heading = styled('div', {
  color: '#5a5a5a',
  fontSize: '13px',
  fontWeight: '700',
  lineHeight: '16px',
  textAlign: 'left',
  textTransform: 'uppercase',
  margin: '8px 0px',
});

const StyledNavLink = styled('div', {
  display: 'block',
  marginBottom: '8px',
  'abyss-link-root': {
    fontSize: '15px !important',
  },
});

const StyledListItem = styled('li', {
  paddingLeft: '12px',
});

const StyledLink = styled(Link, {});

export const TableOfContents = () => {
  const [headings, setHeadings] = useState([]);
  const [subHeadings, setSubHeadings] = useState([]);
  const { resource, type } = useParams();

  const getSubheaderView = id => {
    const element = document.getElementById(id);
    element.scrollIntoView();
  };

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll('div.tableof'))
      .filter(element => element.id)
      .map(element => ({
        id: element.id,
        text: element.textContent ?? '',
      }));
    setHeadings(elements);
    const elementsSub = Array.from(document.querySelectorAll('div.elementof'))
      .filter(element => element.id)
      .map(element => ({
        id: element.id,
        text: element.textContent ?? '',
      }));
    setSubHeadings(elementsSub);
  }, [resource, type]);

  return (
    <Wrapper>
      <Heading>ON THIS PAGE</Heading>
      <For each="topic" of={headings}>
        <StyledNavLink>
          <StyledLink
            size="$md"
            css={{ fontSize: '15px !important', fontWeight: 400 }}
            onClick={() => {
              getSubheaderView(topic.id);
            }}
          >
            {topic.text}
          </StyledLink>
          <ul>
            <For
              each="subheading"
              of={subHeadings.filter(element => element.id.includes(topic.id))}
            >
              <StyledListItem>
                <StyledLink
                  size="$md"
                  css={{ fontSize: '15px !important', fontWeight: 400 }}
                  onClick={() => {
                    getSubheaderView(subheading.id);
                  }}
                >
                  {subheading.text}
                </StyledLink>
              </StyledListItem>
            </For>
          </ul>
        </StyledNavLink>
      </For>
    </Wrapper>
  );
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'CLAIM_SUMMARY';

export const claimSummaryMock = () => {
  const action = useAction(data => {
    return dispatch => {
      dispatch({
        type: `${ACTION_TYPE}_MOCK`,
        payload: data,
      });
    };
  });

  return [action];
};

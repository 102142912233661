import React, { useEffect, useState } from 'react';
import { APICardWithIcon } from 'src/common/APICard';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { cards } from './_resourcesCard';

const Wrapper = styled('div', {
  margin: '64px 0 0px 0',
});

const Heading = styled('h3', {
  color: '#002677',
  width: '624px',
  height: '32px',
  left: '0px',
  top: '1318px',
  fontStyle: 'normal',
  fontWeight: '600 !important',
  fontSize: '28px ! important',
  lineHeight: '32px',
});

const Description = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '24px',
  letterSpacing: '0px',
  textAlign: 'left',
  left: '0px',
  marginTop: '15px',
});

const CategoryBox = styled('div', {
  display: 'flex',
  gap: '15px',

  '@screen >= $xs': {
    display: 'block',
  },
  '@screen >= $xl': {
    display: 'flex',
    gap: '15px',
  },
});

export const Resources = () => {
  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    const dataAc = [...cards].sort((a, b) => (a.title > b.title ? 1 : -1));
    setFilteredCards([dataAc[0], dataAc[1], dataAc[2]]);
  }, []);

  return (
    <Wrapper>
      <Heading id="resources-heading">Resources</Heading>
      <Description id="resources-description">
        Utilize these resources to make the best of your API Marketplace
        experience.
      </Description>
      <CategoryBox>
        {filteredCards.map((el, idx) => {
          return (
            <APICardWithIcon
              key={el.key}
              iconName={el.iconName}
              title={el.title}
              description={el.description}
              link={el.link}
              linkLabel={el.linkLabel}
              index={idx}
              onClick={() => {
                const interactionName = el.title;
                const interactionValue = el.linkLabel;
                const interactionContext = 'tile cta clicks';
                event('INTERACTION_EVENT', {
                  interactionName,
                  interactionValue,
                  interactionContext,
                });
              }}
            />
          );
        })}
      </CategoryBox>
    </Wrapper>
  );
};

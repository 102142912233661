import React, { useEffect } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { useSelector } from 'react-redux';
import { styled } from '@abyss/web/tools/styled';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { OrganizationTracker } from './OrganizationTracker';
import { OrganizationList } from './OrganizationList';
import { ConnectToOrganization } from './ConnectToOrganization/ConnectToOrganization';
import { useApiHealthCheck } from '../../../../../hooks/useApiHealthCheck';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { DeniedConnectToOrganization } from './DeniedConnectToOrg';

const Wrapper = styled('div', {
  marginTop: '32px',
});

export const OrganizationApi = () => {
  const [dsUserInfo, getDSUserInfo] = useDSUserInfo();
  const [apiHealthCheck, getApiHealthCheck] = useApiHealthCheck();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const userStatus = dsUserInfo?.data?.status;

  useEffect(() => {
    if (userInfo.uuid !== '') {
      getDSUserInfo({ userInfo });
      getApiHealthCheck({ userInfo });
    }
  }, [userInfo]);

  const getDisplayComponent = () => {
    switch (userStatus) {
      case 'ACTIVE':
        return <OrganizationList orgUserInfo={dsUserInfo.data} />;
      case 'PENDING':
      case 'DENIED':
        return (
          <Card>
            <Card.Section>
              {userStatus === 'PENDING' ? (
                <OrganizationTracker currentStep={2} />
              ) : (
                <DeniedConnectToOrganization />
              )}
            </Card.Section>
          </Card>
        );
      default:
        return <ConnectToOrganization />;
    }
  };

  return (
    <Wrapper>
      <LoadingOverlay
        loadingTitle="Please Wait"
        loadingMessage="Please wait while we retrieve your data."
        ariaLoadingLabel="Example loading aria label"
        isLoading={apiHealthCheck.loading || dsUserInfo.loading}
        width="40%"
      >
        {getDisplayComponent()}
      </LoadingOverlay>
    </Wrapper>
  );
};

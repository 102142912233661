import { config } from '@abyss/ui/tools/config';

export const checkInternalRole = dsUserData => {
  const { isInternalUser, jobFunctionCodes } = dsUserData || {};
  if (isInternalUser) {
    const hasRoleAccess = jobFunctionCodes?.filter(
      element => element === config('CC_INTERNAL_ROLE_IDS')
    );
    if (hasRoleAccess && hasRoleAccess.length > 0) {
      return true;
    }
  }

  return false;
};

export const leftNavMap = (leftNavContent, leftHeaderItems) => {
  const content = leftNavContent?.map(el => {
    const leftItems = leftHeaderItems?.filter(
      item => item.active && item.tagId === el.tagId
    );
    return {
      ...el,
      paths: leftItems,
    };
  });
  return content;
};

export const getLeftNavData = (dsUserInfo, leftNavContent, leftHeaderItems) => {
  const hasInternalRole = checkInternalRole(dsUserInfo);
  const apiStageAccess = dsUserInfo?.linkedApis?.stage;
  const KnowledgeBaseAccess =
    dsUserInfo?.status && dsUserInfo?.status === 'ACTIVE';
  const ignoreTags = ['intro', 'getting-started'];
  const pathsData = leftNavMap(leftNavContent, leftHeaderItems);
  const dataList = pathsData?.map(element => {
    if (element.active && !ignoreTags.includes(element.tagId)) {
      if (
        (KnowledgeBaseAccess &&
          apiStageAccess &&
          apiStageAccess.includes(element.tagId)) ||
        hasInternalRole
      ) {
        const dataElement = {
          ...element,
          accessible: true,
          accessibleToAll: true,
        };
        return dataElement;
      }
    }
    if (element?.tagId === 'getting-started') {
      const sortedElement = { ...element };
      const videoMediaPathsList = sortedElement?.paths;
      const gettingStartedPath = videoMediaPathsList?.shift();
      videoMediaPathsList.sort((a, b) => a.id.localeCompare(b.id));
      const sortedVideoPathList = [gettingStartedPath, ...videoMediaPathsList];
      sortedElement.paths = sortedVideoPathList;
      return sortedElement;
    }
    return element;
  });

  return dataList;
};

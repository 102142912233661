import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_GITHUB_REPOS';

export const useGitHubRepos = options => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const { variables } = args;
    const { username } = variables;

    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('GITHUB_API_URL')}/users/${username}/repos`)
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: res,
          });
          if (options && options.onCompleted) {
            options.onCompleted({
              ...res,
              login: username,
            });
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          if (options && options.onError) {
            options.onError(error);
          }
        });
    };
  });

  return [result, action];
};

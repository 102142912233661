import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'PATCH_TIN_MANAGEMENT_API';

export const resetPatchTinManagement = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

export const usePatchTinApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const { tins = [] } = args;
    const eapm = window.elasticApm;
    if (eapm) {
      window.elasticApm.startTransaction(ACTION_TYPE, 'http-request', {
        managed: true,
      });
    }
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('APIM_STARGATE_URL')}${config('TIN_MANAGEMENT_API')}`, {
        withCredentials: 'include',
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tins }),
      })
        .then(res => {
          event('API_TRANSACTION', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('TIN_MANAGEMENT_API'),
              userInfo: args?.userInfo,
              method: 'PUT',
              code: res.status,
              operationName: ACTION_TYPE,
              message: res.statusText,
              // result: hasReportCode ? 'success' : 'error',
            },
          });

          event('NEWRELIC_API_TRANSACTION', {
            code: res.status,
            message: res.statusText,
            endpoint: config('TIN_MANAGEMENT_API'),
            userInfo: args?.userInfo,
            method: 'PUT',
          });
          return res.status;
        })
        .then(res => {
          const statusJson = { status: res };
          if (res !== 200) {
            dispatch({
              type: `${ACTION_TYPE}_ERROR`,
              payload: statusJson,
            });
          } else {
            dispatch({
              type: `${ACTION_TYPE}_COMPLETED`,
              payload: statusJson,
            });
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          event('API_TRANSACTION_ERROR', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('TIN_MANAGEMENT_API'),
              userInfo: args?.userInfo,
              method: 'PUT',
              operationName: ACTION_TYPE,
              // code: res.status,
              // message: res.statusText,
            },
          });
        });
    };
  });

  return [result, action];
};

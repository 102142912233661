export const baseStyles = theme => {
  return {
    icon: ({ isOpen }) => {
      return {
        position: 'relative',
        width: 34,
        height: 34,
        borderRadius: '50%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.UHC_BLUE,
        color: theme.colors.UHC_BLUE,
        transform: isOpen ? '' : 'rotate(180deg)',
        transitionProperty: 'transform',
        transitionDuration: 300,

        '&:hover': {
          cursor: 'pointer',
        },

        '&:before, &:after': {
          position: 'absolute',
          content: '""',
          backgroundColor: theme.colors.UHC_BLUE,
          top: isOpen ? 8 : 10,
          width: 1,
          height: 12,
          transitionProperty: 'top',
          transitionDuration: 300,
        },

        '&:before': {
          transform: 'rotate(45deg)',
          left: 11,
        },

        '&:after': {
          transform: 'rotate(-45deg)',
          left: 19,
        },
      };
    },
  };
};

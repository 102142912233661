import { ACTION_TYPE } from './useGitHubUser';

const initialState = {
  data: {
    id: '',
    login: '',
    firstName: '',
    lastName: '',
    avatarUrl: '',
    publicRepos: 0,
    followers: 0,
    following: 0,
  },
  loading: false,
  error: false,
};

export const reducerGitHubUser = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_CALLED`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${ACTION_TYPE}_COMPLETED`:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case `${ACTION_TYPE}_ERROR`:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  },
};

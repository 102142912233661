import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Modal } from '@abyss/web/ui/Modal';
import { Layout } from '@abyss/web/ui/Layout';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { styled } from '@abyss/web/tools/styled';
import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { Tabs } from '@abyss/web/ui/Tabs';
import { CodeSnippet } from './CodeSnippet';
import { Response } from './Response';
import { ParameterTable } from './ParameterTable';
import { Body } from './Body';

const ResponseHeading = styled('h4', {
  marginBottom: '$sm',
  fontSize: '18px !important',
  fontWeight: 400,
});

const SandBoxContainer = styled('div', {
  position: 'relative',
  backgroundColor: '$white',
  [ResponseHeading]: {
    fontSize: '$xl',
  },
});

const ActionContainer = styled('div', {
  zIndex: 2,
  '@screen >= $xl': {
    position: 'absolute',
    right: 0,
    marginTop: 8,
  },
});

const CallContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  zIndex: 2,
});

const CallType = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid $gray6',
  backgroundColor: '$white',
  borderRadius: 4,
  padding: '0 $sm',
  fontWeight: '$bold',
  height: 38,
  minWidth: 80,
  textTransform: 'uppercase',
  variants: {
    variant: {
      get: {
        color: '$success1',
        borderColor: '$success1',
        backgroundColor: '$success2',
      },
      post: {
        color: '$info1',
        borderColor: '$info1',
        backgroundColor: '$info2',
      },
      put: {
        color: '$warning1',
        borderColor: '$warning1',
        backgroundColor: '$warning2',
      },
      delete: {
        color: '$error1',
        borderColor: '$error1',
        backgroundColor: '$error2',
      },
    },
  },
});

const UrlInputContainer = styled('div', {
  flexGrow: 1,
  margin: '0 $sm',
});

export const ServiceSandbox = ({
  url,
  method,
  parameters,
  responses,
  onSend,
  onSuccess,
  onError,
  currentApi,
}) => {
  const [serviceResponse, setServiceResponse] = useState(null);
  const [isCodeOpen, openCodeModal] = useState(false);
  const defaultResponse = responses[0] && responses[0].response;
  const form = useForm({
    defaultValues: { url, response: defaultResponse },
  });

  useEffect(() => {
    form.setValue('url', url);
  }, [url]);

  useEffect(() => {
    setServiceResponse(null);
  }, [url, method, parameters]);

  const responseOptions = useMemo(() => {
    const options = responses.map(res => {
      return {
        label: `${res.response} - ${res.description}`,
        value: res.response,
      };
    });

    return options;
  }, [responses]);

  const responseMap = useMemo(() => {
    return responses.reduce((obj, res) => {
      const mappedResponse = {
        ...res,
        query: [],
        header: [],
        body: [],
        formData: [],
      };

      const responseParams = res.parameters || {};
      parameters.forEach(item => {
        const mappedItem = { ...item };
        if (item.in) {
          const paramSet = responseParams[item.in];
          if (paramSet && paramSet[item.key]) {
            mappedItem.value = paramSet[item.key];
          }
          mappedResponse[item.in].push(mappedItem);
        }
      });

      return { ...obj, [res.response]: mappedResponse };
    }, {});
  }, [parameters, responses]);

  const selectedResponse = form.getValues('response');
  const responseData = responseMap[selectedResponse] || {
    query: [],
    header: [],
    body: [],
    formData: [],
    parameters: {},
  };

  const formDataProcess = (formDataObj, formData, key) => {
    if (Array.isArray(formData[key])) {
      formData[key].forEach(file => {
        formDataObj.append(key, file);
      });
    } else {
      formDataObj.append(key, formData[key]);
    }
  };

  const handleSend = () => {
    const inputValues = form.getValues();
    const responseValues = responseData.parameters;

    const query = { ...inputValues.query, ...responseValues.query };
    const header = { ...inputValues.header, ...responseValues.header };
    const body = inputValues.body || responseValues.body;
    const formData = { ...inputValues.formData, ...responseValues.formData };

    let formDataObj;
    if (formData) {
      formDataObj = new FormData();
      Object.keys(formData).forEach(key => {
        formDataProcess(formDataObj, formData, key);
        // if (Array.isArray(formData[key])) {
        //   formData[key].forEach(file => {
        //     formDataObj.append(key, file);
        //   });
        // } else {
        //   formDataObj.append(key, formData[key]);
        // }
      });
    }

    const options = {
      params: query,
      headers: header,
      responseType: 'arraybuffer',
      data: formDataObj || body,
    };

    setServiceResponse(null);

    if (onSend) {
      onSend(inputValues.url, options, res => {
        setServiceResponse(res);
      });
    } else {
      axios({ ...options, url: inputValues.url, method })
        .then(res => {
          setServiceResponse(res);
          if (onSuccess) {
            onSuccess(res);
          }
        })
        .catch(error => {
          const res = error ? error.response : {};
          setServiceResponse(res);
          if (onError) {
            onError(error);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <SandBoxContainer>
        <FormProvider state={form} onSubmit={handleSend}>
          <CallContainer>
            <CallType variant={method} css={{ fontSize: '15px' }}>
              {method}
            </CallType>
            <UrlInputContainer>
              <TextInput
                label="API URL"
                hideLabel
                width="auto"
                model="url"
                isReadOnly
              />
            </UrlInputContainer>
            <Button
              type="submit"
              fontSize="15px"
              css={{ width: 150, borderRadius: 4 }}
              size="38px"
            >
              Send Request
            </Button>
          </CallContainer>
          <ActionContainer>
            <Layout.Group alignItems="bottom">
              <SelectInput
                label={
                  <span style={{ fontSize: '15px' }}>Sample Responses</span>
                }
                placeholder="Response"
                width="150px"
                options={responseOptions}
                model="response"
              />
              <Button
                css={{ width: 150, borderRadius: 4 }}
                size="38px"
                fontSize="15px"
                variant="outline"
                onClick={() => {
                  openCodeModal(true);
                }}
              >
                Code Snippet
              </Button>
            </Layout.Group>
          </ActionContainer>
          <Tabs
            size="$sm"
            variant="line"
            grow={false}
            css={{
              'abyss-tabs-list': {
                border: 'none',
                minHeight: 40,
              },
              'abyss-tabs-content-container': {
                border: 'none',
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            {responseData.query.length > 0 && (
              <Tabs.Tab label="Query">
                <ParameterTable
                  title="Query Parameters"
                  parameters={responseData.query}
                  setValue={form.setValue}
                />
              </Tabs.Tab>
            )}
            {responseData.header.length > 0 && (
              <Tabs.Tab label="Headers">
                <ParameterTable
                  title="Headers"
                  parameters={responseData.header}
                  setValue={form.setValue}
                />
              </Tabs.Tab>
            )}
            {responseData.parameters.body && (
              <Tabs.Tab label="Body">
                <Body
                  body={responseData.parameters.body}
                  currentApi={currentApi}
                />
              </Tabs.Tab>
            )}
            {responseData.formData.length > 0 && (
              <Tabs.Tab label="Form Data">
                <ParameterTable
                  title="Form Data"
                  parameters={responseData.formData}
                  setValue={form.setValue}
                />
              </Tabs.Tab>
            )}
          </Tabs>
        </FormProvider>
        <ResponseHeading>Response:</ResponseHeading>
        <Response response={serviceResponse} />
      </SandBoxContainer>
      <Modal
        size="800px"
        title="Code Snippet"
        isOpen={isCodeOpen}
        onClose={() => {
          return openCodeModal(false);
        }}
      >
        <CodeSnippet
          url={url}
          method={method}
          query={responseData.query}
          headers={responseData.header}
          body={responseData.parameters.body}
          formData={responseData.formData}
        />
      </Modal>
    </React.Fragment>
  );
};

ServiceSandbox.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.oneOf(['get', 'post', 'put', 'delete']).isRequired,
  onSend: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      in: PropTypes.oneOf(['query', 'header', 'body', 'formData']),
      key: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  currentApi: PropTypes.shape({}),
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      response: PropTypes.string,
      description: PropTypes.string,
      parameters: PropTypes.shape({
        in: PropTypes.oneOf(['query', 'header', 'body', 'formData']),
        key: PropTypes.string,
        description: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string,
      }),
    })
  ),
};

ServiceSandbox.defaultProps = {
  onSend: null,
  onSuccess: null,
  currentApi: null,
  onError: null,
  parameters: [],
  responses: [],
};

import { event } from '@abyss/web/tools/event';
import { logger } from '@abyss/web/tools/logger';
import { config } from '@abyss/web/tools/config';
import {
  addElasticApmLabels,
  validateAndInitializeElasticApm,
} from './helpers';

export const elasticApmAnalytics = options => {
  if (options.enabled !== false) {
    const { hostname } = window.location;
    const eapmEnv = config('EAPM_ENV');
    const serverUrl =
      hostname === 'apimarketplace.uhcprovider.com'
        ? 'https://eapm-gw.uhc.com'
        : 'https://eapm-gw-nonprod.uhc.com';

    const initParams = {
      serverUrl,
      environment: `apim:${eapmEnv}`,
      serviceName: config('ELASTIC_APM_SERVICE_NAME'),
    };

    return {
      id: options.id,
      events: options.events,
      metadata: options.metadata,
      trigger: tag => {
        event.debounce(tag, 100, () => {
          if (options.logging) {
            logger.warn(`Elastic APM: ${tag.event}`, tag.data);
          }

          event.waitFor(
            () => {
              return validateAndInitializeElasticApm(initParams);
            },
            () => {
              addElasticApmLabels(tag);
            }
          );
        });
      },
    };
  }
  return {};
};

import React, { useEffect, useState } from 'react';
import { NavLink } from '@abyss/ui/router/NavLink';
import PropTypes from 'prop-types';
import { event } from '@abyss/web/tools/event';
import RightChevron from 'src/assets/arrow-icons/ic_keyboard_arrow_right.svg';
import LinkIcon from 'src/assets/Arrow-Right.svg';
import LeftChevron from 'src/assets/arrow-icons/ic_keyboard_arrow_left.svg';
import styles from './DesktopViewAPISlider.css';

export const DesktopViewAPISlider = ({ initialData }) => {
  const [relatedAPIs, setRelatedAPIs] = useState([]);
  const [indexesList, setIndexesList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [circleSelector, setCircleSelector] = useState(0);

  const slideFn = data => {
    if (initialData.length > 4) {
      const mainData = [...initialData];
      const nextMAin = data.map(el => {
        return mainData[el];
      });
      setRelatedAPIs(nextMAin);
      setCircleSelector(data[0]);
    } else {
      setRelatedAPIs(initialData);
    }
  };

  useEffect(() => {
    const oneIndexList = [];
    for (let i = 0; i < initialData.length; i++) {
      oneIndexList.push(i);
    }

    const indexes = [];
    for (let i = 1; i < 13; i++) {
      indexes.push(...oneIndexList);
    }

    slideFn(indexes.slice(indexes.length / 2, indexes.length / 2 + 4));
    setIndexesList(indexes);
    setSelectedIndex(indexes.length / 2);
  }, [initialData]);

  const slideHandler = type => {
    const newIndexesList = [...indexesList];
    let data;
    if (type === 'next') {
      data = newIndexesList.slice(selectedIndex + 1, selectedIndex + 5);
      setSelectedIndex(selectedIndex + 1);
    } else {
      data = newIndexesList.slice(selectedIndex - 1, selectedIndex + 3);
      setSelectedIndex(selectedIndex - 1);
    }
    slideFn(data);
  };

  const circleHandler = i => {
    if (i > circleSelector) {
      const count = i - circleSelector;
      const newSelectedIndex = selectedIndex + count;
      const finalData = [...indexesList].slice(
        newSelectedIndex,
        newSelectedIndex + 4
      );
      setSelectedIndex(newSelectedIndex);
      slideFn(finalData);
    } else if (i < circleSelector) {
      const count = circleSelector - i;
      const newSelectedIndex = selectedIndex - count;
      const finalData = [...indexesList].slice(
        newSelectedIndex,
        newSelectedIndex + 4
      );
      setSelectedIndex(newSelectedIndex);
      slideFn(finalData);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.headingRelatedAPI}>Related APIs</h4>
      <div className={styles.container}>
        {relatedAPIs.map((el, i) => {
          return (
            <div className={styles.apiItem}>
              <h3 className={styles.apiTitle}>{el.title}</h3>
              <div className={styles.linkBox}>
                <NavLink
                  to={el.link}
                  onClick={() => {
                    const interactionName = el.title;
                    const interactionValue = el.linkLabel;
                    const interactionContext = 'tile cta clicks';
                    event('INTERACTION_EVENT', {
                      interactionName,
                      interactionValue,
                      interactionContext,
                    });
                  }}
                >
                  {el.linkLabel}
                </NavLink>

                <img src={LinkIcon} alt="link icon" />
              </div>
            </div>
          );
        })}
        {initialData.length > 4 && (
          <input
            type="image"
            src={RightChevron}
            alt="next-image"
            onClick={() => slideHandler('next')}
            className={styles.nextSlide}
          />
        )}
        {initialData.length > 4 && (
          <input
            type="image"
            src={LeftChevron}
            alt="previous-image"
            onClick={() => slideHandler('prv')}
            className={styles.prvSlide}
          />
        )}
      </div>
      {initialData.length > 4 && (
        <div className={styles.indexCircles}>
          {initialData.map((el, i) => {
            const classes =
              i === circleSelector
                ? styles.selectedCircle
                : styles.nonSelectedCircle;
            return (
              <div
                className={classes}
                onClick={() => circleHandler(i)}
                onKeyPress={() => 'my key'}
                role="button"
                aria-label="test"
                tabIndex={i}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

DesktopViewAPISlider.propTypes = {
  initialData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      linkLabel: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

DesktopViewAPISlider.defaultProps = {
  initialData: [],
};

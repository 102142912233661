import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';

const StyledDiv = styled('div', {});

export const LeftNav = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
};

LeftNav.propTypes = {
  children: PropTypes.node.isRequired,
};

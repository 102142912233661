import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_USER_INFO';

export const resetUserInfo = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

export const useUserInfo = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('IDENTITY_API_URL')}/userinfo`, {
        credentials: 'include',
      })
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: res,
          });
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
        });
    };
  });

  return [result, action];
};

import { Accordion } from './Accordion';
import { AccordionPanel } from './AccordionPanel';
import { AccordionHeader } from './AccordionHeader';
import { AccordionContent } from './AccordionContent';

Accordion.Panel = AccordionPanel;
Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;

export { Accordion };

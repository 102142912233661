import React from 'react';
import { Link } from '@abyss/web/ui/Link';
import PropTypes from 'prop-types';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { saveAs } from 'file-saver';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { Card } from 'src/common/Card';
import { constants } from '../Utils/Constants';

const jobName = constants.SUB_TO_API_JOB_NAME;
const AnchorBox = styled('div', {
  display: 'flex',
  alignContent: 'center',
  marginBottom: '15px',
  marginTop: '19px',
  marginLeft: '8px',
});

const DownloadPadding = styled('div', {
  color: '#196ecf',
  fontWeight: 700,
  fontSize: '15px',
  lineHeight: '18px',
  marginRight: '10px',
  alignSelf: 'center',
});

const CategoryBox = styled('div', {
  display: 'flex',
  gap: '15px',

  '@screen >= $xs': {
    display: 'block',
  },
  '@screen >= $md': {
    display: 'flex',
    gap: '15px',
  },
  '@screen >= $xl': {
    display: 'flex',
    gap: '15px',
  },
});

const StyledLink = styled(Link, { fontSize: '15px !important' });

export const LagoonStyledButton = ({ children, href, title, ...props }) => {
  const interactionName = props?.pageHeading;
  const interactionValue = 'schedule a meeting';
  const interactionContext = 'page cta link clicks';

  const router = useRouter();
  const routeUrl = url => {
    router.push(url);
  };

  const downloadFile = (fileUrl, fileName) => {
    saveAs(fileUrl, fileName);
    const fileURL = fileUrl;
    const fileType = title?.split('.')?.[1];
    const fileContext = `${fileType} file`;

    event('DOWNLOAD_CLICK', {
      fileName,
      fileURL,
      fileContext,
    });
  };

  const fileName = title?.split('-')[1];

  const checkSwitch = param => {
    switch (param) {
      case 'link':
        return (
          <StyledLink
            id="custom-link"
            onClick={e => {
              e.preventDefault();
              routeUrl(href);
              event('INTERACTION_EVENT', {
                interactionName: 'knowledge base getting started',
                interactionValue: children[0],
                interactionContext: 'page cta link clicks',
              });
            }}
            {...props}
          >
            {children}
          </StyledLink>
        );
      case 'email':
      case /^(?:[a-z]+:)?\/\//i.test(href):
        return <StyledLink href={href}>{children}</StyledLink>;
      case 'guide':
        return (
          <StyledLink
            href={href}
            onClick={() => {
              const guideFileName = children;
              const fileURL = href;
              const fileContext = title;
              event('DOWNLOAD_CLICK', {
                fileName: guideFileName[0],
                fileURL,
                fileContext,
              });
            }}
          >
            {children}
          </StyledLink>
        );

      case 'Video':
        return (
          <CategoryBox>
            <Card>
              <Card.VideoSection
                id={children}
                maxBufferLength={30}
                maxMaxBufferLength={60}
                backBufferLength={30}
                pauseWhenNotInView
              >
                {href}
              </Card.VideoSection>
            </Card>
          </CategoryBox>
        );

      case 'Modal':
        return (
          <StyledLink
            onClick={() => {
              event('INTERACTION_EVENT', {
                interactionName,
                interactionValue,
                interactionContext,
              });
              event('SUBSCRIBE_EVENT', {
                event,
                jobName,
              });

              props.setShowSubscribeAPIModal(true);
            }}
          >
            {children}
          </StyledLink>
        );
      default:
        return (
          <AnchorBox>
            <DownloadPadding>
              <StyledLink
                fontWeight="$bold"
                onClick={() => {
                  downloadFile(href, fileName);
                }}
                after={<IconMaterial icon="download" size="20px" />}
              >
                {children}
              </StyledLink>
            </DownloadPadding>
          </AnchorBox>
        );
    }
  };

  return checkSwitch(title);
};

LagoonStyledButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
};

LagoonStyledButton.defaultProps = {
  title: null,
};

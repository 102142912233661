import { constants } from '../../../common/Utils/Constants';

export const relatedAPIsMock = [
  {
    title: 'Eligibility and Benefits (270/271)',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/eligibility-and-benefits-extended-x12-api',
  },

  {
    title: 'Claims',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/all-claims-api',
  },
  {
    title: 'Eligibility',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/eligibility-api',
  },
  {
    title: 'Eligibility (Lightweight)',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/eligibility-lightweight-api',
  },
  {
    title: 'Referrals',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/referral-graphQL-api',
  },
  {
    title: 'Provider Demographic',
    linkLabel: constants.VIEW_API_DETAILS,
    link: '/all-apis/provider-demographic-api',
  },
];

import React, { useState } from 'react';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',

  button: {
    focus: {
      boxShadow: 'none',
    },
  },
  '@screen >= $xs': {
    padding: '0px',
    paddingTop: '18px',
  },
  '@screen >= $xl': {
    padding: '32px 0px 0px 0px',
    paddingBottom: '0px',
  },
});

const Helper = styled('p', {
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#002677',
  margin: '0px',
  textAlign: 'center',
});

export const StarRating = ({ starCount, leftLabel, rightLabel, value }) => {
  const [rating, setRating] = useState(0);

  const ratingValue = data => {
    setRating(data);
    value(data);
  };

  return (
    <Wrapper>
      <Helper>{leftLabel}</Helper>
      {[...Array(starCount)].map((el, i) => {
        if (i < rating) {
          return (
            <button type="button" onClick={() => ratingValue(i + 1)}>
              {/* eslint-disable-next-line */}
              <IconMaterial icon="star" size="34px" color="$primary1" />
            </button>
          );
        }
        return (
          <button type="button" onClick={() => ratingValue(i + 1)}>
            {/* eslint-disable-next-line */}
            <IconMaterial icon="star_outline" size="34px" color="$primary1" />
          </button>
        );
      })}
      <Helper>{rightLabel}</Helper>
    </Wrapper>
  );
};

StarRating.propTypes = {
  starCount: PropTypes.number,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  value: PropTypes.func.isRequired,
};

StarRating.defaultProps = {
  starCount: 5,
  leftLabel: 'Not Likely',
  rightLabel: 'Extremely Likely',
};

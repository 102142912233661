import React from 'react';
import { AppContext } from 'src/app/App/AppProvider';
import { styled } from '@abyss/web/tools/styled';

const Wrapper = styled('div', {
  minHeight: '143px',
  background: 'rgb(255, 255, 255)',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: '3px',
  paddingTop: '65px',
  '@screen >= $xs': {
    paddingLeft: '16px',
  },
  '@screen >= $xl': {
    paddingLeft: '80px',
  },
});

const Title = styled('h3', {
  minHeight: '24px !important',
  color: 'rgb(0, 38, 119)',
  fontSize: '20px !important',
  fontWeight: 'bold',
  lineHeight: '24px !important',
});

const SubTitle = styled('h5', {
  minHeight: '24px !important',
  color: 'rgb(51, 51, 51) !important',
  fontSize: '16px !important',
  letterSpacing: '0px !important',
  lineHeight: '24px !important',
  fontWeight: '400 !important',
});

export function RefineResult() {
  const { apiList } = React.useContext(AppContext);
  return (
    <Wrapper>
      <Title>Refine Result</Title>
      <SubTitle>Showing {apiList.length} Results</SubTitle>
    </Wrapper>
  );
}

import React, { useEffect, useState } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { saveAs } from 'file-saver';
import { Flex } from '@abyss/web/ui/Flex';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { Alert } from '@abyss/web/ui/Alert';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { useSelector } from 'react-redux';

import {
  useDownloadFile,
  resetDownloadFileAPI,
} from '../../../../../../hooks/useDownloadFile/useDownloadFile';
import { resetViewSentCommunicationApi } from '../../../../../../hooks/useGetSentCommunicationApi/useGetSentCommunicationApi';
import { useGetSentCommunicationApi } from '../../../../../../hooks/useGetSentCommunicationApi';

const PaddingBottom = styled('div', {
  paddingBottom: '12px',
});

export const ViewEmailModal = ({ showModal, modalOnClose, emailTemplate }) => {
  const [sentCommunication] = useGetSentCommunicationApi();
  const [downloadS3File, getDownloadS3File] = useDownloadFile();
  const [fileId, setFileId] = useState('');
  const [, resetViewCommunication] = resetViewSentCommunicationApi();
  const [, resetDownloadFile] = resetDownloadFileAPI();
  const [displayError, setDisplayError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState({
    header: '',
    message: '',
  });
  const errorMessages = useLagoon('error-messages')();
  const downloadS3FileData = downloadS3File.data[0];
  const sentFileIds = sentCommunication?.data?.fileIds;

  const downloadEvent = fileDetails => {
    const fileData = fileDetails.split('_');
    const fileName =
      fileData[1] &&
      fileData[1]
        .split('.')
        .slice(0, -1)
        .join('.');
    const fileURL = null;
    const fileContext = 'pdf';
    event('DOWNLOAD_CLICK', {
      fileName,
      fileURL,
      fileContext,
    });
  };
  const downloadButton = sentFileIds?.map(fileKey => {
    const name = fileKey.split('_')[1];
    const userInfo = useSelector(state => state.GET_USER_INFO.data);
    return (
      <div style={{ marginBottom: '-7px' }}>
        <Button
          variant="tertiary"
          size="$md"
          fontSize="15px"
          onClick={() => {
            setDisplayError(false);
            setFileId(fileKey);
            getDownloadS3File({ fileKey, userInfo });
            downloadEvent(fileKey);
          }}
          ariaLoadingLabel="Loading content"
          isLoading={fileId === fileKey && downloadS3File?.loading}
          before={
            fileId === fileKey && downloadS3File?.loading ? (
              <IconMaterial icon="downloading" />
            ) : (
              <IconMaterial icon="download" />
            )
          }
          css={{
            'abyss-insert-element-after': {
              marginBottom: '-10px',
            },
          }}
        >
          {name}
        </Button>
      </div>
    );
  });

  const downloadErrorMessage = () => {
    const downloadMessage = errorMessages.find(
      message => message?.active && message?.key === 'VIEW_FILE_DOWNLOAD_FAILED'
    );
    if (downloadMessage) {
      setDisplayError(true);
      const { header = '', message = '' } = downloadMessage || {};
      setServerErrorMessage({ header, message });
    }
  };

  const downloadFileData = () => {
    fetch(`data:application/pdf;base64,${downloadS3FileData.fileContent}`)
      .then(blobRes => blobRes.blob())
      .then(blobRes => {
        const { filename } = downloadS3FileData;
        const file = new File([blobRes], filename, {
          type: 'application/pdf',
        });

        saveAs(file);
        setDisplayError(false);
        resetDownloadFile();
      })
      .catch(() => {
        downloadErrorMessage();
      });
  };

  useEffect(() => {
    if (downloadS3FileData?.fileContent) {
      setDisplayError(false);
      downloadFileData();
    }

    if (downloadS3File.error) {
      resetDownloadFile();
      downloadErrorMessage();
    }
  }, [downloadS3File]);

  const showEmailTemplate = () => {
    return (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: emailTemplate?.body }} />
    );
  };

  const closeModal = () => {
    resetViewCommunication();
    resetDownloadFile();
    setDisplayError(false);
    modalOnClose(false);
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={closeModal}
      closeOnClickOutside={false}
      footer={
        <Modal.Section>
          {displayError ? (
            <Alert title={serverErrorMessage?.header}>
              {serverErrorMessage?.message}
            </Alert>
          ) : null}
          <PaddingBottom />
          <Flex justify="space-between">
            <div>{downloadButton}</div>
            <div style={{ alignSelf: 'center' }}>
              <Button
                onClick={closeModal}
                style={{ marginBottom: '-30px', fontSize: '15px' }}
              >
                Close
              </Button>
            </div>
          </Flex>
        </Modal.Section>
      }
    >
      <Modal.Section css={{ fontSize: '15px', fontWeight: 400 }}>
        {showEmailTemplate()}
      </Modal.Section>
    </Modal>
  );
};
ViewEmailModal.propTypes = {
  modalOnClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  emailTemplate: PropTypes.instanceOf(Object).isRequired,
};

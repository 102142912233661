import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { cards } from './_mock';
import { APICard } from '../../../../../common/APICard';

const Wrapper = styled('div', {
  margin: '64px 0 30px 0',
  display: 'none',
});

const Heading = styled('h3', {
  height: '32px',
  color: 'rgb(0, 38, 119)',
  fontSize: '28px',
  fontWeight: 600,
  lineHeight: '32px',
});

const CategoryBox = styled('div', {
  display: 'flex',
  gap: '15px',

  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
    gap: '15px',
  },
});

export const NewestAPIs = () => {
  return (
    <Wrapper>
      <Heading>New APIs</Heading>
      <CategoryBox>
        {cards.map((el, i) => {
          return (
            <APICard
              src={el.src}
              title={el.title}
              description={el.description}
              link={el.link}
              linkLabel={el.linkLabel}
              index={i}
            />
          );
        })}
      </CategoryBox>
    </Wrapper>
  );
};

import { useEffect, useRef, useState } from 'react';

const DEFAULT_DURATION = 300;

export const useCollapse = ({ ref, defaultIsOpen, duration } = {}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? true);
  const maskRef = ref || useRef();
  const firstUpdate = useRef(true);

  useEffect(() => {
    const maskElem = maskRef?.current;
    const collapseFunction = () => {
      if (isOpen) {
        maskElem.ontransitionend = e => {
          if (e.propertyName === 'height') {
            maskElem.style.height = 'auto';
          }
        };
      }
    };

    if (!maskElem) {
      return undefined;
    }

    if (firstUpdate.current) {
      const transitionDuration = duration || DEFAULT_DURATION;
      const transitionCheck =
        transitionDuration > 1500 ? 1500 : transitionDuration;
      maskElem.style.transitionDuration = `${transitionCheck}ms`;
      maskElem.style.height = isOpen ? 'auto' : '0px';
      firstUpdate.current = false;
      return undefined;
    }

    const startHeight = isOpen ? 0 : maskElem.scrollHeight;
    const closeHeight = isOpen ? maskElem.scrollHeight : 0;

    maskElem.ontransitionend = undefined;

    maskElem.style.height = 'auto';
    maskElem.style.height = `${startHeight}px`;

    // only animates if there is a difference ( display none will return always 0)
    if (startHeight !== closeHeight) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            collapseFunction();
            maskElem.style.height = `${closeHeight}px`;
          });
        });
      });
    } else {
      maskElem.style.height = isOpen ? 'auto' : '0px';
    }

    return () => {
      maskElem.ontransitionend = undefined;
    };
  }, [isOpen, maskRef, firstUpdate]);

  return {
    isOpen,
    buttonProps: {
      onClick: () => {
        return setIsOpen(!isOpen);
      },
      'aria-expanded': isOpen,
    },
    collapseProps: {
      ref: maskRef,
      style: { overflow: 'hidden' },
      'aria-hidden': !isOpen,
    },
  };
};

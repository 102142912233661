import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { getDefaultMiddleware } from './middleware';
import { reducers } from './reducers';

const configureStore = options => {
  const {
    reducer,
    middleware = getDefaultMiddleware(),
    preloadedState,
    enhancers = [],
  } = options || {};

  let rootReducer;

  if (typeof reducer === 'function') {
    rootReducer = reducer;
  } else if (typeof reducer === 'object') {
    rootReducer = combineReducers(reducer);
  } else {
    throw new Error(
      [
        'Reducer argument must be a function or an object of functions that ',
        'can be passed to combineReducers',
      ].join(',')
    );
  }

  const middlewareEnhancer = applyMiddleware(...middleware);
  const storeEnhancers = [middlewareEnhancer, ...enhancers];
  const composedEnhancer = compose(...storeEnhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancer);

  return store;
};

export const store = configureStore({
  reducer: combineReducers(reducers),
});

export const baseStyles = () => {
  return {
    content: {
      paddingTop: 0,
    },
    hidden: {
      display: 'none',
    },
  };
};

export const baseStyles = () => {
  return {
    layout: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 1200,
      marginBottom: '128px',
    },
  };
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ToggleTabs } from '@abyss/web/ui/ToggleTabs';

const LANGUAGE_LABELS = {
  curl: 'cURL',
  http: 'HTTP',
  java: 'Java',
  javascript: 'JavaScript',
  nodejs: 'NodeJS',
  python: 'Python',
  csharp: 'C#',
  php: 'PHP',
};

export const LanguageSelect = ({
  languages,
  setSelectedLanguage,
  selectedLanguage,
}) => {
  const languageOptions = useMemo(() => {
    return languages.map(item => {
      return {
        value: item,
        label: LANGUAGE_LABELS[item] || item,
      };
    });
  }, [languages]);

  return (
    <ToggleTabs
      name="Select a Language"
      value={selectedLanguage}
      onChange={e => {
        return setSelectedLanguage(e.target.value);
      }}
    >
      {languageOptions.map(item => {
        return <ToggleTabs.Tab key={item.value} {...item} />;
      })}
    </ToggleTabs>
  );
};

LanguageSelect.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.string),
  setSelectedLanguage: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
};

LanguageSelect.defaultProps = {
  languages: [],
  selectedLanguage: {
    label: '',
    value: '',
    sytax: '',
    variant: '',
  },
};

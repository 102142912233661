import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_SENT_COMMUNICATION';
const eapm = window.elasticApm;

export const resetViewSentCommunicationApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

const convertToJson = (res, args) => {
  event('API_TRANSACTION', {
    fields: {
      appVersion: process.env.PACKAGE_VERSION,
      url: window.location.href,
      endpoint: config('COMMUNICATION_URL'),
      method: 'GET',
      userInfo: args?.userInfo?.data,
      code: res.status,
      operationName: ACTION_TYPE,
      message: res.statusText,
    },
  });

  event('NEWRELIC_API_TRANSACTION', {
    code: res.status,
    message: res.statusText,
    endpoint: config('COMMUNICATION_URL'),
    method: 'GET',
  });

  return new Promise(resolve =>
    res.json().then(response =>
      resolve({
        status: res.status,
        response,
      })
    )
  );
};

const getAction = () => {
  const action = useAction(args => {
    if (eapm) {
      window.elasticApm.startTransaction(ACTION_TYPE, 'http-request', {
        managed: true,
      });
    }
    return dispatch => {
      const { id = '' } = args || {};
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('APIM_STARGATE_URL')}${config('COMMUNICATION_URL')}`, {
        headers: {
          allMessages: false,
          id,
          viewEmail: true,
        },
      })
        .then(res => convertToJson(res, args))
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 500) {
              throw new Error(
                'Your request was unsuccessful, please try again.'
              );
            } else {
              dispatch({
                type: `${ACTION_TYPE}_ERROR`,
                payload: res.response,
              });
            }
          } else {
            dispatch({
              type: `${ACTION_TYPE}_COMPLETED`,
              payload: res.response,
            });
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          event('API_TRANSACTION_ERROR', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('COMMUNICATION_URL'),
              method: 'GET',
              userInfo: args?.userInfo?.data,
              operationName: ACTION_TYPE,
            },
          });
        });
    };
  });
  return action;
};

export const useGetSentCommunicationApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = getAction();
  return [result, action];
};

import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Icon } from '@abyss/web/ui/Icon';

const RequiredIconStyle = styled(Icon, {
  lineHeight: '10px',
});

export const RequiredIcon = () => {
  return (
    <RequiredIconStyle size={10} color="$error1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        focusable="false"
        aria-hidden="true"
        role="img"
        style={{ display: 'inline-block' }}
      >
        <path d="M21.7 11.05l-1.05-3.3-7.7 3.15.7-8.4H10.2l.65 8.4-7.7-3.2L2.1 11l8.1 1.95-5.45 6.3 2.8 2.05 4.35-7.1 4.3 7.1 2.8-2-5.4-6.3z" />
      </svg>
    </RequiredIconStyle>
  );
};

import React from 'react';
import { Button } from '@abyss/web/ui/Button';
import PropTypes from 'prop-types';
import { event } from '@abyss/web/tools/event';
import { config } from '@abyss/ui/tools/config';

export const StartRegistrationButton = ({ children, ...props }) => {
  const exitEvent = () => {
    const exitLink = 'start registration request';
    const exitURL = config('DIGITAL_SECURITY_SSO_URL');

    event('EXIT_EVENT', {
      event,
      exitLink,
      exitURL,
    });
  };
  return (
    <Button
      variant="outline"
      href={config('DIGITAL_SECURITY_SSO_URL')}
      openNewWindow={false}
      {...props}
      onClick={() => exitEvent()}
    >
      {children}
    </Button>
  );
};

StartRegistrationButton.propTypes = {
  children: PropTypes.node.isRequired,
};

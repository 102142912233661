import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@abyss/web/ui/Alert';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import Markdown from 'markdown-to-jsx';
import { LagoonStyledButton } from '../LagoonStyledButton/LagoonStyledButton';

export const CommonAlert = ({ alertType }) => {
  const contentManagement = useLagoon('custom-alerts');
  const alertList = contentManagement();
  const router = useRouter();

  const lagoonCommonAlertDetails =
    alertList?.length > 0
      ? alertList?.filter(el => el.active && el.alertType === alertType)?.[0]
      : null;

  const {
    variant,
    message,
    title,
    icon,
    isClosable,
    active,
    buttonText,
    buttonLink,
  } = lagoonCommonAlertDetails || {};

  const [isVisible, setIsVisible] = useState(active);

  const onAction = () => {
    if (buttonLink) {
      setIsVisible(false);
      router.push(buttonLink);
    }
  };

  const getCustomAlertProps = {
    title,
    inlineText: (
      <Markdown
        id={`marketplace-${alertType}-alert-text`}
        options={{
          overrides: {
            a: {
              component: LagoonStyledButton,
              props: { size: '14px' },
            },
          },
        }}
      >
        {message}
      </Markdown>
    ),
    variant,
    isVisible,
    onClose: isClosable ? () => setIsVisible(false) : null,
    actionText: buttonText,
    onAction,
  };

  if (icon) {
    getCustomAlertProps.icon = <IconMaterial icon={icon} />;
  }

  return active ? (
    <Alert
      {...getCustomAlertProps}
      css={{
        '.abyss-alert-title': {
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '24px',
        },
        '.abyss-alert-inline-text': {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
        },
      }}
    />
  ) : (
    ''
  );
};

CommonAlert.propTypes = {
  alertType: PropTypes.string.isRequired,
};

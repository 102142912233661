import React, { useState } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { AppContext } from 'src/app/App/AppProvider';
import { SortBy } from '../navComponents/SortBy';
import { SidePane } from '../SidePane/SidePane';
import styles from './TabletView.css';

export const TabletView = () => {
  const { filters = [], updateFilters } = React.useContext(AppContext);
  const [sideView, setSideView] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [oldFilters, setOldFilters] = useState([]);

  const clearFiltersHandler = () => {
    const k = filters.map(el => ({ ...el, checked: false }));
    updateFilters(k);
  };

  const closeSidePane = () => {
    updateFilters(oldFilters);
    setSideView(false);
    clearFiltersHandler();
  };

  const openSidePane = sort => {
    if (sort === 'showSort') {
      setShowSort(true);
    } else {
      setShowSort(false);
    }
    setOldFilters(filters);
    setSideView(true);
  };

  const applyFilters = () => {
    setSideView(false);
  };

  const clearFilter = el => {
    const k = filters.map(m => {
      return {
        ...m,
        checked: m.title === el.title ? false : m.checked,
      };
    });
    updateFilters(k);
  };

  return (
    <div className={styles.Wrapper}>
      <SidePane
        closeSidePane={closeSidePane}
        applyFilters={applyFilters}
        sideView={sideView}
        showSort={showSort}
      />
      <div className={styles.Tablet}>
        <div className={styles.TabletButtonMargin}>
          <Button onClick={openSidePane}>Filter</Button>
        </div>
        <SortBy />
      </div>
      <div className={styles.Mobile}>
        <div className={styles.MobileButtonMargin}>
          <Button onClick={() => openSidePane('showSort')}>
            Filter and Sort
          </Button>
        </div>
      </div>

      <div className={styles.FilterBtnWrap}>
        {filters
          .filter(fl => fl.checked)
          .map(el => {
            return (
              <div className={styles.FilterBtn}>
                <Button
                  variant="outline"
                  after={
                    <IconMaterial icon="close" isScreenReadable size={30} />
                  }
                  onClick={() => clearFilter(el)}
                >
                  {el.title}
                </Button>
              </div>
            );
          })}
        {filters.filter(fl => fl.checked).length ? (
          <Button
            variant="tertiary"
            onClick={clearFiltersHandler}
            className={styles.ClearBtn}
          >
            Clear Filters
          </Button>
        ) : null}
      </div>
    </div>
  );
};

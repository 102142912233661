export const convertJava = ({ url, method, headers, body }) => {
  const codeArr = [];
  let contentType;
  codeArr.push(
    `OkHttpClient client = new OkHttpClient().newBuilder().build();`
  );
  const headerArr = headers.map(header => {
    if (header.key === 'Content-Type') {
      contentType = header.value;
    }
    return `  .addHeader("${header.key}", "${header.value}")`;
  });

  if (body) {
    codeArr.push(`MediaType mediaType = MediaType.parse("${contentType}");`);
    codeArr.push(
      `RequestBody body = RequestBody.create(mediaType, ${JSON.stringify(
        JSON.stringify(body)
      )});`
    );
  }

  codeArr.push('Request request = new Request.Builder()');
  codeArr.push(`  .url("${url}")`);
  codeArr.push(`  .method("${method}", ${body ? 'body' : 'null'})`);

  codeArr.push(`${headerArr.join('\n')}`);

  codeArr.push(`  .build();`);
  codeArr.push('Response response = client.newCall(request).execute();');

  return codeArr.join('\n');
};

import React from 'react';
import { Button } from '@abyss/web/ui/Button';
import PropTypes from 'prop-types';
import CloseIcon from 'src/assets/modal_fntt_close_dark.svg';
import styles from './SidePane.css';
import { Filters } from '../navComponents/Filters';
import { SortBy } from '../navComponents/SortBy';

export const SidePane = ({
  closeSidePane,
  sideView,
  applyFilters,
  showSort,
}) => {
  return (
    <React.Fragment>
      {sideView ? (
        <div className={styles.Wrapper}>
          <div className={styles.CloseIconWrap}>
            <img
              src={CloseIcon}
              alt="Close Sidebar"
              onClick={closeSidePane}
              onKeyPress={() => {}}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              className={styles.CloseIcon}
            />
          </div>
          <div className={styles.BtnWrap}>
            <div className={styles.TabletButtonMargin}>
              <Button onClick={applyFilters}>Apply Filters</Button>
            </div>
          </div>
          <div>
            {showSort && <SortBy />}
            <Filters />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

SidePane.propTypes = {
  closeSidePane: PropTypes.func,
  applyFilters: PropTypes.func,
  sideView: PropTypes.bool,
  showSort: PropTypes.bool,
};

SidePane.defaultProps = {
  closeSidePane: PropTypes.func,
  applyFilters: PropTypes.func,
  sideView: PropTypes.bool,
  showSort: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { CodeHighlighter } from '@abyss/web/ui/CodeHighlighter';
import { Box } from '@abyss/web/ui/Box';

const FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf', 'text/html'];

const convertToImage = (data, contentType) => {
  const blob = new Blob([data], { type: contentType });
  return window.URL.createObjectURL(blob);
};

const convertToJSON = data => {
  return new TextDecoder('utf-8').decode(new Uint8Array(data));
};

const ObjectStyle = styled('object', {
  border: '1px solid $gray4',
});

export const Response = ({ response }) => {
  if (!response) {
    return (
      <Box>
        <span>
          Click <b>Send Request</b> to get a response
        </span>
      </Box>
    );
  }

  if (!response.data) {
    return (
      <Box>
        <span>Could not send request</span>
      </Box>
    );
  }

  if (response.headers) {
    const contentType = response.headers['content-type'];
    if (FILE_TYPES.includes(contentType)) {
      const imageContent = convertToImage(response.data, contentType);

      if (contentType.includes('pdf') || contentType.includes('html')) {
        return (
          <ObjectStyle
            data={imageContent}
            type={contentType}
            width="100%"
            height="500px"
            aria-label={`${contentType} response`}
          />
        );
      }

      return <img src={imageContent} alt={`${contentType} response`} />;
    }
  }

  return (
    <CodeHighlighter
      language="js"
      code={convertToJSON(response.data)}
      showLineNumbers
      css={{
        'abyss-code-highlighter': {
          maxHeight: 450,
          padding: '$sm',
          overflow: 'auto',
        },
      }}
    />
  );
};

Response.propTypes = {
  response: PropTypes.shape({
    headers: PropTypes.shape({
      'content-type': PropTypes.string,
    }),
    data: PropTypes.shape({}),
  }),
};

Response.defaultProps = {
  response: null,
};

import { constants } from '../../../common/Utils/Constants';

export const testimonialMock = [
  {
    title:
      'UnitedHealthcare has done an amazing job of providing documentation, resources and qualified people in their API department. As some companies do not even have a test environment or support, UnitedHealthcare has exceeded any expectations.',
    linkLabel: constants.VIEW_API_DETAILS,
    desc: '',
  },
  {
    title:
      'Love the API claim status service. Claim status programs that used to take us 5 hours over 2 days to run, now takes about 20 minutes to run the same transaction and get the same data. We will be seeking additional API services with UnitedHealthcare.',
    linkLabel: constants.VIEW_API_DETAILS,
    desc: '',
  },
  {
    title:
      'The amount of time to reconsider these claims using the new API has been reduced to zero which freezes up the agents to focus on other tasks.',
    linkLabel: constants.VIEW_API_DETAILS,
    desc: '',
  },
  {
    title:
      'This is a far better solution than requiring our analysts to access the portal directly. The more API solutions, the easier it will be for our organization to manage routine tasks. We see tremendous value when our tools can retrieve information via APIs.',
    linkLabel: constants.VIEW_API_DETAILS,
    desc: '',
  },
  {
    title:
      'You have simplified a lot of things for us and helped to provide better information to our users.',
    linkLabel: constants.VIEW_API_DETAILS,
    desc: '',
  },
  {
    title: 'Much richer offering of data than other payers.',
    linkLabel: constants.VIEW_API_DETAILS,
    desc: '',
  },
  {
    title:
      'We have a tiny family practice. A few months ago, my mom was buried under tons of paperwork and a binder full of claims. Thanks to your APIs that binder is now empty, and we can manage everything with just a few clicks, freeing us up to spend more time as a family without the stress of endless paperwork.',
    linkLabel: constants.VIEW_API_DETAILS,
    desc: '',
  },
];

import './config';
import React from 'react';
import ReactDOM from 'react-dom';
import { global } from '@abyss/ui/tools/styled';
import { AppProvider } from '@abyss/ui/utility/AppProvider';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { LagoonProvider } from '@abyss/web/ui/LagoonProvider';
import { config } from '@abyss/ui/tools/config';
import { AppProvider as ContextAPI } from './App/AppProvider';
import { App } from './App';
import { store } from './store';
import { styles } from './styles';
import { theme } from './theme';

const AppStyles = global`${styles}`;

const render = () => {
  ReactDOM.render(
    <AppProvider theme={theme.theme}>
      <ThemeProvider theme={theme}>
        <ContextAPI>
          <Provider store={store}>
            <AppStyles />
            <LagoonProvider
              app={config('LAGOON_NAMESPACE')}
              env={config('APP_ENV') === 'local' ? 'dev' : config('APP_ENV')}
            >
              <App />
            </LagoonProvider>
          </Provider>
        </ContextAPI>
      </ThemeProvider>
    </AppProvider>,
    document.getElementById('root')
  );
};

if (module.hot) {
  module.hot.accept('./App', () => render());
}

render();

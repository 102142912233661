import React, { Fragment } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Button } from '@abyss/web/ui/Button';
import { useSelector } from 'react-redux';
import { styled } from '@abyss/web/tools/styled';
import { usePostTinApi } from '../../../../../../hooks/usePostTinApi';
import { usePatchTinApi } from '../../../../../../hooks/usePatchTinApi';

const SubmitButton = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingBottom: '16.5px',
  paddingTop: '16px',
});
const CheckboxStyle = styled('span', {
  fontFamily: 'AbyssEnterpriseSansVF',
});

export const AttestAndSubmitModal = ({
  isDeleteFlow,
  isOpen,
  isLoadingOverlayOpen,
  onClosed,
  tins = [],
  text,
}) => {
  const [, setPostTinManagementAPI] = usePostTinApi();
  const [, setPatchTinManagementAPI] = usePatchTinApi();
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);

  const label = `I attest that I am ${text} this TIN list as an authorized user
   associated with ${dsUserInfo.orgName}. I certify that all information
   provided as part of this process is true, accurate and complete, to
   the best of my knowledge.`;

  const onSubmit = () => {
    if (isDeleteFlow) {
      setPatchTinManagementAPI({
        tins,
        userInfo,
      });
    } else {
      setPostTinManagementAPI({
        tins,
        userInfo,
      });
    }
    onClosed(false);
    isLoadingOverlayOpen(true);
  };

  return (
    <Fragment>
      <Modal
        title="Attest and Submit"
        isOpen={isOpen}
        onClose={() => onClosed(false)}
        css={{
          'abyss-modal-header-container': { color: '#002677' },
        }}
      >
        <Modal.Section>
          <CheckboxStyle>
            <Checkbox label={label} isChecked validators={{ required: true }} />
          </CheckboxStyle>
          <SubmitButton>
            <Button type="submit" size="$md" onClick={onSubmit}>
              Agree and Submit
            </Button>
          </SubmitButton>
        </Modal.Section>
      </Modal>
    </Fragment>
  );
};
AttestAndSubmitModal.propTypes = {
  onClosed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isDeleteFlow: PropTypes.bool,
  text: PropTypes.string.isRequired,
  isLoadingOverlayOpen: PropTypes.func.isRequired,
  tins: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AttestAndSubmitModal.defaultProps = {
  isDeleteFlow: false,
};

import React from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { styled } from '@abyss/web/tools/styled';
import { StyledMarkdownLink } from '../../../../../../common/StyledMarkdownLink';

const Wrapper = styled('div', {});

const UL = styled('ul', {
  padding: '16px 0px 16px 0px',
  listStyle: 'disc',
  fontSize: '15px',
  marginLeft: '1.5em',
  color: '#000000',
});

const LI = styled('li', {
  fontWeight: 'normal',
});

const StyledDiv = styled('div', {});

export const ReleaseNotesPageContent = ({ content }) => {
  return (
    <Wrapper>
      <If condition={content.newFeatures}>
        <StyledDiv>
          <div className="elementof" id={`features-badge-${content.key}`}>
            <Badge
              outline
              variant="info"
              css={{
                'abyss-badge-root': {
                  height: 'auto',
                  padding: '8px 14px',
                  borderWidth: '1px',
                  borderRadius: '1px',
                },
                'abyss-badge-content': {
                  color: '#000000',
                  fontSize: '13px',
                },
              }}
            >
              New Features
            </Badge>
          </div>
          <Markdown
            options={{
              overrides: {
                a: StyledMarkdownLink,
                ul: UL,
                li: LI,
              },
            }}
          >
            {content.newFeatures}
          </Markdown>
        </StyledDiv>
      </If>
      <If condition={content.bugFixes}>
        <StyledDiv>
          <div className="elementof" id={`bugfix-badge-${content.key}`}>
            <Badge
              outline
              variant="success"
              css={{
                'abyss-badge-root': {
                  height: 'auto',
                  padding: '8px 14px',
                  borderWidth: '1px',
                  borderRadius: '1px',
                },
                'abyss-badge-content': {
                  color: '#000000',
                },
              }}
            >
              Bug Fixes
            </Badge>
          </div>
          <Markdown
            options={{
              overrides: {
                a: StyledMarkdownLink,
                ul: UL,
                li: LI,
              },
            }}
          >
            {content.bugFixes}
          </Markdown>
        </StyledDiv>
      </If>
    </Wrapper>
  );
};

ReleaseNotesPageContent.propTypes = {
  content: PropTypes.shape({
    key: PropTypes.string,
    newFeatures: PropTypes.string,
    bugFixes: PropTypes.string,
  }).isRequired,
};

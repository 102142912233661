import React from 'react';
import PropTypes from 'prop-types';

export const SideNavHeader = ({ children }) => {
  return <h2>{children}</h2>;
};

SideNavHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

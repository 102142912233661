import React, { useEffect, Fragment, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@abyss/web/tools/styled';
import { storage } from '@abyss/web/tools/storage';
import { Button } from '@abyss/web/ui/Button';
import { config } from '@abyss/ui/tools/config';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Flex } from '@abyss/web/ui/Flex';
import { useForm } from '@abyss/web/hooks/useForm';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { StepIndicator } from '@abyss/web/ui/StepIndicator';
import { Card } from '@abyss/web/ui/Card';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { onBreadcrumbsHandler } from 'src/hooks/useBreadcrumbs';
import { PageLayout } from '../../../../../../common/PageLayout';
import { Breadcrumbs } from '../../../../Breadcrumbs/Breadcrumbs';
import { useUserInfo } from '../../../../../../hooks/useUserInfo';
import { useDSUserInfo } from '../../../../../../hooks/useDSUserInfo';
import { AppContext } from '../../../../AppProvider';

const Wrapper = styled('div', {
  '.abyss-alert-root': {
    marginBottom: '20px',
  },

  '@screen >= $xs': {
    padding: '44px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StepIndicatorConatiner = styled('div', {
  marginBottom: '30px',
});

const PageHeading = styled('h1', {
  color: '#002677',
  fontSize: '41.04px',
  fontWeight: '600',
  lineHeight: '52px',
  marginBottom: '32px',
  '@screen < $sm': {
    fontSize: '25px',
  },
});

export const AddTins = props => {
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const [, getUserInfo] = useUserInfo();
  const [, getDSUserInfo] = useDSUserInfo();
  const paginationProps = usePagination({ pages: 2 });
  const router = useRouter();
  const form = useForm();

  const [setBreadcrumbs] = onBreadcrumbsHandler();
  const { setPreviousPath } = useContext(AppContext);

  const breadcrumbsHandler = data => {
    setBreadcrumbs(data);
  };

  const setPath = data => {
    setPreviousPath(data.history.location.pathname);
  };

  useEffect(() => {
    storage.local.set('IS_SIGN_IN_CLICKED', false);
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }
    setPath(props);
    breadcrumbsHandler(props);
    if (!userInfo?.firstName) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.firstName && !dsUserInfo.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  // Validating TINs before form submition (on user input)
  const formattingTins = value => {
    let updatedVal = '';
    updatedVal = value.replace(
      /[!@#$%^&*)({}<>.[\]\-+_`~=\\|';":a-zA-Z/?]/g,
      ''
    );
    updatedVal = updatedVal.replace(/\s/g, ',').replace(/,,/g, ',');
    return updatedVal;
  };

  // Validating TINs length after form submition
  const validatingTins = value => {
    const invalidTins = value?.split(',')?.filter(tin => tin.length !== 9);
    if (!invalidTins?.length) {
      return true;
    }
    return `Invalid TINs entered, please validate and update the following: ${invalidTins.join()}`;
  };

  // Validating required field and preventing the form to submit empty
  const handleValidateTins = () => {
    form.validate('tins', () => {
      router.push('/welcome/add-tins/submitted-tins');
    });
  };

  const title = () => {
    return (
      <div style={{ display: 'inline-flex' }} id="add-tins-title">
        <IconBrand icon="folder" size={32} />
        <div style={{ alignSelf: 'center', marginLeft: '15px' }}>
          Add your TINs here
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <PageLayout>
        <Wrapper>
          <Breadcrumbs />
          <PageHeading id="add-tins-heading">Add TINs</PageHeading>
          <StepIndicatorConatiner id="step-indicator">
            <StepIndicator currentStep={paginationProps.state.currentPage}>
              <StepIndicator.Step label="Add TINs" />
              <StepIndicator.Step label="Review & Submit" />
            </StepIndicator>
          </StepIndicatorConatiner>
          <Card
            header={title()}
            css={{
              'abyss-card-title': {
                backgroundColor: '#CBCCCD',
              },
            }}
          >
            <Card.Section>
              <TextInputArea
                css={{
                  'abyss-text-input-area-input-descriptors': {
                    span: { wordBreak: 'break-all' },
                  },
                }}
                model="tins"
                id="tinInputField"
                aria-label="type here to add TIN's"
                maxLength={6999}
                displayMaxLength
                rows={5}
                label="TINs"
                subText="Copy and paste your list of TINs into the field above, it will automatically load in the appropriate format. If you are manually entering individual TINs, please separate them using a comma."
                descriptorsDisplay="column"
                validators={{
                  validate: value => validatingTins(value),
                  required: true,
                }}
                formatter={useCallback(value => formattingTins(value), [
                  form.getValues('tins'),
                ])}
              />
            </Card.Section>
          </Card>
          <Flex justify="end">
            <Button
              id="submit-tins"
              type="submit"
              style={{ marginTop: 25 }}
              onClick={handleValidateTins}
            >
              Continue
            </Button>
          </Flex>
        </Wrapper>
      </PageLayout>
    </Fragment>
  );
};

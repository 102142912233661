export const convertPhp = ({ url, method, headers, body }) => {
  const codeArr = [];
  codeArr.push(
    `<?php\n\n$curl = curl_init();\n\ncurl_setopt_array($curl, array(`
  );
  codeArr.push(`  CURLOPT_URL => '${url}',`);
  codeArr.push(`  CURLOPT_RETURNTRANSFER => true,`);
  codeArr.push(`  CURLOPT_ENCODING => '',`);
  codeArr.push(`  CURLOPT_MAXREDIRS => 10,`);
  codeArr.push(`  CURLOPT_TIMEOUT => 0,`);
  codeArr.push(`  CURLOPT_FOLLOWLOCATION => true,`);
  codeArr.push(`  CURLOPT_HTTP_VERSION => `);
  codeArr.push(`    CURL_HTTP_VERSION_1_1,`);
  codeArr.push(`  CURLOPT_CUSTOMREQUEST => '${method}',`);
  if (body) {
    codeArr.push(`  CURLOPT_POSTFIELDS =>'${JSON.stringify(body, null, 2)}',`);
  }

  // header
  codeArr.push(`  CURLOPT_HTTPHEADER => array(`);
  const headerArr = headers.map(header => {
    return `    '${header.key}: ${header.value}',`;
  });
  codeArr.push(`${headerArr.join('\n')}`);
  codeArr.push(`  ),`);

  codeArr.push(
    `));\n\n$response = curl_exec($curl);\n\ncurl_close($curl);\necho $response;`
  );

  return codeArr.join('\n');
};

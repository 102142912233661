import React from 'react';
import { useSelector } from 'react-redux';
import RightChevron from 'src/assets/arrow-icons/ic_keyboard_arrow_right.svg';
import { Link } from '@abyss/web/ui/Link';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { Text } from '@abyss/web/ui/Text';
import styles from './Breadcrumbs.css';
import { data } from './BreadcrumbsUtils';

export const Breadcrumbs = () => {
  const breadcrumbsData = useSelector(state => state.BREADCRUMBS.data);

  const StyledLinkNonSelected = styled(Link, {
    display: 'flex',
    height: '18px',
    fontWeight: '400 ! important',
    letterSpacing: '0px',
    lineHeight: '16px',
  });

  const StyledLinkSelected = styled(Text, {
    height: '18px',
    fontWeight: '400 ! important',
    letterSpacing: '0px',
    lineHeight: '18px',
  });

  const router = useRouter();
  const handleClick = breadcrumbData => {
    const url = window.location.href;
    const urlParts = url.split('/').pop();

    const interactionName = urlParts;
    const interactionValue = data[breadcrumbData.url];
    const interactionContext = 'jump link click';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });

    router.push(`/${breadcrumbData.url}`);
  };

  return (
    <div className={styles.wrapper}>
      {breadcrumbsData.length &&
        breadcrumbsData.map((el, i) => {
          if (el.selected) {
            return (
              <StyledLinkSelected
                key={el.url}
                size="11px"
                color="#333333"
                variant="nounderline"
              >
                <div className={styles.linkLabel}>
                  <p>{data[el.label]}</p>
                  {i !== breadcrumbsData.length - 1 && (
                    <img src={RightChevron} alt="right" />
                  )}
                </div>
              </StyledLinkSelected>
            );
          }
          return (
            <StyledLinkNonSelected
              key={el.url}
              size="11px"
              variant="nounderline"
              onClick={() => {
                handleClick(el);
              }}
            >
              <div className={styles.linkLabel}>
                <p>{data[el.label]}</p>
                {i !== breadcrumbsData.length - 1 && (
                  <img src={RightChevron} alt="right" />
                )}
              </div>
            </StyledLinkNonSelected>
          );
        })}
    </div>
  );
};

import { init as initApm } from '@elastic/apm-rum';

export const validateElasticApm = () => {
  const eapm = window?.elasticApm;
  return eapm;
};

export const initializeElasticApm = (
  eapm,
  { serverUrl, environment, serviceName }
) => {
  if (!eapm?.isInitialized) {
    const initializedEapm = initApm({
      serverUrl,
      environment,
      serviceName,
      apmEnv: environment === 'prod' ? 'prod' : 'nonprod',
    });
    return initializedEapm;
  }
  return eapm;
};

export const validateAndInitializeElasticApm = initParams => {
  const eapm = validateElasticApm();
  return initializeElasticApm(eapm, { ...initParams });
};

export const addElasticApmLabels = tag => {
  const eapm = validateElasticApm();
  if (eapm) {
    const transaction = eapm.getCurrentTransaction();
    if (transaction) {
      transaction.addLabels(tag.data);
    }
  }
};

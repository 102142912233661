import React, { useMemo, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@abyss/web/tools/styled';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { PageLayout } from 'src/common/PageLayout';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { StepIndicator } from '@abyss/web/ui/StepIndicator';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Button } from '@abyss/web/ui/Button';
import { useForm } from '@abyss/web/hooks/useForm';
import { onBreadcrumbsHandlerMultiLevel } from 'src/hooks/useBreadcrumbs';
import { AppContext } from '../../../../AppProvider';
import { SuccessModal } from '../SuccessModal/SuccessModal';
import { FailureModal } from '../FailureModal/FailureModal';
import { usePostTinApi } from '../../../../../../hooks/usePostTinApi';
import { AttestAndSubmitModal } from '../AttestAndSubmitModal/AttestAndSubmitModal';
import { useDSUserInfo } from '../../../../../../hooks/useDSUserInfo';
import { useUserInfo } from '../../../../../../hooks/useUserInfo';

const Wrapper = styled('div', {
  '.abyss-alert-root': {
    marginBottom: '20px',
  },

  '@screen >= $xs': {
    padding: '44px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const PageHeading = styled('h1', {
  color: '#002677',
  fontSize: '41.04px',
  fontWeight: '600',
  lineHeight: '52px',
  marginBottom: '32px',
  '@screen < $sm': {
    fontSize: '25px',
  },
});

const StepIndicatorContainer = styled('div', {
  marginBottom: '30px',
});

const Buttons = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '32px',
  height: '48px',
});

export const SubmittedTins = props => {
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const [, getUserInfo] = useUserInfo();
  const [, getDSUserInfo] = useDSUserInfo();
  const [isSubmitAndAttestModalOpen, setIsSubmitAndAttestModalOpen] = useState(
    false
  );
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [postTinManagementAPI] = usePostTinApi();
  const [isLoadingOverlayOpen, setIsLoadingOverlayOpen] = useState(false);
  const router = useRouter();
  const paginationProps = usePagination({ pages: 2, start: 2 });
  const form = useForm();
  const tins = form.getValues('tins')?.split(',');

  const createData = () => {
    return (
      tins?.map((tin, ind) => ({
        key: ind,
        col1: tin,
      })) || []
    );
  };

  const columns = [
    {
      Header: 'TIN',
      accessor: 'col1',
    },
  ];

  const [setBreadcrumbs] = onBreadcrumbsHandlerMultiLevel();
  const { setPreviousPath } = useContext(AppContext);
  const text = 'submitting';

  const breadcrumbsHandler = data => {
    setBreadcrumbs(data);
  };

  const setPath = data => {
    setPreviousPath(data.history.location.pathname);
  };

  useEffect(() => {
    storage.local.set('IS_SIGN_IN_CLICKED', false);
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }
    setPath(props);
    breadcrumbsHandler(props);
    if (!userInfo?.firstName) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.firstName && !dsUserInfo?.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  useEffect(() => {
    const tinApiStatus = postTinManagementAPI?.data?.status;
    if (tinApiStatus && tinApiStatus === 200) {
      setIsLoadingOverlayOpen(false);
      setIsSuccessModalOpen(true);
    } else if (
      postTinManagementAPI?.error ||
      (tinApiStatus && tinApiStatus !== 200)
    ) {
      setIsLoadingOverlayOpen(false);
      setIsFailureModalOpen(true);
    }
  }, [postTinManagementAPI]);

  useEffect(() => {
    if (!tins?.length) {
      router.push('/welcome/add-tins');
    }
  }, []);

  useEffect(() => {
    if (userInfo?.firstName && !dsUserInfo.isAdmin) {
      router.goBack();
    }
  }, [dsUserInfo]);

  const data = useMemo(() => [...createData()], []);
  const dataTableProps = useDataTable({
    initialData: data,
    initialColumns: columns,
    showPagination: true,
    showBottomPagination: false,
    showGlobalFilter: true,
    noDataMessage: 'No TINs found for this organization',
  });

  const addTins = () => {
    setIsSubmitAndAttestModalOpen(true);
  };

  const title = () => {
    return (
      <div style={{ display: 'inline-flex' }}>
        <div>
          <IconBrand icon="folder" size={32} />
        </div>
        <div style={{ alignSelf: 'center', marginLeft: '15px' }}>
          Review TINs
        </div>
      </div>
    );
  };

  return (
    <LoadingOverlay
      loadingTitle="Please Wait"
      loadingMessage="while we upload your TINs."
      ariaLoadingLabel="Example loading aria label"
      isLoading={isLoadingOverlayOpen}
      isOpen={isLoadingOverlayOpen}
      width="384px"
    >
      <PageLayout>
        <Wrapper>
          <AttestAndSubmitModal
            isOpen={isSubmitAndAttestModalOpen}
            onClosed={setIsSubmitAndAttestModalOpen}
            isLoadingOverlayOpen={setIsLoadingOverlayOpen}
            tins={tins}
            text={text}
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            isClosed={setIsSuccessModalOpen}
          />
          <FailureModal
            isOpen={isFailureModalOpen}
            isClosed={setIsFailureModalOpen}
          />
          <Breadcrumbs />
          <PageHeading>Submitted TINs</PageHeading>
          <StepIndicatorContainer id="step-indicator">
            <StepIndicator currentStep={paginationProps.state.currentPage}>
              <StepIndicator.Step label="Add TINs" />
              <StepIndicator.Step label="Review & Submit" />
            </StepIndicator>
          </StepIndicatorContainer>
          <DataTable
            title={title()}
            tableState={dataTableProps}
            css={{
              '.abyss-table-cell': {
                paddingLeft: '10px !important',
                paddingRight: '10px !important',
              },
            }}
          />

          <Buttons>
            <div style={{ marginRight: '32px' }}>
              <Button
                size="$md"
                variant="outline"
                onClick={() => router.push('/welcome/add-tins')}
              >
                Go Back
              </Button>
            </div>
            <Button onClick={addTins}>Attest & Submit</Button>
          </Buttons>
        </Wrapper>
      </PageLayout>
    </LoadingOverlay>
  );
};

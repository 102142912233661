import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { CommonModal } from '../../components/Modal/CommonModal';

const Wrapper = styled.div``;

export const LogoutSuccessModal = ({ closeAllModals }) => {
  return (
    <Wrapper>
      <CommonModal heading="Log Out Successful" closeAllModals={closeAllModals}>
        <Button onClick={closeAllModals}>Close</Button>
      </CommonModal>
    </Wrapper>
  );
};

LogoutSuccessModal.propTypes = {
  closeAllModals: PropTypes.func,
};

LogoutSuccessModal.defaultProps = {
  closeAllModals: () => {},
};

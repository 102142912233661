import React from 'react';
import { Button } from '@abyss/web/ui/Button';
import { event } from '@abyss/web/tools/event';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { styled } from '@abyss/web/tools/styled';
import { useSelector } from 'react-redux';

const Wrapper = styled('div', {});

const ButtonWrapper = styled('a', {
  textAlign: 'center',
});

const Container2 = styled('div', {
  background: 'rgb(255, 255, 255)',
  borderRadius: '0px',
  border: '1px solid rgb(219, 219, 219)',
  minHeight: '363px',
  display: 'flex',
  marginBottom: '60px',
  '@screen >= $xs': {
    flexDirection: 'column',
  },
  '@screen >= $xl': {
    flexDirection: 'row',
  },
});

const Container2Sec1 = styled('div', {
  flexBasis: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@screen >= $xs': {
    padding: '52px 16px',
    textAlign: 'center',
  },
  '@screen >= $xl': {
    padding: '52px',
  },
});

const Container2Sec1Title = styled('h3', {
  color: 'rgb(0, 38, 119)',
  fontSize: '26px ! important',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '16px',
});

const Container2Sec1Description = styled('p', {
  color: 'rgb(51, 51, 51)',
  fontSize: '15px',
  fontWeight: 400,
  letterSpacing: 0,
  lineHeight: '24px',
  marginBottom: '27px',
});

export const GetStartedAndContactSection = () => {
  const router = useRouter();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);

  const signIn = () => {
    const isSignedIn = !!userInfo?.firstName;
    const interactionName = 'getting started';
    const signInText = 'sign in';
    const interactionContext = 'page cta link clicks';
    const exitURL = config('DIGITAL_SECURITY_SSO_URL');

    if (isSignedIn) {
      event('INTERACTION_EVENT', {
        interactionName,
        interactionValue: signInText,
        interactionContext,
      });
    } else {
      event('EXIT_EVENT', {
        event,
        exitLink: signInText,
        exitURL,
      });
    }

    storage.local.set('IS_SIGN_IN_CLICKED', true);
    if (storage.local.get('IS_SIGNED_OUT')) {
      storage.local.remove('IS_SIGNED_OUT');
    }

    if (process.env.NODE_ENV === 'development') {
      router.push('/welcome');
    } else {
      window.location = `${config('IDENTITY_API_URL')}/login`;
    }
  };
  return (
    <Wrapper>
      <Container2>
        <Container2Sec1>
          <Container2Sec1Title>Get started today</Container2Sec1Title>
          <Container2Sec1Description>
            Get started by speaking with an API Consultant. To submit a request
            for an introductory meeting, sign up today.
          </Container2Sec1Description>
          <ButtonWrapper>
            <Choose>
              <When condition={process.env.NODE_ENV === 'development'}>
                <Button
                  onClick={signIn}
                  css={{
                    'abyss-button-root': {
                      height: '42px',
                      '@screen < $sm': { width: '100%' },
                    },
                  }}
                >
                  Sign In
                </Button>
              </When>
              <Otherwise>
                <Button
                  href={`${config('IDENTITY_API_URL')}/login`}
                  onClick={signIn}
                  css={{
                    'abyss-button-root': {
                      height: '42px',
                      '@screen < $sm': { width: '100%' },
                    },
                  }}
                >
                  Sign In
                </Button>
              </Otherwise>
            </Choose>
          </ButtonWrapper>
        </Container2Sec1>
        <Container2Sec1>
          <Container2Sec1Title>
            Learn more about our transaction types
          </Container2Sec1Title>
          <Container2Sec1Description>
            For specific data returned in these APIs, refer to our Digital
            Solutions Comparison Guide or technical Reference Guides.
          </Container2Sec1Description>
          <ButtonWrapper
            href="https://www.uhcprovider.com/content/dam/provider/docs/public/resources/edi/Digital-Solutions-Comparison-Guide.pdf"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              const interactionName = `digital comparison overview guide`;
              const interactionValue =
                'view digital solutions comparision guide';
              const interactionContext = '';
              event('INTERACTION_EVENT', {
                interactionName,
                interactionValue,
                interactionContext,
              });
            }}
          >
            <Button
              css={{
                'abyss-button-root': {
                  width: '360px',
                  height: '42px',
                  '@screen < $sm': { width: '100%' },
                },
              }}
              onClick={() => {
                const fileName = 'Digital-Solutions-Comparison-Guide';
                const fileURL =
                  'https://www.uhcprovider.com/content/dam/provider/docs/public/resources/edi/Digital-Solutions-Comparison-Guide.pdf';
                const fileContext = 'pdf guide';

                event('DOWNLOAD_CLICK', {
                  fileName,
                  fileURL,
                  fileContext,
                });
              }}
            >
              View Digital Solutions Comparision Guide
            </Button>
          </ButtonWrapper>
        </Container2Sec1>
      </Container2>
    </Wrapper>
  );
};

import React from 'react';
import { PageLayout } from 'src/common/PageLayout/PageLayout';
import { Link } from '@abyss/web/ui/Link';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import styles from './SiteMap.css';

export const SiteMap = () => {
  const allSitemapData = useLagoon('sitemap');
  const activeSitemapData = allSitemapData()?.filter(data => data.active);

  const StyledLink = styled(Link, {
    display: 'flex',
    fontSize: '15px !important',
  });

  const router = useRouter();

  const renderLink = item => {
    if (item.anchor) {
      return (
        <a
          href={item.path}
          rel="noreferrer"
          target={item.newTab ? '_blank' : '_self'}
        >
          {item.label}
        </a>
      );
    }
    return (
      <StyledLink
        onClick={e => {
          router.push(item.path);
        }}
      >
        <span style={{ fontWeight: 'bold' }}>{item.label}</span>
      </StyledLink>
    );
  };

  const renderSitemap = (data, mapToId) => {
    const filteredData = data?.filter(item => item.mapToId === mapToId);
    const sortedData = filteredData?.sort((a, b) => {
      if (a.class === 'Level1' || b.class === 'Level2') {
        return a.priority - b.priority;
      }
      if (a.class === 'Level3' || a.class === 'Level4') {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
      }
      return 0;
    });

    return (
      <React.Fragment>
        {sortedData?.map(item => (
          <React.Fragment>
            <li key={item.id} className={styles[item.class]}>
              {renderLink(item)}
            </li>
            <li>{renderSitemap(data, item.id)}</li>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <PageLayout>
      <div className={styles.Wrapper}>
        <h3 className={styles.Heading}>Sitemap</h3>
        <ul>{renderSitemap(activeSitemapData)}</ul>
      </div>
    </PageLayout>
  );
};

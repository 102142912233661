import React, { useEffect, useState } from 'react';
import { PageLayout } from 'src/common/PageLayout';
import { Flex } from '@abyss/ui/base/Flex';
import { useSelector } from 'react-redux';
import { useParams } from '@abyss/ui/router/hooks/useParams';
import { config } from '@abyss/ui/tools/config';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { event } from '@abyss/web/tools/event';
import { styled, globalCss } from '@abyss/web/tools/styled';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { ReleaseNotesPageContent } from './ReleaseNotesPageContent/ReleaseNotesPageContent';
import { LeftNav } from '../LeftNav';
import apiData from '../OrganizationApi/OrganizationList/OrganizationListReviewUrl.json';
import { TableOfContents } from './TableOfContent';
import { leftNavMap } from '../../../../../common/Utils/utils';

const MenuItems = styled('div', {
  '@screen >= $xs': {
    display: 'none',
  },
  '@screen >= $md': {
    display: 'block',
  },
  '@screen >= $lg': {
    display: 'block',
  },
  '@screen >= $xl': {
    display: 'block',
  },
});

const selectInputStylesCustom = globalCss({
  '.data-abyss-select-item': {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
    color: '#196ECF',
  },
  '.abyss-select-input-input': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    color: '#000000',
  },
});

const ReleaseNotesHeader = styled('div', {
  display: 'flex',
});

const MobileTabletPageMargin = styled('div', {
  marginRight: '24px',
  marginLeft: '24px',

  '@screen >= $xl': {
    marginRight: '0px',
    marginLeft: '0px',
  },
});

const ResponsiveDivItemLeftNav = styled('div', {
  flex: '0 0 0',

  '@screen >= $md': {
    flex: '0 0 20%',
  },
});

const ResponsiveDivItemLeft = styled('div', {
  flex: '0 0 80%',

  '@screen < $lg': {
    flex: '0 0 100%',
  },
});

const DisplayFlex = styled('div', {
  display: 'flex',
});

const PageHeading = styled('h1', {
  color: '$primary1',
  fontStyle: '$normal',
  fontSize: '28px !important',
  fontWeight: '$semibold !important',
  lineHeight: '48px',
  margin: '0px 0px',
});

const ResponsiveDiv = styled('div', {
  display: 'flex',

  '@screen >= $lg': {
    marginRight: '0px',
  },
});

const ResponsiveDivItemRight = styled('div', {
  flex: '0 0 100%',

  '@screen >= $md': {
    flex: '0 0 80%',
    paddingLeft: '20px',
  },
});

const ResponsiveDivTableOfContent = styled('div', {
  flex: '0 0 20%',

  '@screen < $lg': {
    flex: '0 0 0%',
  },
});

const UpdatedDate = styled('p', {
  color: '$primary1',
  fontSize: '13px',
  fontWeight: '$normal',
  minHeight: '20px',
  lineHeight: '20px',
  display: 'flex',
});

const ReleaseDate = styled('h4', {
  fontFamily: 'AbyssEnterpriseSansVF, Arial, sans-serif',
  mozOsxFontSmoothing: 'grayscale',
  webkitFontSmoothing: 'antialiased',
  fontASmoothing: 'antialiased',
  fontWeight: '700 !important',
  fontSize: '23px !important',
  lineHeight: '2rem',
  color: '$primary1',
  padding: '16px 0px 16px 0px',
});

const IconPadding = styled('div', {
  paddingRight: '10px',
});

const BorderLine = styled('div', {
  '@screen >= $lg': {
    borderBottom: '1px solid $gray5',
  },
});

const SelectInputStyles = styled('div', {
  display: 'none',

  '@screen < $lg': {
    display: 'block',
    width: '288px',
  },

  '@screen < $sm': {
    width: '100%',
  },
});

export const ReleaseNotes = () => {
  const router = useRouter();
  const { resource, type } = useParams();
  const leftNavHeaders = useLagoon('left-navigation-headers');
  const leftNavHeaderItems = useLagoon('left-navigation-header-items');
  const [isPageLoadTriggered, setIsPageLoadTriggered] = useState(false);
  let leftNavContent = leftNavHeaders();
  const leftHeaderItems = leftNavHeaderItems();
  leftNavContent = leftNavMap(leftNavContent, leftHeaderItems);

  let releaseNotesData = {
    pathsByTag: leftNavContent,
  };

  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const [dsUserInfo, getDSUserInfo] = useDSUserInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [, getUserInfo] = useUserInfo();
  const [headings, setHeadings] = useState([]);

  const gettingUserInfo = () => {
    getDSUserInfo({ userInfo });
  };

  const isUserSignedIn = userInfo?.firstName !== '' && !dsUserInfo?.uuid;

  useEffect(() => {
    if (userInfo.firstName === '') {
      getUserInfo();
    }

    if (!resource) {
      router.push('/welcome/release-notes/apim-latest-notes');
    }

    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');

      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';

      document.head.appendChild(script);
    }

    if (isUserSignedIn) {
      setIsLoading(true);
      gettingUserInfo();
    }
  }, []);

  useEffect(() => {
    if (dsUserInfo?.uuid !== '') {
      setIsLoading(false);
    }

    if (
      !dsUserInfo.loading &&
      dsUserInfo.data.uuid !== '' &&
      !isPageLoadTriggered
    ) {
      setIsPageLoadTriggered(true);
      event('NEW_PAGE_LOAD', {
        pageName: 'apimarketplace:welcome:release-notes:apim-latest-notes',
        siteSectionL1: 'welcome',
        siteSectionL2: 'release-notes',
        siteSectionL3: 'apim-latest-notes',
        dsUserInfo: dsUserInfo.data,
        userInfo,
      });
    }
  }, [dsUserInfo]);

  useEffect(() => {
    if (isUserSignedIn) {
      setIsLoading(true);
      gettingUserInfo();
    }
  }, [userInfo]);

  useEffect(() => {
    const elements = Array.from(document.querySelectorAll('div.tableof'))
      .filter(element => element.id)
      .map(element => ({
        id: element.id,
        text: element.textContent ?? '',
      }));
    setHeadings(elements);
  }, [resource, type]);

  const getSubheaderView = id => {
    if (id) {
      const element = document.getElementById(id);
      element.scrollIntoView();
    }
  };

  const getResponsiveRightNavData = () => {
    const dataList = headings.map(element => {
      const dataElement = {
        label: element.text,
        value: element.id,
      };
      return dataElement;
    });
    return dataList;
  };

  const getResource = () => {
    if (resource) {
      if (resource === 'apim') {
        return 'api-marketplace';
      }
      return resource;
    }
    return 'api-marketplace';
  };

  const capitalizedServiceName = apiData[resource]?.serviceName;

  useEffect(() => {
    const hasCapitalizedServiceName = !dsUserInfo?.linkedApis?.stage?.includes(
      capitalizedServiceName
    );
    if (
      resource &&
      resource !== 'apim' &&
      dsUserInfo?.uuid !== '' &&
      hasCapitalizedServiceName
    ) {
      router.push('/welcome');
    }
  }, [resource]);

  const getLeftNavData = () => {
    const apiAccess = dsUserInfo?.linkedApis?.stage;
    const ignoreTags = ['apim'];
    const pathsData = leftNavContent;
    const dataList = pathsData?.map(element => {
      if (element.active && !ignoreTags.includes(element.tagId)) {
        if (apiAccess && apiAccess.includes(element.tagId)) {
          const dataElement = {
            ...element,
            accessibleToAll: true,
          };
          return dataElement;
        }
      }
      return element;
    });

    releaseNotesData = {
      pathsByTag: dataList,
    };
    return releaseNotesData;
  };

  const getPageContent = () => {
    const pageResource = getResource();
    const contentManagement = useLagoon(`${pageResource}-release-notes`);
    let pageData = contentManagement();
    let releaseData;

    if (pageData) {
      pageData = pageData.sort((a, b) => {
        return new Date(b.releaseDate) - new Date(a.releaseDate);
      });

      if (window.location.href.includes('latest-notes')) {
        releaseData = pageData.slice(0, 3);
      } else if (window.location.href.includes('archived-notes')) {
        releaseData = pageData.slice(3);
      }
    }

    return {
      data: releaseData,
    };
  };

  const pageContentData = getPageContent();

  const lastUpdatedDate =
    pageContentData?.data && pageContentData.data.length > 0
      ? pageContentData.data[0]?.releaseDate
      : '';
  selectInputStylesCustom();
  return (
    <PageLayout>
      <MobileTabletPageMargin>
        <ReleaseNotesHeader>
          <ResponsiveDivItemLeftNav />
          <ResponsiveDivItemRight>
            <Breadcrumbs />
          </ResponsiveDivItemRight>
        </ReleaseNotesHeader>
        <Flex>
          <ResponsiveDivItemLeftNav>
            <MenuItems>
              <LeftNav>
                <LeftNav.Link
                  loading={isLoading && dsUserInfo.loading}
                  data={getLeftNavData()}
                />
              </LeftNav>
            </MenuItems>
          </ResponsiveDivItemLeftNav>
          <ResponsiveDivItemRight>
            <PageHeading>Release Notes</PageHeading>
            <DisplayFlex>
              <IconPadding>
                <IconMaterial icon="update" size="20px" color="$primary1" />
              </IconPadding>
              <UpdatedDate>
                Last Updated{' '}
                <If condition={lastUpdatedDate && lastUpdatedDate !== ''}>
                  {`${new Date(lastUpdatedDate).toLocaleString('en', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit',
                  })}`}
                </If>
              </UpdatedDate>
            </DisplayFlex>
            <BorderLine />
            <SelectInputStyles>
              <SelectInput
                label=""
                placeholder="ON THIS PAGE"
                onChange={getSubheaderView}
                options={getResponsiveRightNavData()}
                validators={{ required: true }}
                css={{
                  'abyss-select-input-option-content': {
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '18px',
                    color: '#196ECF',
                  },
                  'abyss-select-input-input': {
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    color: '#000000',
                  },
                }}
              />
            </SelectInputStyles>
            <ResponsiveDiv>
              <ResponsiveDivItemLeft>
                <If condition={pageContentData?.data}>
                  <For each="tag" of={pageContentData.data}>
                    <div className="tableof" id={`${tag.key}`}>
                      <ReleaseDate>{tag.label}</ReleaseDate>
                    </div>
                    <ReleaseNotesPageContent content={tag} />
                  </For>
                </If>
              </ResponsiveDivItemLeft>
              <ResponsiveDivTableOfContent>
                <TableOfContents />
              </ResponsiveDivTableOfContent>
            </ResponsiveDiv>
          </ResponsiveDivItemRight>
        </Flex>
      </MobileTabletPageMargin>
    </PageLayout>
  );
};

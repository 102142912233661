import React from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { FileUpload } from '@abyss/web/ui/FileUpload';
import { styled } from '@abyss/web/tools/styled';
import { DateInput } from '@abyss/web/ui/DateInput';
import { useForm } from '@abyss/web/hooks/useForm';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Layout } from '@abyss/web/ui/Layout';
import { Flex } from '@abyss/web/ui/Flex';
import PropTypes from 'prop-types';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { useDownloadFile } from '../../../../../../../hooks/useDownloadFile/useDownloadFile';

const Wrapper = styled('div', {
  '.abyss-descriptors-error-message': {
    fontSize: '13px ',
    fontWeight: '400',
  },
});

export const CreateCommunicationFormFields = ({ setDraftFiles }) => {
  const form = useForm();
  const isFilesAvailable = form.getValues('isFilesAvailable');
  const [downloadFile] = useDownloadFile();
  const featureToggles = useLagoon('feature-toggles');
  const providerDemoToggle = featureToggles('PROVIDER_DEMOGRAPHIC_TOGGLE')
    ?.enabled;
  const priorAuthToggle = featureToggles('CREATE_COMMUNICATION_TOGGLE')
    ?.enabled;
  const symbol = () => {
    return <span style={{ color: '#C40000', fontSize: '23px' }}>*</span>;
  };

  const currentDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  };

  const nextTwoMonths = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 2);
    return date;
  };

  const fileTypesAccepted = {
    'application/pdf': ['.pdf'],
  };

  const headerContent = (
    <React.Fragment>
      <FileUpload.Heading
        css={{
          'abyss-file-upload-header-heading-text': {
            fontSize: '15px !important',
          },
        }}
      >
        Accepted File Type
      </FileUpload.Heading>
      <FileUpload.Description
        css={{
          'abyss-file-upload-header-description-text': {
            fontSize: '15px !important',
          },
        }}
      >
        PDF (Portable Document Format)
      </FileUpload.Description>
      <FileUpload.Heading
        css={{
          'abyss-file-upload-header-heading-text': {
            marginTop: '15px',
            fontSize: '15px !important',
          },
        }}
      >
        <b>Please do not include any PHI/PII information on the attachments</b>
      </FileUpload.Heading>
    </React.Fragment>
  );

  const apiList = () => {
    const apis = [
      { value: 'Claims API', label: 'Claims API' },
      { value: 'Eligibility API', label: 'Eligibility API' },
      { value: 'Referrals API', label: 'Referrals API' },
      { value: 'All Users', label: 'All Users' },
      { value: 'Secret Keeper/Admin', label: 'Secret Keeper/Admin' },
      { value: 'Testing API', label: 'Test Group' },
    ];
    if (providerDemoToggle) {
      apis.push({ value: 'Demographic', label: 'Provider Demographic API' });
    }
    if (priorAuthToggle) {
      apis.push({
        value: 'PriorAuth API',
        label: 'Prior Authorizations API',
      });
    }
    return apis;
  };

  const validateFile = file => {
    const specCharRegex = /[!$%^&*()_+|~=`{}[\]:/;<>?,@#]/;
    if (file.name && specCharRegex.test(file.name)) {
      return {
        message:
          'File name cannot contain special characters other than hyphens.',
      };
    }
    return null;
  };

  return (
    <Wrapper>
      <Layout.Stack
        space={40}
        css={{
          'abyss-layout-stack': {
            display: 'block',
            marginTop: '-2px',
            marginBottom: '20px',
          },
        }}
      >
        <div style={{ fontSize: '15px' }}>Required {symbol()}</div>
        <SelectInput
          id="audience"
          label="Audience"
          model="audience"
          width="330px"
          placeholder=""
          isClearable
          isSearchable
          options={apiList()}
          validators={{ required: true }}
          css={{
            'abyss-select-input-label': {
              position: 'relative',
              fontSize: '15px',
            },
          }}
        />
        <Flex justify="space-between">
          <div>
            <DateInput
              id="date"
              label="Release Date"
              model="date"
              width="280px"
              minimumDate={currentDate()}
              maximumDate={nextTwoMonths()}
              validators={{ required: true }}
              subText={
                <span
                  style={{
                    fontWeight: '1',
                    color: '#7d7f81',
                    fontSize: '13px',
                  }}
                >
                  Date Format: mm/dd/yyyy
                </span>
              }
              css={{
                'abyss-date-picker-input-label': {
                  position: 'relative',
                  fontSize: '15px',
                },
              }}
            />
          </div>
          <div>
            <TextInput
              id="subject"
              label="Subject/RE:"
              type="text"
              model="subject"
              width="330px"
              isClearable
              validators={{ required: true }}
              css={{
                'abyss-text-input-label': {
                  position: 'relative',
                  fontSize: '15px',
                },
              }}
            />
          </div>
        </Flex>
        <TextInputArea
          id="topic"
          label="Topic"
          model="topic"
          displayMaxLength
          validators={{ required: true }}
          css={{
            'abyss-text-input-area-label': {
              position: 'relative',
              fontSize: '15px',
            },
          }}
        />
        <TextInputArea
          id="actionByUnitedHealthCare"
          label="Action by UnitedHealthcare"
          model="actionByUnitedHealthCare"
          displayMaxLength
          validators={{ required: true }}
          css={{
            'abyss-text-input-area-label': {
              position: 'relative',
              fontSize: '15px',
            },
          }}
        />
        <TextInputArea
          id="clientActions"
          label="Client Actions"
          model="clientActions"
          displayMaxLength
          validators={{ required: true }}
          css={{
            'abyss-text-input-area-label': {
              position: 'relative',
              fontSize: '15px',
            },
          }}
        />
        <RadioGroup
          label="Do you need to attach any files?"
          model="isFilesAvailable"
          display="row"
          validators={{ required: true }}
          css={{
            'abyss-radio-group-label': {
              position: 'relative',
              fontSize: '15px',
            },
          }}
        >
          <RadioGroup.Radio label="Yes" value="yes" />
          <RadioGroup.Radio label="No" value="no" />
        </RadioGroup>
        {isFilesAvailable === 'yes' && (
          <FileUpload
            headerContent={headerContent}
            fileTypes={fileTypesAccepted}
            css={{
              'abyss-file-upload-body-container': {
                paddingTop: '20px',
                paddingBottom: '20px',
                fontSize: '15px',
              },
              'abyss-file-upload-label': {
                position: 'absolute',
                fontSize: '15px',
              },
              'abyss-file-upload-error-list-container': {
                fontSize: '15px',
              },
            }}
            model="fileUpload"
            onChange={setDraftFiles}
            maxFileSize={2.5}
            maxFiles={2}
            validators={{
              required:
                'You have chosen Yes for attachment, please attach a valid PDF file.',
            }}
            uploadMessage="Drag your .pdf file here"
            customValidation={validateFile}
            isUploading={downloadFile?.loading}
          />
        )}
      </Layout.Stack>
    </Wrapper>
  );
};

CreateCommunicationFormFields.propTypes = {
  setDraftFiles: PropTypes.func.isRequired,
};

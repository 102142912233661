export const newRelicTags = {
  NEWRELIC_API_TRANSACTION: data => {
    let uuid = data?.userInfo?.uuid;
    let username = data?.userInfo?.username;
    if (process.env.NODE_ENV === 'development') {
      uuid = data?.userInfo?.sub;
      // eslint-disable-next-line camelcase
      username = data?.userInfo?.preferred_username;
    }
    return {
      event: 'api-marketplace',
      data: {
        result: data.message,
        endpoint: data.endpoint,
        appVersion: process.env.PACKAGE_VERSION,
        url: window.location.href,
        uuid,
        optumId: username,
        code: data.code,
        method: data.method,
      },
    };
  },
};

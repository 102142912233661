import React, { useEffect } from 'react';
import { AppContext } from 'src/app/App/AppProvider';
import { styled } from '@abyss/web/tools/styled';
import { event } from '@abyss/web/tools/event';
import { SelectInput } from '@abyss/web/ui/SelectInput';

const Wrapper = styled('div', {
  padding: '16px 16px 16px 80px',
  '@screen >= $xs': {
    paddingLeft: '16px',
  },
  '@screen >= $xl': {
    paddingLeft: '80px',
  },
});

const SubTitle = styled('h5', {
  fontSize: '14px !important',
  fontWeight: 'bold !important',
  marginBottom: '8px !important',
  color: '#262626 !important',
});

export function SortBy() {
  const { sortOptions, setSelectedOption, selectedOption } = React.useContext(
    AppContext
  );

  useEffect(() => {
    setSelectedOption(sortOptions[0]);
  }, []);

  const sortByInteractionEvent = e => {
    const interactionName = 'all apis';
    const interactionValue = `sort by:${e.label} `;
    const interactionContext = 'filter clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  const onChange = e => {
    setSelectedOption(sortOptions.filter(a => a.value === e)[0]);
    sortByInteractionEvent(sortOptions.filter(a => a.value === e)[0]);
  };

  return (
    <Wrapper>
      <SubTitle>Sort By</SubTitle>
      <SelectInput
        onChange={onChange}
        value={selectedOption.value}
        options={sortOptions}
      />
    </Wrapper>
  );
}

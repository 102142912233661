export const convertCurl = ({ url, method, headers, params, body }) => {
  const codeArr = [];
  codeArr.push(`curl --location --request ${method} '${url}'`);

  headers.forEach(header => {
    codeArr.push(`--header '${header.key}: ${header.value}'`);
  });

  if (body) {
    const bodyString = JSON.stringify(body, null, 2);
    codeArr.push(`--data-raw '${bodyString}'`);
  }

  const code = codeArr
    .map((item, i) => {
      const { length } = codeArr;
      if (length >= 1 && i + 1 === length) {
        return `${item}`;
      }
      return `${item} \\`;
    })
    .join('\n');

  return code.trim();
};

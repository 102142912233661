import { ACTION_TYPE } from './useApiHealthCheck';

const initialState = {
  data: {},
  loading: false,
  error: false,
  lastUpdatedDate: '',
};

const dateToTime = date =>
  date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

const getlastUpdatedDate = () => {
  const dateString = Date.now();
  const localDate = new Date(dateString);
  const date = `${
    localDate.getMonth() > 8
      ? localDate.getMonth() + 1
      : `0${localDate.getMonth() + 1}`
  }/${
    localDate.getDate() < 10 ? `0${localDate.getDate()}` : localDate.getDate()
  }/${localDate.getFullYear()}`;
  const time = dateToTime(localDate);
  return `${date} at ${time}`;
};

export const reducerApiHealthCheck = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_CALLED`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${ACTION_TYPE}_COMPLETED`:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
          lastUpdatedDate: getlastUpdatedDate(),
        };
      case `${ACTION_TYPE}_ERROR`:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  },
};

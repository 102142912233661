import React from 'react';
import { Link } from '@abyss/web/ui/Link';
import PropTypes from 'prop-types';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';

export const StyledMarkdownLink = ({ children, href }) => {
  const router = useRouter();
  const routeUrl = url => {
    router.push(url);
  };

  return (
    <Link
      id="custom-link"
      size="inherit"
      fontWeight="bold"
      href={href}
      onClick={e => {
        e.preventDefault();
        routeUrl(href);
      }}
    >
      {children}
    </Link>
  );
};

StyledMarkdownLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

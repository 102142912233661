import { ACTION_TYPE } from './useGitHubRepos';

const initialState = {
  data: {
    repos: [],
  },
  loading: false,
  error: false,
};

export const reducerGitHubRepos = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_CALLED`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${ACTION_TYPE}_COMPLETED`:
        return {
          ...state,
          data: {
            repos: action.payload,
          },
          loading: false,
          error: false,
        };
      case `${ACTION_TYPE}_ERROR`:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  },
};
